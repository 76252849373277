import React, { FC } from "react";
import { queryClient } from "../../../../query-client.ts";
import {
  useDeleteCluster,
  useGetCategoryClusters,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import { Cluster } from "@pages/settings/category-cluster/category-cluster-context.tsx";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Button } from "@primitives/button.tsx";
import { Label } from "@primitives/label.tsx";

interface Props {
  clusters: Cluster[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
}
export const DeleteClusterDialog: FC<Props> = ({
  clusters,
  open,
  onOpenChange,
}) => {
  const { mutateAsync: deleteCluster } = useDeleteCluster();
  const deleteClusters = async (cluster: Cluster) => {
    try {
      await deleteCluster({ id: cluster.id ?? "" }).then();
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const { t } = useTranslation();
  return (
    <DropdownMenuRoot open={open} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant={"outline"}>
          {t("reset-cluster")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        sideOffset={10}
        className=" max-w-[500px] rounded-[8px] p-4"
      >
        <Label className="pb-4 text-lg font-bold">{t("reset-cluster")}</Label>
        <p className={"text-bol mt-2 max-w-[400px] text-[16px] font-normal"}>
          {t("reset-cluster-desc")}
        </p>
        <div className="flex justify-end gap-3 pt-4">
          <Button
            className={"h-[44px] rounded-xl"}
            variant={"outline"}
            onClick={() => onOpenChange(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            className={"h-[44px] rounded-xl"}
            onClick={() => {
              clusters.map((cluster) => {
                deleteClusters(cluster).then();
              });
              onOpenChange(false);
            }}
          >
            {t("reset")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
