import { useAuth } from "@hooks/use-auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import MainNav from "@components/nav/main-nav";
import {
  Ban,
  CalendarClock,
  ConciergeBell,
  DollarSign,
  Settings,
  Settings2,
  Users,
} from "lucide-react";
import { SiteProvider } from "@context/site-context";
import { NavItem, ROUTES } from "@shared/types/navigation";
import { Clusters, useProfileContext } from "@context/profile-context.tsx";
import { anyModuleReadPermission } from "@pages/router.tsx";
import { Permission, PermissionModule } from "../../../../api-contracts/auth";

/** Filtering NavItems on routePermissions */
const createNavItems = (
  module: string,
  routePermissions: Permission[],
  clusters: Clusters[],
): NavItem[] => {
  const categoryGrid = clusters.map(
    (cl) => `/${module}/${ROUTES.CATEGORY_GRID}/${cl.name}`,
  );
  const assetGrid = clusters.map(
    (cl) => `/${module}/${ROUTES.ASSET_GRID}/${cl.name}`,
  );
  const arrivals = clusters.map(
    (cl) => `/${module}/${ROUTES.ARRIVALS}/${cl.name}`,
  );
  arrivals.unshift(`/${module}/${ROUTES.ARRIVALS}/all`);
  const departures = clusters.map(
    (cl) => `/${module}/${ROUTES.DEPARTURES}/${cl.name}`,
  );
  departures.unshift(`/${module}/${ROUTES.DEPARTURES}/all`);
  const occupants = clusters.map(
    (cl) => `/${module}/${ROUTES.OCCUPANTS}/${cl.name}`,
  );
  occupants.unshift(`/${module}/${ROUTES.OCCUPANTS}/all`);
  const priceRules = clusters.map(
    (cl) => `/${module}/${ROUTES.PRICE_RULES}/${cl.name}`,
  );
  const priceCalendar = clusters.map(
    (cl) => `/${module}/${ROUTES.PRICE_CALENDAR}/${cl.name}`,
  );

  return [
    {
      icon: Ban,
      name: "out-of-order",
      subItems: [
        {
          name: "fire-list",
          urls: [`/maintainr/${ROUTES.OUT_OF_ORDER}`],
          requirements: [
            "read/maintainr/out-of-order/out-of-order" as Permission,
          ],
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) => {
          return routePermissions.includes(requirement);
        }),
      ),
    },
    {
      icon: ConciergeBell,
      name: "reception",
      subItems: [
        {
          name: "arrivals",
          urls: arrivals,
          requirements: anyModuleReadPermission("reception/arrivals"),
        },
        {
          name: "departures",
          urls: departures,
          requirements: anyModuleReadPermission("reception/departures"),
        },
        {
          name: "occupants",
          urls: occupants,
          requirements: anyModuleReadPermission("reception/occupants"),
        },
        {
          name: "fire-list",
          urls: [`/${module}/${ROUTES.FIRE_LIST}`],
          requirements: anyModuleReadPermission("reception/fire-list"),
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
    {
      icon: CalendarClock,
      name: "booking",
      subItems: [
        {
          name: "category-grid",
          urls: categoryGrid,
          requirements: anyModuleReadPermission("booking/reservation"),
        },
        {
          name: "asset-grid",
          urls: assetGrid,
          requirements: anyModuleReadPermission("booking/bookable-grid"),
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
    {
      icon: DollarSign,
      name: "price-rules",
      subItems: [
        {
          name: "price-calendar",
          urls: priceCalendar,
          requirements: anyModuleReadPermission("pricing/price-calendar"),
        },
        {
          name: "price-rules-title",
          urls: priceRules,
          requirements: anyModuleReadPermission("pricing/price-seasons"),
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
    {
      name: "settings",
      icon: Settings,
      subItems: [
        {
          name: "bookables-and-products",
          urls: [
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.ROOMS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.AREAS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.BEDS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.DORMITORIES}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.COMBINATIONS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.ASSETS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.PLOTS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.CABINS}`,
            `/${module}/${ROUTES.BOOKABLES}/${ROUTES.OTHER_ACCOMMODATIONS}`,
          ],
          requirements: anyModuleReadPermission("settings/bookables"),
        },
        {
          name: "communication-spheres",
          urls: [`/${module}/${ROUTES.COMMUNICATION_SPHERES}`],
          requirements: anyModuleReadPermission(
            "settings/communication-spheres",
          ),
        },

        {
          name: "channels",
          urls: [`/${module}/${ROUTES.CHANNELS}`],
          requirements: anyModuleReadPermission("settings/channels"),
        },

        {
          name: "categories",
          urls:
            module === "roomr"
              ? [
                  `/${module}/${ROUTES.CATEGORIES}/room`,
                  `/${module}/${ROUTES.CATEGORIES}/area`,
                  `/${module}/${ROUTES.CATEGORIES}/hostel`,
                ]
              : [
                  `/${module}/${ROUTES.CATEGORIES}/tent`,
                  `/${module}/${ROUTES.CATEGORIES}/motorhome`,
                  `/${module}/${ROUTES.CATEGORIES}/caravan`,
                  `/${module}/${ROUTES.CATEGORIES}/cabin`,
                  `/${module}/${ROUTES.CATEGORIES}/mobile-home`,
                  `/${module}/${ROUTES.CATEGORIES}/glamping`,
                  `/${module}/${ROUTES.CATEGORIES}/campr-area`,
                  `/${module}/${ROUTES.CATEGORIES}/misc`,
                ],
          requirements: anyModuleReadPermission("settings/categories"),
        },
        {
          name: "target-groups",
          urls: [`/${module}/${ROUTES.TARGET_GROUPS}`],
          requirements: anyModuleReadPermission("settings/target-groups"),
        },
        {
          name: "bookable-status-title",
          urls: [`/${module}/${ROUTES.STATUS}`],
          requirements: ["read/roomr/settings/booking-status" as Permission],
        },
        {
          name: "groups",
          urls: [`/${module}/${ROUTES.GROUPS}`],
          requirements: ["read/roomr/settings/grouping" as Permission],
        },
        {
          name: "tags",
          urls: [`/${module}/${ROUTES.TAGS}`],
          requirements: ["read/roomr/settings/grouping" as Permission],
        },
        {
          name: "fixtures-and-amenities",
          urls: [`/${module}/${ROUTES.AMENITIES}`],
          requirements: ["read/roomr/settings/amenities" as Permission],
        },
        {
          name: "bed-types-and-inventories",
          urls: [`/${module}/${ROUTES.BED_TYPES}`],
          requirements: ["read/roomr/settings/bed-capacity" as Permission],
        },
        {
          name: "category-cluster",
          urls: [`/${module}/${ROUTES.CATEGORY_CLUSTER}`],
          requirements: ["read/campr/settings/grouping" as Permission],
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
  ].filter((item) => item.subItems && item.subItems.length > 0);
};

const createCommonNavItems = (
  module: string,
  routePermissions: Permission[],
): NavItem[] => {
  return [
    {
      icon: Users,
      name: "customers",
      subItems: [
        {
          name: "customer-list",
          urls: [
            `/${module}/${ROUTES.CUSTOMER_LIST}/organization`,
            `/${module}/${ROUTES.CUSTOMER_LIST}/people`,
          ],
          requirements: ["read/common/customers/customer-list" as Permission],
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
    {
      icon: Settings2,
      name: "general-settings",
      subItems: [
        {
          name: "locations",
          urls: [`/${module}/${ROUTES.LOCATIONS}`],
          requirements: ["read/common/settings/locations" as Permission],
        },
        {
          name: "titles",
          urls: [`/${module}/${ROUTES.TITLES}`],
          requirements: ["read/common/settings/titles" as Permission],
        },
        {
          name: "bridges",
          urls: [`/${module}/${ROUTES.BRIDGES}`],
          requirements: ["read/common/settings/bridges" as Permission],
        },
      ].filter((subItem) =>
        subItem.requirements.some((requirement) =>
          routePermissions.includes(requirement),
        ),
      ),
    },
  ];
};

/**
 * Ensure that the user is authenticated before rendering the child route.
 */
function Protected({
  routePermissions,
  module,
}: {
  routePermissions: Permission[];
  module: PermissionModule;
}) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const returnUrl = location.pathname;
  const { activeCluster } = useProfileContext();

  const moduleNavItems = createNavItems(
    module,
    routePermissions,
    activeCluster,
  );
  const commonNavItems = createCommonNavItems(module, routePermissions);

  const navItems = moduleNavItems.concat(commonNavItems);

  return isAuthenticated ? (
    <SiteProvider>
      <MainNav user={user} navItems={navItems}>
        <Outlet />
      </MainNav>
    </SiteProvider>
  ) : (
    <Navigate to={"/login"} replace={true} state={{ returnUrl }} />
  );
}

export default Protected;
