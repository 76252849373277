import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import { useAuth } from "@hooks/use-auth";
import {
  Form,
  Link,
  Navigate,
  useActionData,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { FormField } from "@primitives/form-field";
import { ModularVisitSymbol } from "@primitives/logo";
import { Checkbox } from "@primitives/checkbox";
import { useTranslation } from "react-i18next";
import { action, loader } from "./login.loader";
import { useProfileContext } from "@context/profile-context";
import { ThemeProvider } from "@context/theme-context";

function Superadmin() {
  const { user } = useAuth();
  const [t] = useTranslation();
  const { email, remember } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>() || {};
  const { state } = useLocation();
  const { module } = useProfileContext();

  return user ? (
    <Navigate to={state?.returnUrl || `/${module}`} replace />
  ) : (
    <ThemeProvider defaultTheme="dark">
      <div className="flex min-h-screen bg-screen-background p-8 text-primary-text">
        <div className="z-10 flex min-h-full w-1/3 flex-col items-center justify-center pr-8">
          <div className=" mb-6 rounded-lg bg-amber-500-10 p-4">
            <ModularVisitSymbol width={45} />
          </div>
          <Form method="post" className="flex w-full flex-col px-16 py-2">
            <p className=" text-center text-lg font-light">
              {t("log-in-to-the-superadmin-portal")}
            </p>

            {!actionData?.success && actionData.errors?.root && (
              <FormField.Error>
                {t("login.invalid-email-or-password")}
              </FormField.Error>
            )}

            <FormField.Root>
              <FormField.Label htmlFor="">{t("username")}</FormField.Label>
              <Input
                name="email"
                type="email"
                defaultValue={email}
                placeholder={t("username")}
                autoComplete="email"
              />
              {!actionData?.success && actionData.errors?.email && (
                <FormField.Error>{actionData.errors.email}</FormField.Error>
              )}
            </FormField.Root>
            <FormField.Root>
              <FormField.Label>{t("password")}</FormField.Label>
              <Input
                name="password"
                type="password"
                placeholder={t("password")}
                autoComplete="current-password"
              />
              {!actionData?.success && actionData.errors?.password && (
                <FormField.Error>{actionData.errors.password}</FormField.Error>
              )}
            </FormField.Root>

            <div className="my-1">
              <Link to="/forgot-password" className="text-sm">
                {t("login.forgot-password")}
              </Link>
            </div>

            <FormField.Root className="my-6">
              <FormField.Label className="flex items-center gap-2">
                <Checkbox name="remember" defaultChecked={remember} />
                {t("login.remember-me")}
              </FormField.Label>
            </FormField.Root>

            <Button
              type="submit"
              variant={"default"}
              loading={false}
              disabled={false}
            >
              {t("login.login-button")}
            </Button>
          </Form>
        </div>
        <div className=" min-h-full w-2/3 rounded-3xl bg-[url('/superadmin-login-min.jpg')] bg-cover bg-center"></div>
      </div>
    </ThemeProvider>
  );
}

export default Superadmin;
