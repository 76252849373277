import { useCategoriesContext } from "@pages/settings/categories/categories-context";
import { useTranslation } from "react-i18next";
import { CategoryType } from "../../../../../../api-contracts/categories";
import { useProfileContext } from "@context/profile-context.tsx";
import { TabNavbar } from "@components/tab-navbar.tsx";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const CategoryTypeTabs = () => {
  const { setCategoryTypes, selectedCategoryType, setSelectedCategoryType } =
    useCategoriesContext();
  const { t } = useTranslation();
  const { module } = useProfileContext();
  const { type } = useParams();
  const navigate = useNavigate();
  type TabItems = {
    value: CategoryType[];
    label: string;
    disabled: boolean;
    to: string;
  };
  const previousModule = useRef(module);

  const roomrTabItems: TabItems[] = [
    {
      value: ["room"],
      label: t("hotel"),
      disabled: false,
      to: `/${module}/categories/room`,
    },
    {
      value: ["area"],
      label: t("surface-area"),
      disabled: false,
      to: `/${module}/categories/area`,
    },
    {
      value: ["bed", "dormitory"],
      label: t("hostel"),
      disabled: false,
      to: `/${module}/categories/hostel`,
    },
  ];

  const camprTabItems: TabItems[] = [
    {
      value: ["tent"],
      label: t("tent"),
      disabled: false,
      to: `/${module}/categories/tent`,
    },
    {
      value: ["motorhome"],
      label: t("motor-home"),
      disabled: false,
      to: `/${module}/categories/motorhome`,
    },
    {
      value: ["caravan"],
      label: t("caravan"),
      disabled: false,
      to: `/${module}/categories/caravan`,
    },
    {
      value: ["cabin"],
      label: t("cabin"),
      disabled: false,
      to: `/${module}/categories/cabin`,
    },
    {
      value: ["mobile-home"],
      label: t("mobile-home"),
      disabled: false,
      to: `/${module}/categories/mobile-home`,
    },
    {
      value: ["glamping"],
      label: t("glampingtent"),
      disabled: false,
      to: `/${module}/categories/glamping`,
    },
    {
      value: ["campr-area"],
      label: t("area"),
      disabled: false,
      to: `/${module}/categories/campr-area`,
    },
    {
      value: ["misc"],
      label: t("misc"),
      disabled: false,
      to: `/${module}/categories/misc`,
    },
  ];

  const tabItems = module === "roomr" ? roomrTabItems : camprTabItems;

  // Sets types for query & tab bars
  useEffect(() => {
    setSelectedCategoryType(type === "hostel" ? "bed" : (type as CategoryType));
    setCategoryTypes(
      type === "hostel" ? ["bed", "dormitory"] : ([type] as CategoryType[]),
    );
  }, [type]);

  // Navigates if module change
  useEffect(() => {
    if (previousModule.current !== module) {
      navigate(module === "campr" ? camprTabItems[0].to : roomrTabItems[0].to, {
        replace: true,
      });
      previousModule.current = module;
    }
  }, [module]);

  const handleValueChange = (value: string) => {
    const newValue = value.split("&") as CategoryType[];
    let selectedType: CategoryType[] =
      value === "hostel" ? ["bed", "dormitory"] : newValue;
    setSelectedCategoryType(selectedType[0]);
    setCategoryTypes(selectedType);
  };

  return (
    <>
      <TabNavbar
        onValueChange={(value) => {
          handleValueChange(value);
        }}
        value={selectedCategoryType}
        items={tabItems}
      />
    </>
  );
};
