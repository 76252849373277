import {
  formatOccupancyAsPercentage,
  getWeatherIcon,
} from "../price-calendar.utils";
import { PriceRuleDropdown } from "./year-calendar-cell";
import {
  CalendarDay,
  Note,
  SetPriceCalendarRequest,
} from "../../../../../../api-contracts/price-calendar";
import { isBefore, isToday, startOfDay } from "date-fns";
import { usePriceCalendarContext } from "../price-calendar-context";
import { useTranslation } from "react-i18next";

export const MonthCalendarCell = ({
  day,
  isSelected,
  today,
  isAdjacent,
}: {
  day: CalendarDay;
  isSelected: boolean;
  today: Date;
  isAdjacent: boolean;
}) => {
  const {
    showOccupancy,
    showPrices,
    showWeather,
    selectedCategory,
    handlePriceRuleChange,
    selectedSubCategory,
    availablePriceRules,
  } = usePriceCalendarContext();
  const { t } = useTranslation();
  const dateToCheck = startOfDay(day.date);
  const isCurrentDate = isToday(dateToCheck);
  const isPastDate = isBefore(dateToCheck, today);

  const priceRuleOfDay = day.priceRule;
  const occupancyOfDay = formatOccupancyAsPercentage(day.occupancy);
  const priceOfDay = day.price;
  const weatherOfDay = day.weather;
  const notesOfDay = day.notes;
  const isDivergentPriceRule: boolean =
    day.distinctPriceRules > 1 && selectedSubCategory === "all";

  const updatePriceRule = async (newPriceRuleId: string | null) => {
    const payload: SetPriceCalendarRequest = {
      months: [
        {
          month: dateToCheck.getMonth() + 1,
          year: dateToCheck.getFullYear(),
          days: [
            {
              day: day.day,
              priceRuleId: newPriceRuleId,
            },
          ],
        },
      ],
      categoryId: selectedSubCategory === "all" ? null : selectedSubCategory,
      categoryTypes: selectedCategory,
    };

    try {
      handlePriceRuleChange(payload);
    } catch (error) {}
  };

  const deletePriceRule = async () => {
    const payload: SetPriceCalendarRequest = {
      months: [
        {
          month: dateToCheck.getMonth() + 1,
          year: dateToCheck.getFullYear(),
          days: [
            {
              day: day.day,
              priceRuleId: null,
            },
          ],
        },
      ],
      categoryId: selectedSubCategory === "all" ? null : selectedSubCategory,
      categoryTypes: selectedCategory,
    };
    try {
      handlePriceRuleChange(payload);
    } catch (error) {}
  };

  return (
    <>
      {day && (
        <div
          className={`group z-20 mx-1 flex h-full w-[100px] cursor-pointer flex-col overflow-hidden p-2 align-middle 
        ${isAdjacent ? "opacity-10" : isPastDate ? "opacity-50" : ""}
      `}
        >
          <div className="flex h-6 justify-between">
            <div className="flex items-center">
              {showWeather && weatherOfDay && (
                <div className="flex items-center justify-center text-secondary-text">
                  {getWeatherIcon(weatherOfDay.weatherIcon)}
                  <span className="ml-1 text-[10px]">{`${weatherOfDay.tempMax} / ${weatherOfDay.tempMin} °`}</span>
                </div>
              )}
            </div>
            <div
              className={`${
                isCurrentDate
                  ? "h-6 w-6 items-center justify-center rounded-full bg-amber-500 font-sans"
                  : ""
              } flex`}
            >
              <p
                className={`text-sm font-medium ${isCurrentDate ? "text-quaternary-text" : "text-primary-text"}
                  ${isSelected ? "text-quaternary-text" : "text-primary-text"}`}
              >
                {day.day}
              </p>
            </div>
          </div>
          <div className="mt-2 flex-grow flex-col text-xs font-normal text-primary-text">
            <div className="flex flex-col items-start justify-start space-y-1">
              {notesOfDay
                .sort((a: Note, b: Note) => Number(a.id) - Number(b.id))
                .slice(0, notesOfDay.length === 2 ? 2 : 1)
                .map((note, index) => (
                  <div
                    key={`${note.id}-${index}`}
                    className="w-full rounded-md bg-secondary-card-backplate"
                  >
                    <p
                      className={`max-w-full truncate p-1 ${isSelected ? "text-quaternary-text" : "text-primary-text"}`}
                    >
                      {note.message}
                    </p>
                  </div>
                ))}
              {notesOfDay.length > 2 && (
                <div className="inline-block rounded-md bg-secondary-card-backplate">
                  <p className="p-1">+{notesOfDay.length - 1}</p>
                </div>
              )}
            </div>
          </div>

          <div
            className={`mt-2 flex justify-between  ${isSelected && "text-quaternary-text"}`}
          >
            <p
              className={`mr-2 truncate text-sm font-medium ${
                isDivergentPriceRule
                  ? "text-accent-amber-700"
                  : isSelected
                    ? "text-quaternary-text"
                    : "text-primary-text"
              }`}
            >
              {isDivergentPriceRule
                ? `${t("divergent")}`
                : day.priceRule.name || "--"}
            </p>
            {availablePriceRules && !isPastDate && !isAdjacent && (
              <PriceRuleDropdown
                priceRules={availablePriceRules}
                priceRuleOfDay={priceRuleOfDay}
                updatePriceRule={updatePriceRule}
                deletePriceRule={deletePriceRule}
                className={`${isSelected ? "border-quaternary-text text-quaternary-text" : "border-border-color text-primary-text"}`}
              />
            )}
          </div>

          <div>
            <div
              className={`flex flex-col text-xs font-medium text-secondary-text ${isSelected && "text-quaternary-text"}`}
            >
              {showPrices && <p>{priceOfDay ? priceOfDay : 0} SEK</p>}
              {occupancyOfDay && showOccupancy && <p>{occupancyOfDay}%</p>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
