import { AuthUser } from "@context/auth-context";
import { LucideIcon } from "lucide-react";
import { ReactNode } from "react";
import { Permission } from "../../../../api-contracts/auth";

export interface NavSubItem {
  name: string;
  urls: string[];
  requirements: Permission[];
}

export interface NavItem {
  icon: LucideIcon;
  name: string;
  subItems?: NavSubItem[];
}

export interface MainNavProps {
  navItems: NavItem[];
  children: ReactNode;
  user?: AuthUser;
}

/** Navigation routes */
export const ROUTES = {
  ASSETS: "assets",
  BRIDGES: "bridges",
  LOCATIONS: "locations",
  ASSET_GRID: "asset-grid",
  BOOKABLES: "bookables",
  ROOMS: "rooms",
  DORMITORIES: "dormitories",
  CATEGORIES: "categories",
  CATEGORY_GRID: "category-grid",
  CATEGORY_GRID_HOSTEL: "category-grid/hostel",
  CATEGORY_GRID_AREA: "category-grid/area",
  UNALLOCATED: "unallocated",
  CATEGORY_GROUPS: "category-groups",
  BOOKABLE_GROUPS: "bookable-groups",
  COMMUNICATION: "communication",
  DASHBOARD: "dashboard",
  GUEST_GROUPS: "guest-groups",
  GROUPS: "groups",
  IMAGES: "images",
  INVENTORY: "inventory",
  PAYMENTS: "payments",
  PRICE_RULES: "pricing",
  PRICE_CALENDAR: "price-calendar",
  RESERVATIONS: "reservations",
  SITES: "sites",
  TITLES: "titles",
  USERS: "users",
  CHANNELS: "channels",
  COMBINATIONS: "combinations",
  FIXTURES: "fixtures",
  AMENITIES: "amenities",
  TARGET_GROUPS: "target-groups",
  TAGS: "tags",
  GUEST: "guests",
  GUEST_ORG: "guest-organizations",
  STATUS: "status",
  BEDS: "beds",
  AREAS: "areas",
  BED_TYPES: "bed-types",
  ARRIVALS: "arrivals",
  ARRIVALS_HOTEL: "arrivals/hotel",
  ARRIVALS_AREA: "arrivals/area",
  ARRIVALS_HOSTEL: "arrivals/hostel",
  PLOTS: "plots",
  CABINS: "cabins",
  OTHER_ACCOMMODATIONS: "other-accommodations",
  MISC: "all",
  DEPARTURES: "departures",
  DEPARTURES_HOTEL: "departures/hotel",
  DEPARTURES_AREA: "departures/area",
  DEPARTURES_HOSTEL: "departures/hostel",
  OCCUPANTS: "occupants",
  OCCUPANTS_HOTEL: "occupants/hotel",
  OCCUPANTS_AREA: "occupants/area",
  OCCUPANTS_HOSTEL: "occupants/hostel",
  CATEGORY_CLUSTER: "category-cluster",
  FIRE_LIST: "fire-list",
  COMMUNICATION_SPHERES: "communication-spheres",
  SIMPLE_SEARCH: "search",
  OUT_OF_ORDER: "out-of-order",
  CUSTOMER_LIST: "customer-list",
};

export type ROUTES = (typeof ROUTES)[keyof typeof ROUTES];
