import { FC } from "react";
import { Button } from "@primitives/button";
import { Column } from "@tanstack/react-table";
import { Upload } from "lucide-react";
import { CSVLink } from "react-csv";
import { FullSearchResult } from "../../../../../api-contracts/search";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface CsvButtonProps {
  searchResults: FullSearchResult[];
  searchColumnDefinition: Column<FullSearchResult, any>[];
}

export const CsvButton: FC<CsvButtonProps> = ({
  searchResults,
  searchColumnDefinition,
}) => {
  const { t } = useTranslation();

  const extractHeaders = (columns: Column<FullSearchResult, any>[]) => {
    const headers = columns.map((column) => {
      let label = "";

      if (typeof column.columnDef.header === "string") {
        label = column.columnDef.header;
      } else if (typeof column.columnDef.header === "function") {
        label = t(column.id?.toString() || "");
      } else {
        label = t(column.id?.toString() || "");
      }

      const key = column.id?.toString() || "";

      return { label, key };
    });
    return headers;
  };

  const headers = extractHeaders(searchColumnDefinition);

  const formatDataForCsv = (results: FullSearchResult[]) => {
    const formattedData = results.map((result) => {
      const formattedResult: Record<string, any> = {};

      headers.forEach(({ key }) => {
        switch (key) {
          case "reservationId":
            formattedResult[key] = result.reservationId || "";
            break;
          case "guestFirstNames":
            formattedResult[key] = result.guests
              ? result.guests.map((guest) => guest.name).join(", ")
              : "";
            break;
          case "guestSurnames":
            formattedResult[key] = result.guests
              ? result.guests.map((guest) => guest.surname).join(", ")
              : "";
            break;
          case "arrivalDate":
            formattedResult[key] = result.arrivalDate
              ? format(new Date(result.arrivalDate), "yyyy-MM-dd")
              : "";
            break;
          case "departureDate":
            formattedResult[key] = result.departureDate
              ? format(new Date(result.departureDate), "yyyy-MM-dd")
              : "";
            break;
          case "categoryShort":
            formattedResult[key] = result.category?.short || "";
            break;
          case "guestOrganization":
            formattedResult[key] = result.guestOrganization?.name || "";
            break;
          case "travelAgency":
            formattedResult[key] = result.travelAgency?.name || "";
            break;
          case "holderFirstName":
            formattedResult[key] = result.holder?.name || "";
            break;
          case "holderSurname":
            formattedResult[key] = result.holder?.surname || "";
            break;
          case "state":
            formattedResult[key] = t(
              result.state === "planned" ? "not-checked-in" : result.state,
            );
            break;
        }
      });

      return formattedResult;
    });

    return formattedData;
  };

  const data = formatDataForCsv(searchResults);

  return data.length > 0 ? (
    <CSVLink
      data={data}
      headers={headers}
      filename="search-results.csv"
      enclosingCharacter={`"`}
      separator={","}
    >
      <Button
        variant={"secondary"}
        className="w-10 p-0 text-primary-icon-color"
      >
        <Upload size={"15px"} />
      </Button>
    </CSVLink>
  ) : (
    <Button
      variant={"secondary"}
      disabled
      className="w-10 p-0 text-primary-icon-color"
    >
      <Upload size={"15px"} />
    </Button>
  );
};
