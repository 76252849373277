import { TabItem, TabNavbar } from "@components/tab-navbar.tsx";
import { FC, useEffect, useState } from "react";
import { useProfileContext } from "@context/profile-context.tsx";
import { usePriceCalendarContext } from "@pages/booking/price-calendar/price-calendar-context.tsx";

interface Props {
  path: string;
  all?: TabItem;
  tabsAsLinks?: boolean;
  onValueChange?: (value: string) => void;
}
export const ClusterTabNavBar: FC<Props> = ({
  path,
  all,
  tabsAsLinks = true,
  onValueChange,
}) => {
  const { module, activeCluster } = useProfileContext();
  const [tabItems, setTabItems] = useState<TabItem[]>(
    activeCluster.map((cl) => ({
      value: cl.categoryTypes.map((ct) => ct), // Mapping over categoryTypes
      label: cl.name,
      disabled: false,
      to: `/${module}/${path}/${cl.name}`,
    })),
  );
  const [value, setValue] = useState(all ? all.value[0] : tabItems[0].value[0]);

  useEffect(() => {
    setValue(all ? all.value[0] : tabItems[0].value[0]);
    if (activeCluster.length > 0) {
      setTabItems(
        activeCluster.map((cl) => {
          return {
            value: cl.categoryTypes.map((ct) => ct),
            label: cl.name,
            disabled: false,
            to: tabsAsLinks ? `/${module}/${path}/${cl.name}` : undefined,
          };
        }),
      );
    }
  }, [activeCluster]);

  useEffect(() => {
    setValue(all ? all.value[0] : tabItems[0].value[0]);
  }, [tabItems, onValueChange]);

  return (
    <TabNavbar
      value={value}
      onValueChange={setValue}
      items={tabItems}
      all={all}
    />
  );
};
