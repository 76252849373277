import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs.tsx";
import { useTranslation } from "react-i18next";

export const CardHeaderTabs = ({
  numberOfResults,
}: {
  numberOfResults: number;
}) => {
  const { t } = useTranslation();
  const tabItems = [
    {
      value: "bookings",
      label: `${t("bookings")}`,
      disabled: false,
    },
  ];
  return (
    <Tabs value={"bookings"} onValueChange={() => null}>
      <TabsList>
        {tabItems.map((item, index) => (
          <TabsTrigger
            key={`${item}-${index}`}
            value={item.value}
            disabled={item.disabled}
          >
            <span>{item.label}</span>
            <span className="ml-1 rounded-3xl bg-primary-card-backplate p-[2px] px-2 text-xs font-bold">
              {numberOfResults}
            </span>
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
