import { SearchBar } from "@primitives/search-bar";
import React, { useState, useRef, useEffect } from "react";
import { debounce } from "lodash";
import { NavItem } from "../main-nav";
import { PageResults } from "./page-results";
import { RecentResults } from "./recent-results";
import { useTranslation } from "react-i18next";
import {
  RecentItem,
  getRecentSearch,
  removeRecentSearch,
  saveRecentSearch,
} from "./search-utils";
import { usePostSimpleSearch } from "@api/search";
import { SimpleSearchResult } from "../../../../../../api-contracts/search";
import { BookingResults } from "./booking-results";
import { Button } from "@primitives/button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";

export const Search = ({ pages }: { pages: NavItem[] }) => {
  const navigate = useNavigate();
  const { module } = useProfileContext();

  const [searchTerm, setSearchTerm] = useState<string>(() => {
    const params = new URLSearchParams(location.search);
    return params.get("searchTerm") || "";
  });
  const [bookingResults, setBookingResults] = useState<SimpleSearchResult[]>(
    [],
  );
  const [numberOfResults, setNumberOfResults] = useState<number>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const search = usePostSimpleSearch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlSearchTerm = params.get("searchTerm") || "";
    setSearchTerm(urlSearchTerm);
  }, [location.search]);

  const executeSearch = debounce(async (searchText: string) => {
    if (searchText.length > 1) {
      try {
        const response = await search.mutateAsync({ text: searchText });
        setBookingResults(response.results || []);
        setNumberOfResults(response.numberOfResults || 0);
      } catch (error) {
        console.log("Error executing search:", error);
      }
    }
  }, 300);

  useEffect(() => {
    executeSearch(searchTerm);
    return () => executeSearch.cancel();
  }, [searchTerm]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === "") {
      localStorage.removeItem("lastSearchTerm");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      executeSearch.cancel();
      onShowMoreResults();
    }
  };

  const onShowMoreResults = () => {
    if (searchTerm) {
      const query = new URLSearchParams({ searchTerm }).toString();
      saveRecentSearch({ type: "searchTerm", searchTerm });
      localStorage.setItem("lastSearchTerm", searchTerm);
      window.dispatchEvent(new Event("storage"));
      navigate(`${module}/${ROUTES.SIMPLE_SEARCH}?${query}`);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      inputRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setTimeout(() => setIsDropdownOpen(false), 100);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div>
      <SearchBar
        ref={inputRef}
        onFocus={() => setIsDropdownOpen(true)}
        className="focus:font-neu justify-center rounded-full border-none bg-secondary-card-backplate font-itc font-normal text-secondary-text placeholder:text-xs focus:bg-solid-backplate focus:font-bold focus:text-primary-text"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
      />
      <div className="relative z-[200]">
        {isDropdownOpen && (
          <SearchResultsDropdown
            onShowMoreResults={onShowMoreResults}
            searchTerm={searchTerm}
            ref={dropdownRef}
            pages={pages}
            bookings={bookingResults}
            numberOfResults={numberOfResults || 0}
          />
        )}
      </div>
    </div>
  );
};

const SearchResultsDropdown = React.forwardRef<
  HTMLDivElement,
  {
    searchTerm: string;
    pages: NavItem[];
    bookings: SimpleSearchResult[];
    onShowMoreResults: () => void;
    numberOfResults: number;
  }
>(
  (
    { searchTerm, pages, bookings, onShowMoreResults, numberOfResults },
    ref,
  ) => {
    const { t } = useTranslation();

    const [recentResults, setRecentResults] = useState<RecentItem[]>([]);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const updateRecentSearchResults = () => {
      setRecentResults(getRecentSearch());
    };

    const handleDelete = (itemToRemove: RecentItem) => {
      removeRecentSearch(itemToRemove);
      setRefreshTrigger((prev) => !prev);
    };

    useEffect(() => {
      updateRecentSearchResults();
    }, [refreshTrigger]);

    return (
      <div
        ref={ref}
        className=" absolute left-1/2 z-10 mt-2 w-full min-w-[600px] -translate-x-1/2 transform rounded-lg bg-solid-backplate text-sm text-primary-text shadow-lg"
      >
        {recentResults.length > 0 && (
          <>
            <p className="px-2 pt-2 text-sm font-bold ">{t("recent")}</p>
            <RecentResults
              searchTerm={searchTerm}
              onDelete={handleDelete}
              recentResults={
                searchTerm ? recentResults.slice(0, 3) : recentResults
              }
            />
          </>
        )}
        {searchTerm && (
          <>
            <p className="px-2 pt-2 text-sm font-bold">{t("pages")}</p>
            <PageResults pages={pages} searchTerm={searchTerm} />
          </>
        )}
        {searchTerm && (
          <>
            <div className="flex justify-between px-2 pt-2 ">
              <p className="text-sm font-bold">{t("bookings")}</p>
              {numberOfResults > 0 && (
                <span className="text-xs font-light text-secondary-text">
                  {t("showing-{{number}}-of-{{results}}", {
                    number: bookings.length,
                    results: numberOfResults,
                  })}
                </span>
              )}
            </div>
            <BookingResults bookings={bookings} searchTerm={searchTerm} />
          </>
        )}
        {searchTerm && (
          <div className="w-full border-t border-highlighted-backplate">
            <Button
              className="w-full"
              variant={"link"}
              onClick={onShowMoreResults}
            >
              {t("show-more-results")}
            </Button>
          </div>
        )}
      </div>
    );
  },
);
