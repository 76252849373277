import React, { FC, useContext, useState } from "react";
import { CamprType } from "../../../../../../api-contracts/categories";
import {
  CategoryClusterContext,
  ClusterData,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useDeleteCluster,
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Button } from "@primitives/button.tsx";
import { Label } from "@primitives/label.tsx";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const RemoveCategoriesFromClusterDialog: FC<Props> = ({
  open,
  onOpenChange,
}) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const { mutateAsync: deleteCluster } = useDeleteCluster();
  const [clusterId, setClusterId] = useState("");
  const { t } = useTranslation();

  const patchClusters = async (d: {
    clusterName: string;
    id: string;
    unselectedCategories: string[];
  }) => {
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: d.clusterName,
      categoryTypes: d.unselectedCategories as CamprType[],
    };
    try {
      await patchCluster({
        patch: cluster,
        id: d.id,
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: d.clusterName }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const getUnselectedCategoriesByCluster = (
    clusterData: ClusterData,
  ): { clusterName: string; id: string; unselectedCategories: string[] }[] => {
    return clusterData.clusters.map((cl) => ({
      clusterName: cl.name,
      id: cl.id,
      unselectedCategories: cl.categories
        .filter((cat) => !cat.selected) // Only keep unselected categories
        .map((cat) => cat.category), // Extract just the category name
    }));
  };

  return (
    <>
      <DropdownMenuRoot open={open} onOpenChange={onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant={"outline"}>
            {t("reset")}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          sideOffset={10}
          className=" min-w-[500px] rounded-[8px] p-4"
        >
          <Label className="pb-4 text-lg font-bold">{t("reset-cluster")}</Label>
          <p className={"text-bol mt-2 text-[16px] font-normal"}>
            {t("reset-selected-categories-from-cluster")}
          </p>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className={"h-[44px] rounded-xl"}
              variant={"outline"}
              onClick={() => onOpenChange(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              className={"h-[44px] rounded-xl"}
              onClick={() => {
                const data = getUnselectedCategoriesByCluster(clusterData);
                data.map((d) => {
                  patchClusters(d).then();
                });
                onOpenChange(false);
              }}
            >
              {t("save")}
            </Button>
          </div>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </>
  );
};
