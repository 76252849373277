import { CardTitle } from "@primitives/card";
import { Button } from "@primitives/button";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { TabNavbar } from "@components/tab-navbar";

export const Header = ({ tab }: { tab: "organization" | "people" }) => {
  const { module } = useProfileContext();
  const { t } = useTranslation();

  const tabItems = [
    {
      value: "organization",
      label: t("company"),
      to: `/${module}/${ROUTES.CUSTOMER_LIST}/organization`,
    },
    {
      value: "people",
      label: t("people"),
      to: `/${module}/${ROUTES.CUSTOMER_LIST}/people`,
    },
  ];
  return (
    <div className="flex flex-col">
      <TabNavbar items={tabItems} value={tab} />
      <div className="flex flex-col">
        <div className="flex items-center justify-between py-4">
          <CardTitle>{t("customer-list")}</CardTitle>
          <div>
            <Button variant="secondary" className="mx-4">
              {t("export")}
            </Button>
            <Button>
              {tab === "organization" ? t("new-company") : t("new-customer")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
