import { useGetCommunicationSpheres } from "@api/communication-spheres.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { useTranslation } from "react-i18next";
import { GetCommunicationSphere } from "../../../../../../../api-contracts/communication-spheres";

interface Props {
  value?: GetCommunicationSphere;
  onValueChange?: (value?: GetCommunicationSphere) => void;
  id?: string;
  disabled?: boolean;
}

const CommunicationSphereSelect = ({
  value,
  onValueChange,
  id,
  disabled,
}: Props) => {
  const [t] = useTranslation();

  const {
    data: communicationSpheresData,
    isLoading: communicationSpheresIsLoading,
    isRefetching: communicationSpheresIsRefetching,
  } = useGetCommunicationSpheres({});

  return (
    <Select
      disabled={disabled}
      value={value?.id}
      onValueChange={(value) =>
        onValueChange &&
        onValueChange(communicationSpheresData?.find((c) => c.id === value))
      }
    >
      <SelectTrigger id={id} className="text-xs">
        <SelectValue>{value?.name}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {communicationSpheresData?.map((s, i) => (
          <SelectItem key={i} value={s.id} className=" text-xs">
            {t(s.name)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default CommunicationSphereSelect;
