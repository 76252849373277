import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import { ArrivalsCardHeader } from "./components/arrivals-card-header";

import {
  CategoryTypeEnum,
  ReceptionContextProvider,
} from "../reception-context";
import { TableContainer } from "./components/arrivals-table-container";
import { useProfileContext } from "@context/profile-context";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@shared/types/navigation";
import { queryClient } from "query-client";
import { useEffect } from "react";
import { useGetArrivalsByDate } from "@api/reception";
import { ClusterTabNavBar } from "@components/cluster-tab-nav-bar.tsx";
import { TabItem } from "@components/tab-navbar.tsx";
import { values } from "lodash";
import { CategoryType } from "../../../../../api-contracts/categories";

const ArrivalsPage = ({ tab }: { tab: CategoryType[] }) => {
  const { t } = useTranslation();
  const { module, clusters } = useProfileContext();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: useGetArrivalsByDate.getKey() });
  }, []);

  const all: TabItem = {
    value: ["all"],
    label: "All",
    to: `/${module}/${ROUTES.ARRIVALS}/all`,
    disabled: false,
  };

  return (
    <ReceptionContextProvider categoryType={tab} page="arrivals">
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <ClusterTabNavBar
            path={ROUTES.ARRIVALS}
            all={all ? all : undefined}
          />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <ArrivalsCardHeader />
          <TableContainer />
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};

export default ArrivalsPage;
