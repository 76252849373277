import { createMutation, createQuery } from "react-query-kit";
import { api } from "./api";
import { currentSite } from "@context/site-context";
import { format } from "date-fns";
import {
  AllocateBookingRequest,
  AllocateBookingResponse,
  AllocateBookingsRequest,
  AllocateBookingsResponse,
  GetUnallocatedBookingsResponse,
} from "../../../../api-contracts/reservations/allocation";
import {
  AbortOngoingReservationRequest,
  CancelBookingRequest,
  CancelBookingResponse,
  CancelReservationRequest,
  CancelReservationResponse,
  ConfirmReservationRequest,
  ConfirmReservationResponse,
  CreateReservationRequest,
  CreateReservationResponse,
  GetReservationResponse,
  PatchBookingRequest,
  PatchBookingResponse,
  PatchReservationRequest,
  PatchReservationResponse,
} from "../../../../api-contracts/reservations";

export const RESERVATIONS_QUERY_KEY = "reservations";
export const BOOKING_QUERY_KEY = "booking";

export const useGetReservation = createQuery<
  GetReservationResponse,
  { id: string }
>({
  primaryKey: RESERVATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { id }] }) =>
    api.get(`/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${id}`),
});

export const useGetUnallocatedReservations = createQuery<
  GetUnallocatedBookingsResponse,
  { startDate: Date; endDate: Date }
>({
  primaryKey: RESERVATIONS_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { startDate, endDate }] }) =>
    api.get(`/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/unallocated`, {
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    }),
});

export const useAllocateReservation = createMutation<
  AllocateBookingResponse,
  { id: string; body: AllocateBookingRequest }
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/allocate/${data.id}`,
      data.body,
    ),
});

export const useAllocateAllReservations = createMutation<
  AllocateBookingsResponse,
  AllocateBookingsRequest
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/allocate`,
      data,
    ),
});

export const useCreateReservation = createMutation<
  CreateReservationResponse,
  CreateReservationRequest
>({
  mutationFn: async (data) =>
    api.post(`/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}`, data),
});

export const usePatchReservation = createMutation<
  PatchReservationResponse,
  { patch: PatchReservationRequest; id: string }
>({
  mutationFn: async ({ patch, id }) =>
    api.patch(`/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${id}`, patch),
});

export const useCancelReservation = createMutation<
  CancelReservationResponse,
  { id: string } & CancelReservationRequest
>({
  mutationFn: async ({ id, ...args }) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${id}/cancel`,
      args,
    ),
});

export const useAbortOngoingReservation = createMutation<
  {},
  { id: string } & AbortOngoingReservationRequest
>({
  mutationFn: async ({ id, ...args }) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${id}/abort-ongoing`,
      args,
    ),
});

export const useConfirmReservation = createMutation<
  ConfirmReservationResponse,
  { id: string } & ConfirmReservationRequest
>({
  mutationFn: async ({ id, ...patch }) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${id}/confirm`,
      patch,
    ),
});

export const useCancelBooking = createMutation<
  CancelBookingResponse,
  { reservationId: string; bookingId: string } & CancelBookingRequest
>({
  mutationFn: async ({ reservationId, bookingId, ...args }) =>
    api.post(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${reservationId}/booking/${bookingId}/cancel`,
      args,
    ),
});

// PATCH/:siteId/reservations/:id/booking/:id
export const usePatchBooking = createMutation<
  PatchBookingResponse,
  { reservationId: string; bookingId: string; patch: PatchBookingRequest }
>({
  mutationFn: async ({ reservationId, bookingId, patch }) =>
    api.patch(
      `/api/${currentSite?.id}/${RESERVATIONS_QUERY_KEY}/${reservationId}/${BOOKING_QUERY_KEY}/${bookingId}`,
      patch,
    ),
});
