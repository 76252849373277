import { Address } from "./address";
import { InputWithLabel } from "./input-with-label";
import { Tags } from "./tags";
import { Filters } from "@pages/simple-search/search-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const GuestFilter = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    name: filters.guest.name,
    surname: filters.guest.surname,
    phone: filters.guest.phone,
    email: filters.guest.email,
    tags: filters.guest.tags,
    address: filters.guest.address,
    address2: filters.guest.address2,
    zip: filters.guest.zip,
    city: filters.guest.city,
    state: filters.guest.state,
    countryCode: filters.guest.countryCode,
  });

  const { t } = useTranslation();

  useEffect(() => {
    setTempInput({ ...filters.guest });
  }, [filters.guest]);

  const handleInputChange = (
    key: keyof typeof tempInput,
    value: string | string[],
  ) => {
    setTempInput((prev) => ({ ...prev, [key]: value }));
    updateFilter("guest", key, value);
  };

  return (
    <div className="flex flex-col pb-4">
      <InputWithLabel
        label={t("first-name")}
        value={tempInput.name}
        onChange={(e) => handleInputChange("name", e.target.value)}
      />
      <InputWithLabel
        label={t("last-name")}
        value={tempInput.surname}
        onChange={(e) => handleInputChange("surname", e.target.value)}
      />
      <InputWithLabel
        label={t("phone")}
        value={tempInput.phone}
        onChange={(e) => handleInputChange("phone", e.target.value.trim())}
      />
      <InputWithLabel
        label={t("email")}
        value={tempInput.email}
        onChange={(e) => handleInputChange("email", e.target.value.trim())}
      />
      <Tags
        disabled
        selectedTags={tempInput.tags ?? []}
        onValueChange={(newTags) => handleInputChange("tags", newTags)}
      />
      <Address
        address={{
          address: tempInput.address,
          address2: tempInput.address2,
          zip: tempInput.zip,
          city: tempInput.city,
          state: tempInput.state,
          countryCode: tempInput.countryCode,
        }}
        handleInputChange={handleInputChange}
      />
      {/* <div className="mt-4">
        <Button disabled size={"sm"} variant={"outline"}>
          {t("add-guest")}
        </Button>
      </div> */}
    </div>
  );
};
