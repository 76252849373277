import {
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FullSearchResult } from "../../../../../api-contracts/search";
import { useTranslation } from "react-i18next";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { format } from "date-fns";
import { SearchTable } from "./search-table";
import { reservationStateToColor } from "../search-utils";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { ROUTES } from "@shared/types/navigation";
import { TableHeader } from "./table-header";
import { Card } from "@primitives/card";
import { useState } from "react";

export const SearchTableContainer = ({
  searchResults,
  searchTerm,
  openFiltersSheet,
  areFiltersApplied,
}: {
  searchResults: FullSearchResult[];
  searchTerm: string;
  openFiltersSheet: () => void;
  areFiltersApplied: boolean;
}) => {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const columnHelper = createColumnHelper<FullSearchResult>();

  const onRowClick = (reservationId: string) => {
    navigate(`/${module}/${ROUTES.RESERVATIONS}/${reservationId}`, {
      replace: true,
    });
  };

  const searchColumnDefinition = [
    columnHelper.group({
      header: t("booking"),
      id: "booking",
      meta: { group: "booking" },
      columns: [
        columnHelper.accessor("reservationId", {
          enableHiding: false,
          header: t("reservation-number"),
          id: "reservationId",
          cell: ({ getValue }) => (
            <HighlightText textToHighlight={searchTerm} text={getValue()} />
          ),
          meta: { group: "booking" },
        }),
      ],
    }),
    columnHelper.group({
      header: t("guest"),
      id: "guest",
      meta: { group: "guest" },
      columns: [
        columnHelper.accessor((row) => row.guests.map((guest) => guest.name), {
          header: t("first-name"),
          id: "guestFirstNames",
          cell: ({ getValue }) => {
            const names: string[] = getValue() || [];
            return (
              <div className="flex flex-col">
                {names.map((name: string, index: number) => (
                  <div key={index} className="whitespace-nowrap break-keep">
                    <>
                      {name ? (
                        <HighlightText
                          textToHighlight={searchTerm}
                          text={name}
                          className="whitespace-nowrap break-keep"
                        />
                      ) : (
                        "\u00A0"
                      )}
                    </>
                  </div>
                ))}
              </div>
            );
          },
          meta: { group: "guest" },
        }),
        columnHelper.accessor(
          (row) => row.guests.map((guest) => guest.surname),
          {
            header: t("surname"),
            id: "guestSurnames",
            cell: ({ getValue }) => {
              const surnames: string[] = getValue();
              return (
                <div className="flex flex-col">
                  {surnames.map((name: string, index: number) => (
                    <div key={index} className="whitespace-nowrap break-keep">
                      <>
                        {name ? (
                          <HighlightText
                            textToHighlight={searchTerm}
                            text={name}
                            className="whitespace-nowrap break-keep"
                          />
                        ) : (
                          "\u00A0"
                        )}
                      </>
                    </div>
                  ))}
                </div>
              );
            },
            meta: { group: "guest" },
          },
        ),
      ],
    }),
    columnHelper.group({
      header: t("date"),
      id: "date",
      meta: { group: "date" },
      columns: [
        columnHelper.accessor("arrivalDate", {
          header: t("arrival-date"),
          id: "arrivalDate",
          cell: ({ getValue }) => format(new Date(getValue()), "yyyy-MM-dd"),
          meta: { group: "date" },
        }),
        columnHelper.accessor("departureDate", {
          header: t("departure-date"),
          id: "departureDate",
          cell: ({ getValue }) => format(new Date(getValue()), "yyyy-MM-dd"),
          meta: { group: "date" },
        }),
      ],
    }),
    columnHelper.accessor("category.short", {
      header: t("category"),
      id: "categoryShort",
      meta: { group: "category" },
    }),
    columnHelper.accessor((row) => row.guestOrganization?.name || "", {
      header: t("guest-organization"),
      id: "guestOrganization",
      meta: { group: "organization" },
    }),
    columnHelper.accessor((row) => row.travelAgency?.name || "", {
      header: t("travel-agency"),
      id: "travelAgency",
      meta: { group: "travel-agency" },
    }),

    columnHelper.group({
      header: t("booker"),
      id: "booker",
      meta: { group: "booker" },
      columns: [
        columnHelper.accessor("holder.name", {
          header: t("first-name"),
          id: "holderFirstName",
          meta: { group: "booker" },
          cell: ({ getValue }) => (
            <div className="whitespace-nowrap break-keep">
              <HighlightText
                textToHighlight={searchTerm}
                text={getValue() || ""}
                className="whitespace-nowrap break-keep"
              />
            </div>
          ),
        }),
        columnHelper.accessor("holder.surname", {
          header: t("surname"),
          id: "holderSurname",
          meta: { group: "booker" },
          cell: ({ getValue }) => (
            <div className="whitespace-nowrap break-keep">
              <HighlightText
                textToHighlight={searchTerm}
                text={getValue() || ""}
                className="whitespace-nowrap break-keep"
              />
            </div>
          ),
        }),
      ],
    }),

    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
      cell: ({ getValue }) => {
        const state = getValue();
        return (
          <div
            className={`bg-${reservationStateToColor(state)}-100 text-${reservationStateToColor(state)} flex items-center justify-center whitespace-nowrap rounded-[4px] px-2 py-1 font-bold`}
          >
            <p> {state === "planned" ? t("not-checked-in") : t(state)}</p>
          </div>
        );
      },
      meta: { group: "status" },
    }),
  ];

  const searchTable = useReactTable({
    data: searchResults,
    columns: searchColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const cols = searchTable.getAllLeafColumns();

  return (
    <div>
      <TableHeader
        openFiltersSheet={openFiltersSheet}
        setColumnVisibility={setColumnVisibility}
        columns={cols}
        searchTerm={searchTerm}
        results={searchResults}
        areFiltersApplied={areFiltersApplied}
      />
      <Card className="p-0">
        <SearchTable<FullSearchResult>
          table={searchTable}
          onRowClick={onRowClick}
        />
      </Card>
    </div>
  );
};
