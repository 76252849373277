import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@primitives/accordion";
import { Button } from "@primitives/button";
import { DefaultSideSheet } from "@primitives/default-sheet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GuestFilter } from "./guest";
import { BookingInformationFilter } from "./booking-information";
import { Booker } from "./booker";
import { Organization } from "./organization";
import { TravelAgency } from "./travel-agency";
import { UnitAndCategory } from "./unit-category";
import { Date } from "./date";
import { Filter } from "lucide-react";
import { areFiltersApplied } from "@pages/simple-search/search-utils";
import { Filters } from "@pages/simple-search/search-types";

const ACCORDION_ITEM_STYLE =
  "mb-4 rounded-xl border-none bg-secondary-card-backplate";
const ACCORDION_TRIGGER_STYLE = "h-12 px-4 font-semibold whitespace-nowrap";
const ACCORDION_CONTENT_STYLE = "px-4 ";

export const FiltersSideSheet = ({
  isOpen,
  setIsOpen,
  filters,
  clearAllFilters,
  updateFilter,
  numberOfResults,
  onApplyFilters,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filters: Filters;
  clearAllFilters: () => void;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
  numberOfResults: number;
  onApplyFilters: () => void;
}) => {
  const [openAccordion, setOpenAccordion] = useState<string>();
  const { t } = useTranslation();

  return (
    <DefaultSideSheet
      title={t("filter")}
      open={isOpen}
      onOpenChange={setIsOpen}
      className="flex h-full flex-col "
    >
      <Accordion
        value={openAccordion}
        onValueChange={(value) => setOpenAccordion(value)}
        type="single"
        collapsible
        className="w-full flex-grow justify-start overflow-auto text-primary-text"
      >
        <AccordionItem
          className={`${ACCORDION_ITEM_STYLE}`}
          value="booking-information"
        >
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("booking-information")}
            {areFiltersApplied(filters.bookingInformation) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent
            className={`${ACCORDION_CONTENT_STYLE} overflow-visible`}
          >
            <BookingInformationFilter
              filters={filters}
              updateFilter={updateFilter}
            />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="guest" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("guest")}

            {areFiltersApplied(filters.guest) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent className={ACCORDION_CONTENT_STYLE}>
            <GuestFilter filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="holder" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("booker")}
            {areFiltersApplied(filters.holder) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent className={ACCORDION_CONTENT_STYLE}>
            <Booker filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="organization" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("organization")}
            {areFiltersApplied(filters.organization) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent className={ACCORDION_CONTENT_STYLE}>
            <Organization filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="travel-agency" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("travel-agency")}
            {areFiltersApplied(filters.travelAgency) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent className={ACCORDION_CONTENT_STYLE}>
            <TravelAgency filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="unit-category" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger className={ACCORDION_TRIGGER_STYLE}>
            {t("unit-category")}
            {areFiltersApplied(filters.unitCategory) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent
            className={`${ACCORDION_CONTENT_STYLE} overflow-visible`}
          >
            <UnitAndCategory filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="date" className={ACCORDION_ITEM_STYLE}>
          <AccordionTrigger
            className={`${ACCORDION_TRIGGER_STYLE} flex items-center justify-between`}
          >
            <div className="flex items-center space-x-2">
              <span> {t("date")}</span>
              {openAccordion === "date" && (
                <span className="text-xs italic text-secondary-text">
                  {t("yyyy-mm-dd")}
                </span>
              )}
            </div>
            {areFiltersApplied(filters.date) && <FilterIcon />}
          </AccordionTrigger>
          <AccordionContent className={ACCORDION_CONTENT_STYLE}>
            <Date filters={filters} updateFilter={updateFilter} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="flex justify-end">
        <Button variant={"outline"} onClick={clearAllFilters}>
          {t("clear-all")}
        </Button>
        <Button className="ml-4" onClick={onApplyFilters}>
          {t("show-{{number}}-result", { number: numberOfResults })}
        </Button>
      </div>
    </DefaultSideSheet>
  );
};

const FilterIcon = () => {
  return (
    <div className="mr-4 flex w-full justify-end">
      <span className=" rounded-lg bg-amber-500-50 p-1 text-primary-icon-color">
        <Filter strokeWidth={1} size={15} />
      </span>
    </div>
  );
};
