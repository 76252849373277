import React, { FC, useContext } from "react";
import { Checkbox } from "@primitives/checkbox.tsx";
import { cn } from "@utils/css.ts";
import {
  CategoryClusterContext,
  Cluster,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { ClusterCategoryCard } from "@pages/settings/category-cluster/components/cluster-category-card.tsx";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "@utils/helpers.ts";
import clsx from "clsx";

interface Props {
  cluster: Cluster;
  selectedCategories: number;
  selectedClusters: number;
  selectedClusterCategories: number;
}
export const ClusterCard: FC<Props> = ({
  cluster,
  selectedCategories,
  selectedClusters,
  selectedClusterCategories,
}) => {
  const { t } = useTranslation();
  const { selectCluster } = useContext(CategoryClusterContext);
  return (
    <div
      className={clsx(
        selectedCategories < 1 && selectedClusterCategories < 1
          ? "cursor-pointer"
          : "cursor-default",
        "flex",
      )}
    >
      <div
        className={cn(
          "mr-2 h-[200px] rounded-xl pl-[16px] pr-[16px] pt-5",
          cluster.selected
            ? "border-0 bg-amber-500-50"
            : "border border-component-border-color bg-primary-card-backplate",
        )}
      >
        <div>
          <div
            className={"flex justify-between"}
            onClick={(e) => {
              if (selectedCategories < 1 && selectedClusterCategories < 1)
                selectCluster(cluster.id);
            }}
          >
            <div>
              <p className={"fw text-[14px] font-bold text-primary-text"}>
                {capitalizeFirstLetter(cluster.name)}
              </p>
              <p className={"mb-3 text-[12px] text-primary-text"}>
                {t("category-types")}
              </p>
            </div>
            {selectedClusterCategories < 1 && selectedCategories < 1 && (
              <Checkbox checked={cluster.selected} />
            )}
          </div>
          <div className={"flex"}>
            {cluster.categories.map((cat, index) => {
              return (
                <ClusterCategoryCard
                  selectedClusters={selectedClusters}
                  selectedCategories={selectedCategories}
                  key={index}
                  cluster={cluster}
                  category={cat}
                  showCheck={true}
                />
              );
            })}
          </div>
        </div>
        <div
          className={"h-12"}
          onClick={(e) => {
            if (selectedCategories < 1 && selectedClusterCategories < 1)
              selectCluster(cluster.id);
          }}
        />
      </div>
    </div>
  );
};
