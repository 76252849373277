import { CategoryCapacity } from "../../../../api-contracts/categories";
import {
  ReceptionBooking,
  ReceptionLocation as ReceptionLocation,
  FireList,
  LocationWithFireList,
} from "../../../../api-contracts/reception";

export type ReceptionBookingWithLocation = ReceptionBooking & {
  locationName: string;
};

export type FireListWithLocation = FireList & {
  locationName: string;
};

interface Guests {
  adults: number;
  teenagers: number;
  children: number;
  infants: number;
}

export const formatGuests = (
  guests: Guests,
  language: string = "en",
): string => {
  const acceptedLanguage = language === "sv-se" ? "sv-se" : "en";

  const labels = {
    "sv-se": { adults: "V", teenagers: "T", children: "B", infants: "S" },
    en: { adults: "A", teenagers: "T", children: "C", infants: "I" },
  };

  const parts: string[] = [];

  if (guests.adults > 0) {
    parts.push(`${guests.adults}(${labels[acceptedLanguage].adults})`);
  }

  if (guests.children > 0) {
    parts.push(`${guests.children}(${labels[acceptedLanguage].children})`);
  }

  if (guests.teenagers > 0) {
    parts.push(`${guests.teenagers}(${labels[acceptedLanguage].teenagers})`);
  }

  if (guests.infants > 0) {
    parts.push(`${guests.infants}(${labels[acceptedLanguage].infants})`);
  }

  return parts.join(" ");
};

export const formatPrice = (price: string) => {
  const svString = (
    <p>
      {price} <span className="text-secondary-text">SEK</span>
    </p>
  );
  return svString;
};

export const flattenLocationsWithBookings = (
  locations: ReceptionLocation[],
  parentName: string = "",
): ReceptionBookingWithLocation[] => {
  const flattenedBookings: ReceptionBookingWithLocation[] = [];

  locations.forEach((location) => {
    const locationName = parentName
      ? `${parentName} / ${location.name}`
      : location.name;

    location.bookings?.forEach((booking) => {
      flattenedBookings.push({
        ...booking,
        locationName,
      });
    });

    if (location.subLocations) {
      const subLocationData = flattenLocationsWithBookings(
        location.subLocations,
        locationName,
      );
      flattenedBookings.push(...subLocationData);
    }
  });

  return flattenedBookings;
};

export const flattenLocationsWithFireList = (
  locations: LocationWithFireList[],
  parentName: string = "",
): FireListWithLocation[] => {
  const flattenedFireList: FireListWithLocation[] = [];

  locations.forEach((location) => {
    const locationName = parentName
      ? `${parentName} / ${location.name}`
      : location.name;

    location.fireList?.forEach((fire) => {
      flattenedFireList.push({
        ...fire,
        locationName: locationName,
      });
    });

    if (location.subLocations) {
      const subLocationData = flattenLocationsWithFireList(
        location.subLocations as LocationWithFireList[],
        locationName,
      );
      flattenedFireList.push(...subLocationData);
    }
  });

  return flattenedFireList;
};

export const printTable = async () => {
  const tableElement = document.getElementById("table");
  if (tableElement) {
    const originalContents = document.body.innerHTML;
    const tableContents = tableElement.outerHTML;

    document.body.innerHTML = tableContents;

    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();
  } else {
    window.print();
    window.location.reload();
  }
};

export const validateBookingCapacity = (
  booking: {
    adults: number;
    teenagers: number;
    children: number;
    infants: number;
  },
  categoryCapacity: CategoryCapacity | undefined,
  t: (key: string, options?: any) => string,
): string[] => {
  if (!categoryCapacity) {
    return [""];
  }

  const errors: string[] = [];
  const totalGuests =
    booking.adults + booking.teenagers + booking.children + booking.infants;

  if (totalGuests > categoryCapacity.total.max) {
    errors.push(
      t("capacity-total-max-warning", {
        total: totalGuests,
        max: categoryCapacity.total.max,
      }),
    );
  }

  if (booking.adults < categoryCapacity.adults.min) {
    errors.push(
      t("capacity-adults-min-warning", {
        adults: booking.adults,
        min: categoryCapacity.adults.min,
      }),
    );
  }
  if (booking.adults > categoryCapacity.adults.max) {
    errors.push(
      t("capacity-adults-max-warning", {
        adults: booking.adults,
        max: categoryCapacity.adults.max,
      }),
    );
  }

  if (booking.teenagers > categoryCapacity.teenagers.max) {
    errors.push(
      t("capacity-teenagers-max-warning", {
        teenagers: booking.teenagers,
        max: categoryCapacity.teenagers.max,
      }),
    );
  }

  if (booking.children > categoryCapacity.children.max) {
    errors.push(
      t("capacity-children-max-warning", {
        children: booking.children,
        max: categoryCapacity.children.max,
      }),
    );
  }

  if (booking.infants > categoryCapacity.infants.max) {
    errors.push(
      t("capacity-infants-max", {
        infants: booking.infants,
        max: categoryCapacity.infants.max,
      }),
    );
  }

  return errors;
};
