import { Button } from "@primitives/button";
import { Input } from "@primitives/input";
import { useAuth } from "@hooks/use-auth";
import {
  Form,
  Link,
  Navigate,
  useActionData,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { FormField } from "@primitives/form-field";
import { ModularVisitLogo } from "@primitives/logo";
import { Checkbox } from "@primitives/checkbox";
import { useTranslation } from "react-i18next";
import { action, loader } from "./login.loader";
import { useProfileContext } from "@context/profile-context";

function Login() {
  const { user } = useAuth();
  const [t] = useTranslation();
  const { email, remember } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>() || {};
  const { state } = useLocation();
  const { module } = useProfileContext();

  return user ? (
    <Navigate to={state?.returnUrl || `/${module}`} replace />
  ) : (
    <div className="flex min-h-screen flex-col items-center justify-center text-primary-text">
      <div className="absolute inset-0 z-0 bg-white">
        <div className="z-0 flex h-full w-full gap-4">
          <div className="flex-1 bg-accent-amber-50" />
          <div className="flex-1 bg-accent-amber-100" />
          <div className="flex-1 bg-accent-amber-200" />
        </div>
        <div className="absolute inset-0 z-10 bg-[length_95%] bg-mv-symbol bg-cover bg-center bg-no-repeat opacity-20" />
      </div>

      <div className="z-10 flex w-2/5 flex-col items-center">
        <ModularVisitLogo height={36} className="mb-12" />
        <Form
          method="post"
          className="flex w-full flex-col rounded-lg border border-gray-200 bg-white/75 px-16 py-12"
        >
          <p className="text-sm ">{t("login.title")}</p>

          {!actionData?.success && actionData.errors?.root && (
            <FormField.Error>
              {t("login.invalid-email-or-password")}
            </FormField.Error>
          )}

          <FormField.Root>
            <FormField.Label htmlFor="">{t("username")}</FormField.Label>
            <Input
              name="email"
              type="email"
              defaultValue={email}
              placeholder={t("username")}
              autoComplete="email"
            />
            {!actionData?.success && actionData.errors?.email && (
              <FormField.Error>{actionData.errors.email}</FormField.Error>
            )}
          </FormField.Root>
          <FormField.Root>
            <FormField.Label>{t("password")}</FormField.Label>
            <Input
              name="password"
              type="password"
              placeholder={t("password")}
              autoComplete="current-password"
            />
            {!actionData?.success && actionData.errors?.password && (
              <FormField.Error>{actionData.errors.password}</FormField.Error>
            )}
          </FormField.Root>

          <div className="my-1">
            <Link to="/forgot-password" className="text-sm ">
              {t("login.forgot-password")}
            </Link>
          </div>

          <FormField.Root className="my-6">
            <FormField.Label className="flex items-center gap-2">
              <Checkbox name="remember" defaultChecked={remember} />
              {t("login.remember-me")}
            </FormField.Label>
          </FormField.Root>

          <Button
            type="submit"
            variant={"default"}
            loading={false}
            disabled={false}
          >
            {t("login.login-button")}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Login;
