import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { CategoryTypeEnum, useReceptionContext } from "../../reception-context";
import { ReceptionTable } from "../../components/reception-table";
import { TableHeader } from "../../components/table-header";
import { ReceptionBooking } from "../../../../../../api-contracts/reception";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { useTranslation } from "react-i18next";
import {
  formatGuests,
  formatPrice,
  ReceptionBookingWithLocation,
} from "../../reception-utils";
import { format, isSameDay } from "date-fns";
import { Button } from "@primitives/button";
import { Lock } from "lucide-react";
import { Loading } from "@primitives/loading";
import {
  useGetArrivalsByDate,
  usePostCheckInByBookingId,
  usePostSplitNoShowByBookingId,
  usePostUndoCheckInByBookingId,
} from "@api/reception";
import { queryClient } from "query-client";
import { toast } from "@hooks/use-toast";
import { useEffect, useState } from "react";
import { CheckInDialogManager } from "./check-in-dialog-manager";
import {
  BookingState,
  PatchBookingRequest,
} from "../../../../../../api-contracts/reservations";
import { usePatchBooking } from "@api/reservations";
import { ROUTES } from "@shared/types/navigation";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@context/profile-context";
import { Checkbox } from "@primitives/checkbox";
import { enGB, sv } from "date-fns/locale";
import { LocationReceptionTable } from "@pages/reception/components/location-reception-table";
import { CheckinRequest } from "../../../../../../api-contracts/reception/arrivals";

export const TableContainer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { module } = useProfileContext();
  const { selectedBookings, setSelectedBookings, date } = useReceptionContext();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedBooking, setSelectedBooking] = useState<{
    booking: ReceptionBooking | null;
    isOpen: boolean;
    dialogType:
      | "confirm"
      | "chooseBookable"
      | "checkInAssignedRoom"
      | "paymentRemaining"
      | "undoCheckIn"
      | "undoNoShow"
      | "assignBeforeCheckIn"
      | null;
  }>({
    booking: null,
    isOpen: false,
    dialogType: null,
  });

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedToday = today.toISOString().split("T")[0];
  const formattedDate = date.toISOString().split("T")[0];
  const isToday = formattedDate === formattedToday;
  const locale = i18n.language === "sv-se" ? sv : enGB;

  const {
    arrivals,
    columnVisibility,
    setColumnVisibility,
    searchTerm,
    isLoading,
    categoryType,
    setDate,
    showLocation,
  } = useReceptionContext();

  const { mutateAsync: checkIn } = usePostCheckInByBookingId();
  const { mutateAsync: undoCheckIn } = usePostUndoCheckInByBookingId();
  const { mutateAsync: patchBooking } = usePatchBooking();
  const { mutateAsync: splitNoShow } = usePostSplitNoShowByBookingId();

  const onSplitNoShow = async (
    booking: ReceptionBooking,
    splitDate: string,
  ) => {
    const { bookingId, holder, arrivalDate } = booking;
    const fullName = `${holder.name} ${holder.surname}`;

    const splitDateObj = new Date(splitDate);

    const isToday = isSameDay(splitDateObj, today);
    const isYesterday = isSameDay(splitDateObj, yesterday);
    const isInitialArrivalDate = isSameDay(arrivalDate, splitDateObj);

    const formatSplitDate = (splitDate: Date) => {
      const formattedDate = format(splitDate, "d MMMM", { locale });

      if (isToday) {
        return `${t("today")}, ${formattedDate}`;
      } else if (isYesterday) {
        return `${t("yesterday")}, ${formattedDate}`;
      } else {
        return formattedDate;
      }
    };

    const formattedSplitDate = formatSplitDate(splitDateObj);

    try {
      isInitialArrivalDate
        ? await onCheckIn({ bookingId: bookingId })
        : await splitNoShow({ bookingId, splitDate });
      if (isToday) {
        setDate(today);
      }
      toast({
        title: t("checked-in"),
        variant: "success",
        description: t("{{name}}-is-now-checked-in-from-{{date}}", {
          name: fullName,
          date: formattedSplitDate,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: useGetArrivalsByDate.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const onPatchBooking = async (
    booking: ReceptionBooking,
    patchData: PatchBookingRequest,
  ) => {
    try {
      await patchBooking({
        reservationId: booking.reservationId,
        bookingId: booking.bookingId,
        patch: patchData,
      });
      queryClient.invalidateQueries({
        queryKey: useGetArrivalsByDate.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
      throw error;
    }
  };

  const onCheckIn = async (req: CheckinRequest) => {
    try {
      await checkIn(req);
      queryClient.invalidateQueries({
        queryKey: useGetArrivalsByDate.getKey(),
      });

      toast({
        title: t(`{{name}}-{{surname}}-is-checked-in`, {
          name: selectedBooking.booking?.holder.name,
          surname: selectedBooking.booking?.holder.surname,
        }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };
  const onUndoCheckIn = async (bookingId: string) => {
    try {
      await undoCheckIn(bookingId);
      queryClient.invalidateQueries({
        queryKey: useGetArrivalsByDate.getKey(),
      });

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const handleDialogs = (booking: ReceptionBooking) => {
    if (booking.state === "checked-in") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "undoCheckIn",
      });
      return;
    }

    if (
      booking.state === "no-show" &&
      new Date(booking.departureDate) >= today
    ) {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "undoNoShow",
      });
      return;
    }

    if (booking.paymentRemaining !== "0.00") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "paymentRemaining",
      });
      return;
    }

    if (booking.lockedBookable && booking.state === "planned") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "confirm",
      });
      return;
    }

    if (
      booking.bookable.name &&
      !booking.lockedBookable &&
      booking.state === "planned"
    ) {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "checkInAssignedRoom",
      });
      return;
    }

    if (!booking.bookable.name && booking.state === "planned") {
      setSelectedBooking({
        booking,
        isOpen: true,
        dialogType: "assignBeforeCheckIn",
      });
    }
  };

  const closeDialogs = () => {
    setSelectedBooking({ booking: null, isOpen: false, dialogType: null });
  };
  const handleRowClick = (booking: ReceptionBooking) => {
    navigate(`/${module}/${ROUTES.RESERVATIONS}/${booking.reservationId}`);
  };
  const toggleRowSelection = (row: ReceptionBooking) => {
    if (row.paymentRemaining !== "0.00" || !isToday) return;

    setSelectedBookings((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (selectedRow) => selectedRow.reservationId === row.reservationId,
      );

      if (isSelected) {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.reservationId !== row.reservationId,
        );
      }
      return [...prevSelectedRows, row];
    });
  };

  const toggleAllRowSelection = () => {
    const selectableRows = arrivals.filter(
      (row) =>
        isToday &&
        row.paymentRemaining === "0.00" &&
        row.state !== "checked-in",
    );

    const isAllSelected = selectedBookings.length === selectableRows.length;

    if (isAllSelected) {
      setSelectedBookings([]);
    } else {
      setSelectedBookings(selectableRows);
    }
  };

  const columnHelper = createColumnHelper<
    ReceptionBooking | ReceptionBookingWithLocation
  >();

  const arrivalsColumnDefinitions = [
    columnHelper.display({
      id: "select",
      size: 5,
      enableHiding: false,
      header: () => {
        const selectableArrivals = arrivals.filter(
          (row) =>
            isToday &&
            row.paymentRemaining === "0.00" &&
            row.state !== "checked-in",
        );
        const isAllRowsSelected =
          selectedBookings.length === selectableArrivals.length;

        const noArrivals = arrivals.length === 0;
        const hasAtleastOneSelected = selectedBookings.length > 0;

        if (noArrivals || !isToday || selectableArrivals.length === 0)
          return null;

        return (
          <Checkbox
            className={`h-4 w-4 ${hasAtleastOneSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isAllRowsSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              toggleAllRowSelection();
            }}
          />
        );
      },
      cell: ({ row }) => {
        const isRowSelected = selectedBookings.some(
          (selectedRow) => selectedRow.bookingId === row.original.bookingId,
        );
        const isSelectable =
          isToday &&
          row.original.paymentRemaining === "0.00" &&
          row.original.state !== "checked-in";

        if (!isSelectable) return null;

        return (
          <Checkbox
            className={`h-4 w-4 ${isRowSelected ? "visible" : "invisible"} group-hover:visible`}
            checked={isRowSelected}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onCheckedChange={() => {
              toggleRowSelection(row.original);
            }}
          />
        );
      },
    }),
    columnHelper.accessor("reservationId", {
      header: t("booking"),
      id: "reservationId",
      cell: ({ getValue }) => (
        <HighlightText text={getValue()} textToHighlight={searchTerm} />
      ),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.name", {
      header: t("first-name"),
      id: "firstName",
      cell: ({ getValue }) => {
        const firstName = getValue();
        return (
          <HighlightText
            text={firstName ? firstName : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("holder.surname", {
      header: t("surname"),
      id: "surname",
      cell: ({ getValue }) => {
        const surname = getValue();
        return (
          <HighlightText
            text={surname ? surname : ""}
            textToHighlight={searchTerm}
          />
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor(
      (row) => {
        const { adults, teenagers, children, infants } = row;

        return formatGuests(
          { adults, teenagers, children, infants },
          i18n.languages[0],
        );
      },
      {
        header: t("guests"),
        id: "guests",
        meta: {
          initialVisibility: true,
        },
      },
    ),
    columnHelper.accessor("arrivalDate", {
      header: t("arrival-date"),
      id: "arrivalDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: false,
      },
    }),
    columnHelper.accessor("departureDate", {
      header: t("departure-date"),
      id: "departureDate",
      cell: ({ getValue }) => format(getValue(), "yyyy-MM-dd"),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("numberOfNights", {
      header: t("number-of-nights"),
      id: "numberOfNights",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("category.name", {
      header: t("category"),
      id: "roomCategory",
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("bookable.name", {
      header: categoryType.includes("room") ? t("room") : t("unit"),
      id: "room",
      cell: ({ row }) => {
        const { lockedBookable, bookable } = row.original;
        return (
          <div className="flex ">
            {!lockedBookable ? (
              <Button
                size={"sm"}
                variant={"outline"}
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedBooking({
                    booking: row.original,
                    isOpen: true,
                    dialogType: "chooseBookable",
                  });
                }}
              >
                {`${bookable.name ? bookable.name : t("assign")}`}
              </Button>
            ) : (
              <div className="flex w-full items-center justify-between">
                <p className="mr-2">{bookable.name}</p>
                <Lock strokeWidth={1} size={16} className="ml-auto" />
              </div>
            )}
          </div>
        );
      },
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("paymentRemaining", {
      header: t("to-be-paid"),
      id: "paymentRemaining",
      cell: ({ getValue }) => formatPrice(getValue()),
      meta: {
        initialVisibility: true,
      },
    }),
    columnHelper.accessor("state", {
      header: t("status"),
      id: "state",
      meta: {
        initialVisibility: true,
      },
      cell: ({ getValue }) => {
        const stateLabels: Partial<Record<BookingState, string>> = {
          planned: t("not-checked-in"),
          "checked-in": t("checked-in"),
          "checked-out": t("checked-out"),
          "no-show": t("no-show"),
        };

        const stateClasses: Partial<Record<BookingState, string>> = {
          planned: "bg-status-warning-100 text-status-warning",
          "checked-in": "bg-status-success-100 text-status-success",
          "checked-out": "bg-status-disabled-100 text-status-disabled",
          "no-show": "bg-status-error-100 text-status-error",
        };

        const stateValue = getValue();
        const label = stateLabels[stateValue];
        const stateClassName = stateClasses[stateValue];
        return (
          <div
            className={`${stateClassName} flex items-center justify-center whitespace-nowrap rounded-[4px] px-2 py-1 font-extrabold`}
          >
            <p>{label}</p>
          </div>
        );
      },
    }),
    columnHelper.display({
      header: t("action"),
      id: "action",
      enableHiding: false,
      cell: ({ row }) => {
        const stateValue = row.original.state;
        const arrivalDate = new Date(row.original.arrivalDate);
        const departureDate = new Date(row.original.departureDate);

        const isToday =
          arrivalDate.getDate() === today.getDate() &&
          arrivalDate.getMonth() === today.getMonth() &&
          arrivalDate.getFullYear() === today.getFullYear();

        const isYesterdayOrEarlier = arrivalDate <= yesterday;
        const isNoShow = stateValue === "no-show";
        const isFuture = arrivalDate > today;
        const canUndoNoShow =
          isYesterdayOrEarlier && isNoShow && departureDate >= today;

        return (
          <div>
            {isToday ? (
              stateValue === "planned" ? (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDialogs(row.original);
                  }}
                  size={"sm"}
                >
                  {t("check-in")}
                </Button>
              ) : (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDialogs(row.original);
                  }}
                  size={"sm"}
                  variant={"outline"}
                >
                  {t("undo-check-in")}
                </Button>
              )
            ) : canUndoNoShow ? (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDialogs(row.original);
                }}
                size={"sm"}
                variant={"outline"}
              >
                {t("undo-no-show")}
              </Button>
            ) : (
              isFuture && null
            )}
          </div>
        );
      },
    }),
  ];

  const arrivalsTable = useReactTable({
    data: arrivals,
    columns: arrivalsColumnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: (newSorting) => {
      if (!showLocation) {
        setSorting(newSorting);
      } else {
        setSorting([]);
      }
    },
    state: {
      columnVisibility,
      sorting,
    },
  });

  useEffect(() => {
    setSorting([]);
  }, [showLocation]);

  const cols = arrivalsTable.getAllLeafColumns();
  if (isLoading || !arrivals) return <Loading />;
  return (
    <div>
      <CheckInDialogManager
        selectedBooking={selectedBooking}
        onCheckIn={onCheckIn}
        onUndoCheckIn={onUndoCheckIn}
        closeDialogs={closeDialogs}
        onPatchBooking={onPatchBooking}
        onSplitNoShow={onSplitNoShow}
      />
      <div className="p-4">
        <TableHeader columns={cols} tableType="arrivals" />
      </div>
      {!showLocation ? (
        <ReceptionTable<ReceptionBooking>
          table={arrivalsTable}
          onRowClick={handleRowClick}
        />
      ) : (
        <LocationReceptionTable
          table={arrivalsTable}
          onRowClick={handleRowClick}
          showLocation={showLocation}
        />
      )}
    </div>
  );
};
