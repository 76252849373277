import { Header } from "./components/header";
import { TableContainer } from "./components/table-container";

const CustomerListPage = ({ tab }: { tab: "organization" | "people" }) => {
  return (
    <>
      <div className="p-4">
        <Header tab={tab} />
      </div>
      <div>
        <TableContainer />
      </div>
    </>
  );
};

export default CustomerListPage;
