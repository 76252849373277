import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavigationArrows } from "@components/navigation-arrows";
import { SearchTableContainer } from "./components/search-table-container";
import { usePostFullSearch } from "@api/search";
import { Loading } from "@primitives/loading";
import { toast } from "@hooks/use-toast";
import { useTranslation } from "react-i18next";
import { FiltersSideSheet } from "./components/filter-side-sheet/filters-side-sheet";
import { FullSearchResult } from "../../../../api-contracts/search";
import {
  filterByBookingInformation,
  filterByGuest,
  filterByOrganization,
  filterByTravelAgency,
  filterByUnitCategory,
  filterByDate,
  filterByHolder,
  areFiltersApplied,
  parseFiltersFromQueryParams,
  buildQueryParamsFromFilters,
} from "./search-utils";
import { useLocation, useNavigate } from "react-router-dom";
import { cloneDeep, debounce } from "lodash";
import { Filters } from "./search-types";

export const INITIAL_FILTER: Filters = {
  bookingInformation: {
    reservationId: "",
    state: "",
    tags: [],
  },
  guest: {
    name: "",
    surname: "",
    phone: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone2: "",
    tags: [],
    countryCode: "",
  },
  holder: {
    name: "",
    surname: "",
    passportNumber: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    countryCode: "",
    phone: "",
    phone2: "",
    email: "",
    tags: [],
  },
  organization: {
    id: "",
    name: "",
    organizationNumber: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    phone2: "",
    email: "",
    tags: [],
  },
  travelAgency: {
    id: "",
    name: "",
    organizationNumber: "",
    IATA: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    phone2: "",
    email: "",
    invoiceReference: "",
    invoiceName: "",
    invoiceAddress: "",
    invoiceAddress2: "",
    invoiceZip: "",
    invoiceCity: "",
    invoiceState: "",
    invoiceCountry: "",
    tags: [],
  },
  unitCategory: {
    category: {
      id: "",
      short: "",
      name: "",
    },
    bookable: {
      id: "",
      name: "",
    },
  },
  date: {
    arrivalDate: { from: "", to: "" },
    departureDate: { from: "", to: "" },
    reservationDate: { from: "", to: "" },
    updatedDate: { from: "", to: "" },
  },
};

const SimpleSearchPage = () => {
  const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tempFilters, setTempFilters] = useState<Filters>(
    cloneDeep(INITIAL_FILTER),
  );
  const [appliedFilters, setAppliedFilters] = useState<Filters>(
    cloneDeep(INITIAL_FILTER),
  );
  const [filteredResults, setFilteredResults] = useState<FullSearchResult[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const isInitialMount = useRef(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    mutateAsync: postSearch,
    data: searchResults,
    reset,
  } = usePostFullSearch();

  const filteredSearchResults = useMemo(() => {
    if (!searchResults?.results) return [];
    return searchResults.results.filter((result) => {
      if (areFiltersApplied(appliedFilters.bookingInformation)) {
        if (!filterByBookingInformation(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.guest)) {
        if (!filterByGuest(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.holder)) {
        if (!filterByHolder(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.organization)) {
        if (!filterByOrganization(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.travelAgency)) {
        if (!filterByTravelAgency(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.unitCategory)) {
        if (!filterByUnitCategory(result, appliedFilters)) return false;
      }
      if (areFiltersApplied(appliedFilters.date)) {
        if (!filterByDate(result, appliedFilters)) return false;
      }
      return true;
    });
  }, [appliedFilters, searchResults?.results]);

  const filteredSearchResultsForSideSheet = useMemo(() => {
    if (!searchResults?.results) return [];
    return searchResults.results.filter((result) => {
      if (areFiltersApplied(tempFilters.bookingInformation)) {
        if (!filterByBookingInformation(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.guest)) {
        if (!filterByGuest(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.holder)) {
        if (!filterByHolder(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.organization)) {
        if (!filterByOrganization(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.travelAgency)) {
        if (!filterByTravelAgency(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.unitCategory)) {
        if (!filterByUnitCategory(result, tempFilters)) return false;
      }
      if (areFiltersApplied(tempFilters.date)) {
        if (!filterByDate(result, tempFilters)) return false;
      }
      return true;
    });
  }, [tempFilters, searchResults?.results]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchTerm(params.get("searchTerm") || "");
    const parsedFilters = parseFiltersFromQueryParams(params);

    setTempFilters(cloneDeep(parsedFilters));
    setAppliedFilters(cloneDeep(parsedFilters));

    if (isInitialMount.current) {
      if (areFiltersApplied(parsedFilters)) {
        setFiltersApplied(true);
      } else {
        setFiltersApplied(false);
      }
      isInitialMount.current = false;
    }
  }, [location.search]);

  useEffect(() => {
    const fetchResults = async () => {
      if (!searchTerm.trim()) {
        reset();
        setFilteredResults([]);
        return;
      }
      setIsLoading(true);
      try {
        await postSearch({ text: searchTerm });
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchResults();
  }, [searchTerm, postSearch, reset]);

  useEffect(() => {
    if (searchResults?.results) {
      if (filtersApplied) {
        setFilteredResults(filteredSearchResults);
      } else {
        setFilteredResults(searchResults.results);
      }
    }
  }, [searchResults, filtersApplied, filteredSearchResults]);

  const updateFilter = useCallback(
    (section: keyof Filters, key: string, value: any) => {
      setTempFilters((prevFilters) => ({
        ...prevFilters,
        [section]: {
          ...prevFilters[section],
          [key]: value,
        },
      }));
    },
    [],
  );

  const debouncedUpdateFilterRef = useRef(
    debounce(
      (section: keyof Filters, key: string, value: any) => {
        updateFilter(section, key, value);
      },
      300,
      { leading: false, trailing: true },
    ),
  );

  const applyFilters = () => {
    setAppliedFilters(tempFilters);
    setFilteredResults(filteredSearchResults);

    const params = buildQueryParamsFromFilters(tempFilters);
    if (searchTerm) {
      params.set("searchTerm", searchTerm);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl, { replace: true });

    setIsSheetOpen(false);

    setFiltersApplied(true);
  };

  const clearAllFilters = () => {
    debouncedUpdateFilterRef.current.cancel();

    setTempFilters(cloneDeep(INITIAL_FILTER));
  };

  return (
    <div className="h-full p-4">
      <div className="pb-4">
        <NavigationArrows rootPath="/" />
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <SearchTableContainer
          searchTerm={searchTerm}
          searchResults={filteredResults}
          openFiltersSheet={() => setIsSheetOpen(true)}
          areFiltersApplied={areFiltersApplied(appliedFilters)}
        />
      )}
      <FiltersSideSheet
        numberOfResults={filteredSearchResultsForSideSheet.length}
        isOpen={isSheetOpen}
        setIsOpen={setIsSheetOpen}
        filters={tempFilters}
        updateFilter={debouncedUpdateFilterRef.current}
        clearAllFilters={clearAllFilters}
        onApplyFilters={applyFilters}
      />
    </div>
  );
};

export default SimpleSearchPage;
