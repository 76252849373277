import { Card } from "@primitives/card";
import { CategoryTabs } from "../components/category-tabs";
import {
  CategoryTypeEnum,
  ReceptionContextProvider,
} from "../reception-context";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import { useTranslation } from "react-i18next";
import { DeparturesCardHeader } from "./components/departures-card-header";
import { DeparturesTableContainer } from "./components/departures-table-container";
import { useGetDeparturesByDate } from "@api/reception";
import { queryClient } from "query-client";
import { useEffect } from "react";
import { ClusterTabNavBar } from "@components/cluster-tab-nav-bar.tsx";
import { TabItem } from "@components/tab-navbar.tsx";
import { CategoryType } from "../../../../../api-contracts/categories";

const DeparturesPage = ({ tab }: { tab: CategoryType[] }) => {
  const { t } = useTranslation();
  const { module, clusters } = useProfileContext();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetDeparturesByDate.getKey(),
    });
  }, []);

  const all: TabItem = {
    value: ["all"],
    label: "All",
    to: `/${module}/${ROUTES.DEPARTURES}/all`,
    disabled: false,
  };

  return (
    <ReceptionContextProvider categoryType={tab} page="departures">
      <div className="flex h-full w-full flex-col p-4">
        <div>
          <ClusterTabNavBar
            path={ROUTES.DEPARTURES}
            all={all ? all : undefined}
          />
        </div>
        <Card className="mt-4 flex-grow p-0">
          <DeparturesCardHeader />
          <DeparturesTableContainer />
        </Card>
      </div>
    </ReceptionContextProvider>
  );
};

export default DeparturesPage;
