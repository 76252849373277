import { useBlockBookable } from "@api/bookables";
import { useGetCategoryGrid } from "@api/category-grid";
import { useToast } from "@hooks/use-toast";
import { Button } from "@primitives/button";
import DatePicker from "@primitives/date-picker";
import { Label } from "@primitives/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { Textarea } from "@primitives/textarea";
import { format, isBefore, max } from "date-fns";
import { queryClient } from "query-client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  type: "bookable" | "asset";
  id?: string;
  name?: string;
  open: boolean;
  onClose: () => void;
}

export const BlockDrawer = ({ type, id, name, open, onClose }: Props) => {
  const [t] = useTranslation();
  const { toast } = useToast();
  const [blockStartDate, setBlockStartDate] = useState<Date>();
  const [blockEndDate, setBlockEndDate] = useState<Date>();
  const [blockType, setBlockType] = useState<
    "out-of-service" | "out-of-order"
  >();
  const [blockReason, setBlockReason] = useState<string>("");
  const [blockLoading, setBlockLoading] = useState<boolean>(false);

  const blockBookable = useBlockBookable();

  const onBlockBookable = async () => {
    if (id && blockStartDate && blockEndDate && blockType) {
      try {
        setBlockLoading(true);
        await blockBookable.mutateAsync({
          startDate: format(blockStartDate, "yyyy-MM-dd"),
          endDate: format(blockEndDate, "yyyy-MM-dd"),
          blockType: blockType,
          reason: blockReason ? blockReason : undefined,
          id,
          type,
        });
        queryClient.invalidateQueries({
          queryKey: useGetCategoryGrid.getKey(),
        });
        toast({
          variant: "success",
          title: t("saved-succesfully", { name: name }),
          className: "text-status-success",
        });
        setBlockLoading(false);
        setBlockStartDate(undefined);
        setBlockEndDate(undefined);
        setBlockType(undefined);
        setBlockReason("");
        onClose();
      } catch (err) {
        setBlockLoading(false);
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
    }
  };

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setBlockStartDate(undefined);
          setBlockEndDate(undefined);
          setBlockType(undefined);
          setBlockReason("");
          onClose();
        }
      }}
    >
      <SheetContent
        side="left"
        className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
      >
        <SheetHeader>
          <SheetTitle className=" text-primary-text">
            {t("block-room")}
          </SheetTitle>
        </SheetHeader>

        <div className=" flex-1 overflow-y-auto bg-secondary-card-backplate p-4 text-primary-text">
          <p className=" text-base font-bold">
            {t("chosen-room")}: {name}
          </p>

          <div className=" mt-1 flex flex-nowrap items-end space-x-2">
            <div className=" flex-1">
              <Label className="text-xs">{t("start-date")}*</Label>
              <DatePicker
                value={blockStartDate}
                onValueChange={setBlockStartDate}
                calendarProps={
                  blockEndDate
                    ? {
                        disabled: {
                          after: blockEndDate,
                        },
                      }
                    : undefined
                }
              />
            </div>
          </div>

          <div className=" mt-1 flex flex-nowrap items-end space-x-2">
            <div className=" flex-1">
              <Label className="text-xs">{t("end-date")}*</Label>
              <DatePicker
                value={blockEndDate}
                onValueChange={setBlockEndDate}
                calendarProps={
                  blockStartDate
                    ? {
                        disabled: {
                          before: blockStartDate,
                        },
                      }
                    : blockStartDate
                }
              />
            </div>
          </div>

          <div className="mt-2">
            <Label className="text-xs">{t("block")}*</Label>
            <Select
              value={blockType}
              onValueChange={(value) =>
                setBlockType(value as "out-of-service" | "out-of-order")
              }
            >
              <SelectTrigger className="min-w-[170px] text-xs">
                <SelectValue placeholder={t("choose-block")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="out-of-service" className=" text-xs">
                  {t("out-of-service")}
                </SelectItem>

                <SelectItem value="out-of-order" className=" text-xs">
                  {t("out-of-order")}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="mt-2">
            <Label className="text-xs">{t("reason")}*</Label>
            <Textarea
              value={blockReason}
              onChange={(e) => setBlockReason(e.target.value)}
            />
          </div>
        </div>

        <div className=" flex justify-end space-x-2 ">
          <Button
            variant="outline"
            onClick={() => {
              setBlockStartDate(undefined);
              setBlockEndDate(undefined);
              setBlockType(undefined);
              setBlockReason("");
              onClose();
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            disabled={
              !blockReason ||
              !blockStartDate ||
              !blockEndDate ||
              !blockType ||
              isBefore(blockEndDate, blockStartDate)
            }
            onClick={onBlockBookable}
            loading={blockLoading}
          >
            {t("continue")}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};
