import { Input } from "@primitives/input.tsx";
import {
  CategoryClusterContext,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CamprType } from "../../../../../../api-contracts/categories";
import { CreateCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useCreateCluster,
  useGetCategoryClusters,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Button } from "@primitives/button.tsx";
import { Label } from "@primitives/label.tsx";
import { Space } from "@components/space.tsx";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedClusterCategories: number;
  selectedClusters: number;
}

export const CreateClusterDialog: FC<Props> = ({
  open,
  onOpenChange,
  selectedClusterCategories,
  selectedClusters,
}) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: createCluster } = useCreateCluster();
  const { t } = useTranslation();
  const [clusterName, setClusterName] = useState("");

  const createClusters = async () => {
    const types: CamprType[] = clusterData.categoryTypes
      .filter((cat: ClusterCategory) => {
        return cat.selected;
      })
      .map((cat: ClusterCategory) => {
        return cat.category;
      });
    const cluster: CreateCategoryClusterRequest = {
      name: clusterName.toLowerCase(),
      categoryTypes: types,
    };
    try {
      await createCluster(cluster).then();
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
    onOpenChange(false);
  };

  useEffect(() => {
    const selectedCat = clusterData?.categoryTypes.filter(
      (category: ClusterCategory) => {
        if (category.selected) return category.category;
      },
    );
  }, [clusterData]);

  return (
    <>
      <DropdownMenuRoot open={open} onOpenChange={onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant={"outline"}>
            {t("create-new-cluster")}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          sideOffset={10}
          className=" min-w-[500px] rounded-[8px] p-4"
        >
          <Label className="pb-4 text-lg font-bold">
            {t("create-new-cluster")}
          </Label>
          <Space h={20} />
          <Input
            className={"font-neue text-[15px] font-normal text-secondary-text"}
            type={"text"}
            value={clusterName}
            onChange={(e) => setClusterName(e.target.value)}
            placeholder={"Name"}
          />
          <div className={"mb-4 mt-5 flex"}>
            {clusterData?.categoryTypes.map((category: ClusterCategory) => {
              if (category.selected)
                return (
                  <CategoryCard
                    key={category.category}
                    category={category}
                    showCheck={false}
                    selectedClusterCategories={selectedClusterCategories}
                    selectedClusters={selectedClusters}
                  />
                );
            })}
          </div>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className={"h-[44px] rounded-xl"}
              variant={"outline"}
              onClick={() => {
                setClusterName("");
                onOpenChange(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              className={"h-[44px] rounded-xl"}
              disabled={clusterName.length === 0}
              onClick={() => {
                createClusters().then();
                setClusterName("");
                onOpenChange(false);
              }}
            >
              {t("save")}
            </Button>
          </div>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </>
  );
};
