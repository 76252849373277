import { createMutation } from "react-query-kit";
import {
  FullSearchRequest,
  FullSearchResponse,
  SimpleSearchRequest,
  SimpleSearchResponse,
} from "../../../../api-contracts/search";
import { api } from "./api";
import { currentSite } from "@context/site-context";

export const SIMPLE_SEARCH_QUERY_KEY = "search/simple";
export const FULL_SEARCH_QUERY_KEY = "search/full";

// POST /api/:siteId/search/simple
export const usePostSimpleSearch = createMutation<
  SimpleSearchResponse,
  SimpleSearchRequest
>({
  mutationFn: async (searchPayload) =>
    api.post(
      `/api/${currentSite?.id}/${SIMPLE_SEARCH_QUERY_KEY}`,
      searchPayload,
    ),
});

// POST /api/:siteId/search/full
export const usePostFullSearch = createMutation<
  FullSearchResponse,
  FullSearchRequest
>({
  mutationFn: async (searchPayload) =>
    api.post(`/api/${currentSite?.id}/${FULL_SEARCH_QUERY_KEY}`, searchPayload),
});
