import { FilterSelector } from "./filter-select";
import { InputWithLabel } from "./input-with-label";
import { Tags } from "./tags";
import { useGetReservationStatuses } from "@api/statuses";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterOption, Filters } from "@pages/simple-search/search-types";

export const BookingInformationFilter = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    reservationId: filters.bookingInformation.reservationId,
    state: filters.bookingInformation.state,
    tags: filters.bookingInformation.tags,
  });
  const { data: reservationStatuses } = useGetReservationStatuses();
  const { t } = useTranslation();

  // TODO Change from camelCase to kebab-case in BE?
  const statusOptions: FilterOption[] = reservationStatuses
    ? Object.keys(reservationStatuses).map((key) => {
        let translatedState;
        key === "noShow"
          ? (translatedState = "no-show")
          : (translatedState = key);

        return {
          id: translatedState,
          name: t(translatedState),
        };
      })
    : [];

  const handleInputChange = (
    key: keyof typeof tempInput,
    value: string | string[],
  ) => {
    setTempInput((prev) => ({ ...prev, [key]: value }));
    updateFilter("bookingInformation", key, value);
  };

  useEffect(() => {
    setTempInput({
      reservationId: filters.bookingInformation.reservationId,
      state: filters.bookingInformation.state,
      tags: filters.bookingInformation.tags || [],
    });
  }, [filters.bookingInformation]);

  return (
    <div className="flex flex-col pb-4">
      <InputWithLabel
        label={t("reservation-id")}
        value={tempInput.reservationId}
        onChange={(e) =>
          handleInputChange("reservationId", e.target.value.trim())
        }
      />
      <InputWithLabel label={t("external-reservation-number")} disabled />
      <InputWithLabel label={t("block-id")} disabled />
      <InputWithLabel label={t("block-name")} disabled />
      <InputWithLabel label={t("allotment-id")} disabled />
      <InputWithLabel label={t("allotment-name")} disabled />
      <FilterSelector
        disabled
        label={t("chooose-booking-channel")}
        options={[{ id: "", name: "Not implemented" }]}
        selectedOption={undefined}
        setSelectedOption={() => {}}
      />
      <FilterSelector
        disabled
        label={t("choose-target-audience")}
        options={[{ id: "", name: "Not implemented" }]}
        selectedOption={undefined}
        setSelectedOption={() => {}}
      />
      <FilterSelector
        label={t("choose-booking-status")}
        options={statusOptions}
        selectedOption={
          tempInput.state
            ? statusOptions.find((option) => option.id === tempInput.state)
            : undefined
        }
        setSelectedOption={(option) =>
          handleInputChange("state", option?.id ?? "")
        }
      />

      <Tags
        selectedTags={tempInput.tags ?? []}
        onValueChange={(newTagNames) => handleInputChange("tags", newTagNames)}
      />
    </div>
  );
};
