import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "@primitives/table";
import {
  Column,
  SortingState,
  flexRender,
  Table as TableType,
} from "@tanstack/react-table";
import {
  ChevronsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "lucide-react";
import { useTranslation } from "react-i18next";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData, TValue> {
    group?: string;
  }
}

type SearchTableProps<T extends { reservationId: string }> = {
  table: TableType<T>;
  onRowClick: (reservationId: string) => void;
};
export const SearchTable = <T extends { reservationId: string }>({
  table,
  onRowClick,
}: SearchTableProps<T>) => {
  const { t } = useTranslation();

  const getSortIcon = (
    column: Column<T, unknown>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };

  return (
    <>
      <Table id="table" className="whitespace-nowrap rounded-md print:block">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="group">
              {headerGroup.headers.map((header) => {
                const isGroupedHeader = header.colSpan > 1;
                return (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={` 
              ${header.column.columnDef.meta?.group === "guest" ? "bg-secondary-card-backplate" : ""}
              ${header.column.columnDef.meta?.group === "booker" ? "bg-secondary-card-backplate" : ""}
              ${isGroupedHeader ? "pointer-events-none" : ""}
            `}
                  >
                    {header.isPlaceholder ? (
                      <div className="h-full w-full">&nbsp;</div>
                    ) : (
                      <div
                        className="flex cursor-pointer items-center"
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort() && (
                          <div className="ml-1">
                            {getSortIcon(
                              header.column,
                              table.getState().sorting,
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  className="group cursor-pointer"
                  onClick={() => onRowClick(row.original.reservationId)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellGroup = cell.column.columnDef.meta?.group;

                    return (
                      <TableCell
                        key={cell.id}
                        style={{
                          width: `${cell.column.getSize()}px`,
                        }}
                        className={`
                  ${cellGroup === "guest" ? "bg-secondary-card-backplate" : ""}
                  ${cellGroup === "booker" ? "bg-secondary-card-backplate" : ""}
                `}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllLeafColumns().length}
                className="h-24 text-center"
              >
                {t("no-results")}.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};
