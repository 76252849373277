import { createMutation, createQuery } from "react-query-kit";
import { api } from "@shared/api/api.ts";
import { currentSite } from "@shared/context/site-context.tsx";
import {
  CopyDefaultTextRequest,
  CopyDefaultTextResponse,
  CreateCommunicationSphereRequest,
  CreateCommunicationSphereResponse,
  DeleteCommunicationSphereRequest,
  DeleteCommunicationSphereResponse,
  DuplicateCommunicationSphereRequest,
  DuplicateCommunicationSphereResponse,
  GetCommunicationSphereAuditResponse,
  GetCommunicationSphereResponse,
  GetCommunicationSpheresResponse,
  GetGroupedCommunicationVariablesResponse,
  PatchCommunicationSphereRequest,
  PatchCommunicationSphereResponse,
  PreviewTemplateRequest,
  PreviewTemplateResponse,
  UploadAttachmentResponse,
} from "../../../../api-contracts/communication-spheres";

export const COMMUNICATION_SPHERES_QUERY_KEY = "communication-spheres";

export const useGetCommunicationSpheres =
  createQuery<GetCommunicationSpheresResponse>({
    primaryKey: COMMUNICATION_SPHERES_QUERY_KEY,

    queryFn: async () => {
      return api.get(
        `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}`,
      );
    },
  });

export const useGetCommunicationSphere = createQuery<
  GetCommunicationSphereResponse,
  { id: string }
>({
  primaryKey: COMMUNICATION_SPHERES_QUERY_KEY,
  queryFn: async ({ queryKey: [_, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}`,
    );
  },
});

export const useGetCommunicationSphereAudit = createQuery<
  GetCommunicationSphereAuditResponse,
  { id: string }
>({
  primaryKey: `${COMMUNICATION_SPHERES_QUERY_KEY}/audit-log`,
  queryFn: async ({ queryKey: [_, { id }] }) => {
    return api.get(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}/audit-log`,
    );
  },
});

export const useCreateCommunicationSphere = createMutation<
  CreateCommunicationSphereResponse,
  CreateCommunicationSphereRequest
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}`,
      data,
    ),
});

export const usePatchCommunicationSphere = createMutation<
  PatchCommunicationSphereResponse,
  PatchCommunicationSphereRequest
>({
  mutationFn: async ({ id, ...data }) =>
    api.patch(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}`,
      data,
    ),
});

export const useDuplicateCommunicationSphere = createMutation<
  DuplicateCommunicationSphereResponse,
  DuplicateCommunicationSphereRequest
>({
  mutationFn: async ({ id }) =>
    api.post(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}/duplicate`,
      {},
    ),
});

export const useDeleteCommunicationSphere = createMutation<
  DeleteCommunicationSphereResponse,
  DeleteCommunicationSphereRequest
>({
  mutationFn: async ({ id }) =>
    api.delete(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/${id}`,
    ),
});

export const useCopyDefaultCommunicationSphereContent = createMutation<
  CopyDefaultTextResponse,
  CopyDefaultTextRequest
>({
  mutationFn: async (data) =>
    api.post(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/copy-default`,
      data,
    ),
});

export const useGetCommunicationSphereVariables = createQuery<
  GetGroupedCommunicationVariablesResponse,
  {}
>({
  primaryKey: `${COMMUNICATION_SPHERES_QUERY_KEY}/variables`,
  queryFn: async () => {
    return api.get(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/variables`,
    );
  },
});

export const useGetCommunicationSpherePreview = createQuery<
  PreviewTemplateResponse,
  PreviewTemplateRequest
>({
  primaryKey: `${COMMUNICATION_SPHERES_QUERY_KEY}/preview`,
  queryFn: async ({ queryKey: [_, args] }) => {
    return api.post(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/preview`,
      args,
    );
  },
});

export const useUploadAttachment = createMutation<
  UploadAttachmentResponse,
  { file: File }
>({
  mutationFn: async ({ file }) =>
    api.upload(
      `/api/${currentSite?.id}/${COMMUNICATION_SPHERES_QUERY_KEY}/attachment`,
      file,
    ),
});
