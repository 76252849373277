import { DefaultSideSheet } from "@primitives/default-sheet.tsx";
import { DefaultInput } from "@primitives/default-input.tsx";
import {
  ChannelsActionType,
  useChannelsPageContext,
} from "../channels-context.tsx";
import { useUpdateChannel } from "@api/channels.ts";
import { PatchChannelRequest } from "../../../../../../api-contracts/channels";
import { useSharedContext } from "@context/shared-context.tsx";
import { useTranslation } from "react-i18next";

export const EditChannel = () => {
  const {
    sharedState: { actionType, current },
    sharedFunctions: { setActionType, handleRefetch },
  } = useChannelsPageContext();
  const {
    sharedFunctions: { toast, setLoading },
  } = useSharedContext();

  const { mutate: editChannel } = useUpdateChannel();
  const { t } = useTranslation();

  const updateChannel = (id: string, req: PatchChannelRequest) => {
    setLoading(true);
    editChannel(
      { id, body: req },
      {
        onError: (e) => {
          toast({
            variant: "destructive",
            title: t("update-channel-error", { error: e.message }),
          });
        },
        onSuccess: async () => {
          await handleRefetch();
        },
        onSettled: () => {
          setLoading(false);
          setActionType(ChannelsActionType.DEFAULT);
        },
      },
    );
  };

  return (
    <DefaultSideSheet
      open={actionType === ChannelsActionType.EDIT}
      onOpenChange={() => setActionType(ChannelsActionType.DEFAULT)}
      side="left"
      title={t("edit-channel")}
    >
      <DefaultInput
        onSubmit={(value: string) => {
          current && updateChannel(current.id, { name: value, url: value });
        }}
        buttonText={t("save")}
        inputName="channel-edit-name"
        placeholder={t("channel-name")}
        required={true}
        defaultValue={current?.name}
        label={t("name")}
      />
    </DefaultSideSheet>
  );
};
