import { FilterOption } from "@pages/simple-search/search-types";
import { Label } from "@primitives/label";
import { MultiSelect } from "@primitives/multi-select";

export const FilterSelector = ({
  label,
  options = [],
  selectedOption,
  setSelectedOption,
  disabled,
}: {
  label: string;
  options?: FilterOption[];
  selectedOption?: FilterOption;
  setSelectedOption: (option: FilterOption | undefined) => void;
  disabled?: boolean;
}) => {
  const handleChange = (val: FilterOption[]) => {
    const selected = val[0];
    if (
      selected &&
      selectedOption &&
      String(selected.id) === String(selectedOption.id)
    ) {
      setSelectedOption(undefined);
    } else {
      setSelectedOption(selected);
    }
  };

  return (
    <div className={`my-2 ${disabled ? "text-status-disabled" : ""}`}>
      <div className="mb-1">
        <Label>{label}</Label>
      </div>
      <MultiSelect
        className={`${disabled ? "cursor-not-allowed" : ""}`}
        disabled={disabled}
        options={options}
        value={selectedOption ? [selectedOption] : []}
        onChange={handleChange}
        getName={(opt) => opt.name}
        multiple={false}
      />
    </div>
  );
};
