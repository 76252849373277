import React, { FC, useContext, useEffect, useState } from "react";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import {
  CategoryClusterContext,
  Cluster,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { RadioGroup, RadioGroupItem } from "@primitives/radio-group.tsx";
import { Label } from "@primitives/label.tsx";
import { queryClient } from "../../../../query-client.ts";
import {
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import { Space } from "@components/space.tsx";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Button } from "@primitives/button.tsx";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const MoveCategoriesDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const { t } = useTranslation();
  const [clusterToMoveTo, setClusterToMoveTo] = useState<Cluster>();

  const patchClusters = async () => {
    let selectedCategories = clusterData.categoryTypes
      .filter((cat: ClusterCategory) => cat.selected)
      .map((cat: ClusterCategory) => cat.category);
    if (clusterToMoveTo)
      selectedCategories = [
        ...selectedCategories,
        ...clusterToMoveTo.categories.map((cat) => cat.category),
      ];
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: clusterToMoveTo?.name,
      categoryTypes: selectedCategories,
    };
    try {
      await patchCluster({
        patch: cluster,
        id: clusterToMoveTo?.id ?? "",
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: clusterToMoveTo?.name }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
    onOpenChange(false);
  };

  useEffect(() => {
    setClusterToMoveTo(clusterData?.clusters[0]);
  }, [clusterData?.clusters]);

  return (
    <>
      <DropdownMenuRoot open={open} onOpenChange={onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant={"outline"}>
            {t("move-category-types")}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          sideOffset={10}
          className=" min-w-[500px] rounded-[8px] p-4"
        >
          <Label className="pb-4 text-lg font-bold">
            <div className={"mb-[15px] flex"}>
              <p>{t("move-category-types")}</p>
              <Space w={5} />
              <p>
                {clusterData?.categoryTypes.map((cl: ClusterCategory) =>
                  cl.selected ? t(cl.category) + " " : "",
                )}
              </p>
              <Space w={5} />
              <p>{t("to").toLowerCase()}:</p>
            </div>
          </Label>
          <RadioGroup defaultValue={clusterToMoveTo?.name}>
            {clusterData?.clusters.map((cl: Cluster) => (
              <div key={cl.name} className={"flex text-center"}>
                <RadioGroupItem
                  value={cl.name}
                  onClick={() => {
                    setClusterToMoveTo(cl);
                  }}
                />
                <Label
                  className={
                    "ml-1 text-[14px] font-extrabold text-primary-text"
                  }
                >
                  {cl.name}
                </Label>
              </div>
            ))}
          </RadioGroup>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className={"h-[44px] rounded-xl"}
              variant={"outline"}
              onClick={() => onOpenChange(false)}
            >
              {t("cancel")}
            </Button>
            <Button className={"h-[44px] rounded-xl"} onClick={patchClusters}>
              {t("move")}
            </Button>
          </div>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </>
  );
};
