import { useGetCategories } from "@api/categories";
import { useProfileContext } from "@context/profile-context";
import { useEffect, useState } from "react";
import { FilterOption, Filters } from "@pages/simple-search/search-types";
import { Loading } from "@primitives/loading";
import { useGetAssets } from "@api/assets";
import { FilterSelector } from "./filter-select";
import { useTranslation } from "react-i18next";

export const UnitAndCategory = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    unitCategory: {
      category: {
        id: filters.unitCategory.category.id ?? "",
        name: filters.unitCategory.category.name ?? "",
      },
      bookable: {
        id: filters.unitCategory.bookable.id ?? "",
        name: filters.unitCategory.bookable.name ?? "",
      },
    },
  });

  const { t } = useTranslation();
  const { activeCluster } = useProfileContext();

  const allCategoryTypes = activeCluster
    .map((types) => types.categoryTypes)
    .flat();

  const { data: categoryData, isLoading: catIsLoading } = useGetCategories({
    variables: { categoryTypes: allCategoryTypes },
  });

  const { data: assetData, isLoading: assetIsLoading } = useGetAssets({
    variables: {},
  });

  const categoryOptions = categoryData?.map((cat) => ({
    id: cat.id,
    name: cat.name,
  }));

  const assetOptions = assetData?.map((asset) => ({
    id: asset.id,
    name: asset.name,
  }));

  const handleInputChange = (
    key: keyof (typeof tempInput)["unitCategory"],
    value: FilterOption,
  ) => {
    setTempInput((prev) => {
      if (prev.unitCategory[key].id === value.id) return prev;
      return {
        ...prev,
        unitCategory: {
          ...prev.unitCategory,
          [key]: value,
        },
      };
    });

    updateFilter("unitCategory", key, value);
  };

  useEffect(() => {
    setTempInput({
      unitCategory: {
        category: {
          id: filters.unitCategory.category.id ?? "",
          name: filters.unitCategory.category.name ?? "",
        },
        bookable: {
          id: filters.unitCategory.bookable.id ?? "",
          name: filters.unitCategory.bookable.name ?? "",
        },
      },
    });
  }, [filters.unitCategory]);

  return (
    <div className="flex flex-col pb-4">
      <div>
        {catIsLoading && categoryOptions ? (
          <Loading />
        ) : (
          <FilterSelector
            label={t("category")}
            options={categoryOptions ?? []}
            selectedOption={
              tempInput.unitCategory.category || { name: "", id: "" }
            }
            setSelectedOption={(option) => {
              handleInputChange(
                "category",
                (option as FilterOption) ?? { name: "", id: "" },
              );
            }}
            disabled={catIsLoading}
          />
        )}
      </div>
      <div>
        {assetIsLoading && assetOptions ? (
          <Loading />
        ) : (
          <FilterSelector
            label={t("asset")}
            options={assetOptions ?? []}
            selectedOption={
              tempInput.unitCategory.bookable || { name: "", id: "" }
            }
            setSelectedOption={(option) => {
              handleInputChange(
                "bookable",
                (option as FilterOption) ?? { name: "", id: "" },
              );
            }}
            disabled
          />
        )}
      </div>
    </div>
  );
};
