import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@primitives/table";
import {
  Column,
  SortingState,
  flexRender,
  Table as TableType,
} from "@tanstack/react-table";
import { t } from "i18next";
import {
  ChevronsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "lucide-react";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData, TValue> {
    group?: string;
  }
}

type OrganizationTableProps<T> = {
  table: TableType<T>;
  onRowClick: (id: string) => void;
  selectedRowIds: string[];
};

export const OrganizationTable = <T extends { id: string }>({
  table,
  onRowClick,
  selectedRowIds,
}: OrganizationTableProps<T>) => {
  const getSortIcon = (
    column: Column<T, unknown>,
    sortingState: SortingState,
  ) => {
    const sort = sortingState.find((s) => s.id === column.id);
    if (!sort) return <ChevronsUpDownIcon size={13} />;
    return sort.desc ? (
      <ChevronDownIcon size={13} />
    ) : (
      <ChevronUpIcon size={13} />
    );
  };
  return (
    <Table
      id="table"
      className="whitespace-nowrap rounded-md border-t border-highlighted-backplate print:block"
    >
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="group">
            {headerGroup.headers.map((header) => {
              const isGroupedHeader = header.colSpan > 1;
              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  className={` 
              ${header.column.columnDef.meta?.group === "address" ? "bg-secondary-card-backplate" : ""}
              ${isGroupedHeader ? "pointer-events-none" : ""}
            `}
                >
                  {header.isPlaceholder ? (
                    <div className="h-full w-full">&nbsp;</div>
                  ) : (
                    <div
                      className="flex cursor-pointer items-center"
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanSort() && (
                        <div className="ml-1">
                          {getSortIcon(header.column, table.getState().sorting)}
                        </div>
                      )}
                    </div>
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => {
            return (
              <TableRow
                key={row.id}
                className={`group cursor-pointer ${selectedRowIds.includes(row.original.id) ? "bg-amber-500-10" : ""}`}
                onClick={() => onRowClick(row.id)}
              >
                {row.getVisibleCells().map((cell) => {
                  const cellGroup = cell.column.columnDef.meta?.group;
                  return (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}px`,
                      }}
                      className={`
                  ${cellGroup === "address" ? "bg-secondary-card-backplate" : ""}
                 
                `}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              colSpan={table.getAllLeafColumns().length}
              className="h-24 text-center"
            >
              {t("no-results")}.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
