import { Button } from "@primitives/button";
import { SearchBarWithIcon } from "@primitives/search-bar-with-icon";
import { FilterIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationTable } from "./organization-table";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { OrganizationCustomer } from "../../../../../api-contracts/customer/customer-list";
import { HighlightText } from "@pages/settings/categories/components/highlight-text";
import { capitalizeFirstLetter } from "@shared/utils/helpers";
import { Checkbox } from "@primitives/checkbox";

const organizationCustomers: OrganizationCustomer[] = [
  {
    id: "org-1",
    name: "Globala Företag AB",
    organizationNumber: "556123-4567",
    address: "Storgatan 1",
    address2: "Våning 2",
    city: "Stockholm",
    state: null,
    zip: "11122",
    country: "Sverige",
    phone: "+46-8-123-4567",
    phone2: "+46-8-123-4568",
    email: "info@globalaforetag.se",
    invoiceReference: "FAKT-123",
    invoiceName: "Globala Företag Ekonomi",
    invoiceAddress: "Storgatan 1",
    invoiceAddress2: "Våning 2",
    invoiceZip: "11122",
    invoiceCity: "Stockholm",
    invoiceState: null,
    invoiceCountry: "Sverige",
    organizationType: "travel-agency",
    IATA: "GF123",
  },
  {
    id: "org-2",
    name: "Tech Innovatörer AB",
    organizationNumber: "556789-0123",
    address: "Innovationsvägen 10",
    address2: null,
    city: "Göteborg",
    state: null,
    zip: "41104",
    country: "Sverige",
    phone: "+46-31-987-6543",
    phone2: null,
    email: "kontakt@techinnovatörer.se",
    invoiceReference: "FAKT-456",
    invoiceName: "Tech Innovatörer Ekonomi",
    invoiceAddress: "Innovationsvägen 10",
    invoiceAddress2: null,
    invoiceZip: "41104",
    invoiceCity: "Göteborg",
    invoiceState: null,
    invoiceCountry: "Sverige",
    organizationType: "organization",
    IATA: null,
  },
];

export const TableContainer = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    OrganizationCustomer[]
  >([]);

  const { t } = useTranslation();
  const columnHelper = createColumnHelper<OrganizationCustomer>();

  const toggleRow = (row: OrganizationCustomer) => {
    setSelectedOrganizations((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (selectedRow) => selectedRow.id === row.id,
      );

      if (isSelected) {
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id,
        );
      }
      return [...prevSelectedRows, row];
    });
  };

  const toggleAll = () => {
    const isAllSelected =
      selectedOrganizations.length === organizationCustomers.length;

    if (isAllSelected) {
      setSelectedOrganizations([]);
    } else {
      setSelectedOrganizations(organizationCustomers);
    }
  };

  const organizationColumnDefinition = [
    columnHelper.group({
      header: () => {
        const isAllRowsSelected =
          selectedOrganizations.length === organizationCustomers.length;
        return (
          <Checkbox
            className={`h-3 w-3`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={isAllRowsSelected}
            onCheckedChange={() => toggleAll()}
          />
        );
      },
      id: "selectAll",
      columns: [
        columnHelper.display({
          id: "select",
          size: 5,
          enableHiding: false,
          cell: ({ row }) => {
            const isRowSelected = selectedOrganizations.some(
              (selectedRow) => selectedRow.id === row.original.id,
            );
            return (
              <Checkbox
                className={`${isRowSelected ? "visible" : "invisible"} h-3 w-3 group-hover:visible`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                checked={isRowSelected}
                onCheckedChange={() => toggleRow(row.original)}
              />
            );
          },
        }),
      ],
    }),
    columnHelper.group({
      header: t("company-data"),
      id: "companyData",
      columns: [
        columnHelper.accessor("id", {
          header: t("customer-number"),
          id: "customerNumber",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("name", {
          header: t("organization-name"),
          id: "organizationName",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("organizationType", {
          header: t("organization-type"),
          id: "organizationType",
          cell: ({ getValue }) => {
            const orgType = getValue();
            const classNameStyle =
              orgType === "organization"
                ? "bg-amber-500-10 text-amber-500"
                : "bg-booking-confirmed-100 text-booking-confirmed";
            return (
              <div className="flex">
                <div
                  className={`${classNameStyle} rounded-md px-2 py-1 text-center font-bold`}
                >
                  {capitalizeFirstLetter(
                    t(orgType === "organization" ? "company" : orgType),
                  )}
                </div>
              </div>
            );
          },
        }),
        columnHelper.accessor("organizationNumber", {
          header: t("organization-number"),
          id: "organizationNumber",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("IATA", {
          header: t("iata-number"),
          id: "iata",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("phone", {
          header: t("phone"),
          id: "phone",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
        columnHelper.accessor("email", {
          header: t("email"),
          id: "email",
          cell: ({ getValue }) => (
            <HighlightText text={getValue()} textToHighlight={searchTerm} />
          ),
        }),
      ],
    }),
    columnHelper.group({
      header: t("address"),
      id: "address",
      meta: { group: "address" },
      columns: [
        columnHelper.accessor("address", {
          header: `${t("address")} 1`,
          id: "adress1",
          meta: { group: "address" },
        }),
        columnHelper.accessor("address2", {
          header: `${t("address")} 2`,
          id: "adress2",
          meta: { group: "address" },
        }),
        columnHelper.accessor("zip", {
          header: t("zip"),
          id: "zip",
          meta: { group: "address" },
        }),
        columnHelper.accessor("city", {
          header: t("city"),
          id: "city",
          meta: { group: "address" },
        }),
        columnHelper.accessor("state", {
          header: t("state-province-region"),
          id: "stateProvinceRegion",
          meta: { group: "address" },
        }),
        columnHelper.accessor("country", {
          header: t("country"),
          id: "country",
          meta: { group: "address" },
        }),
      ],
    }),
  ];

  const organizationTable = useReactTable({
    data: organizationCustomers,
    columns: organizationColumnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <>
      <div className="flex justify-between p-4 pt-0">
        <SearchBarWithIcon
          isExpanded={isSearchExpanded}
          setIsExpanded={setIsSearchExpanded}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Button variant={"secondary"}>
          <FilterIcon size="15px" className="mr-1" />
          <p>{t("show")}</p>
        </Button>
      </div>
      <div className="py-4  text-primary-text">
        <OrganizationTable<OrganizationCustomer>
          selectedRowIds={selectedOrganizations.map((org) => org.id)}
          table={organizationTable}
          onRowClick={() => {}}
        />
      </div>
    </>
  );
};
