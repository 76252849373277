import { useEffect, useMemo, useState } from "react";
import { InputWithLabel } from "./input-with-label";
import { Filters } from "@pages/simple-search/search-types";
import { debounce } from "lodash";
import { t } from "i18next";

export const Date = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    arrivalDate: {
      from: filters.date.arrivalDate.from,
      to: filters.date.arrivalDate.to,
    },
    departureDate: {
      from: filters.date.departureDate.from,
      to: filters.date.departureDate.to,
    },
    reservationDate: {
      from: filters.date.reservationDate.from,
      to: filters.date.reservationDate.to,
    },
    updatedDate: {
      from: filters.date.updatedDate.from,
      to: filters.date.updatedDate.to,
    },
  });

  const handleInputChange = (
    key: keyof typeof tempInput,
    type: "from" | "to",
    value: string,
  ) => {
    setTempInput((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [type]: value,
      },
    }));

    updateFilter("date", key, {
      ...tempInput[key],
      [type]: value,
    });
  };

  useEffect(() => {
    setTempInput({
      arrivalDate: {
        from: filters.date.arrivalDate.from,
        to: filters.date.arrivalDate.to,
      },
      departureDate: {
        from: filters.date.departureDate.from,
        to: filters.date.departureDate.to,
      },
      reservationDate: {
        from: filters.date.reservationDate.from,
        to: filters.date.reservationDate.to,
      },
      updatedDate: {
        from: filters.date.updatedDate.from,
        to: filters.date.updatedDate.to,
      },
    });
  }, [filters.date]);

  return (
    <div className="flex flex-col pb-4">
      <div className="flex">
        <InputWithLabel
          className="mr-4 flex w-full flex-col"
          label={t("arrival")}
          placeholder={t("from")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("arrivalDate", "from", e.target.value)
          }
          value={tempInput.arrivalDate.from}
        />
        <InputWithLabel
          className="flex w-full flex-col"
          label={"\u00A0"}
          placeholder={t("to")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("arrivalDate", "to", e.target.value)
          }
          value={tempInput.arrivalDate.to}
        />
      </div>

      <div className="mt-4 flex">
        <InputWithLabel
          className="mr-4 flex w-full flex-col"
          label={t("departure")}
          placeholder={t("from")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("departureDate", "from", e.target.value)
          }
          value={tempInput.departureDate.from}
        />
        <InputWithLabel
          className="flex w-full flex-col"
          label={"\u00A0"}
          placeholder={t("to")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("departureDate", "to", e.target.value)
          }
          value={tempInput.departureDate.to}
        />
      </div>

      <div className="mt-4 flex">
        <InputWithLabel
          className="mr-4 flex w-full flex-col"
          label={t("reservation-date")}
          placeholder={t("from")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("reservationDate", "from", e.target.value)
          }
          value={tempInput.reservationDate.from}
        />
        <InputWithLabel
          className="flex w-full flex-col"
          label={"\u00A0"}
          placeholder={t("to")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("reservationDate", "to", e.target.value)
          }
          value={tempInput.reservationDate.to}
        />
      </div>

      <div className="mt-4 flex">
        <InputWithLabel
          className="mr-4 flex w-full flex-col"
          label={t("change-date")}
          placeholder={t("from")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("updatedDate", "from", e.target.value)
          }
          value={tempInput.updatedDate.from}
        />
        <InputWithLabel
          className="flex w-full flex-col"
          label={"\u00A0"}
          placeholder={t("to")}
          showCalendarIcon
          onChange={(e) =>
            handleInputChange("updatedDate", "to", e.target.value)
          }
          value={tempInput.updatedDate.to}
        />
      </div>
    </div>
  );
};
