import { Input } from "@primitives/input.tsx";
import {
  CategoryClusterContext,
  Cluster,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import { CategoryCard } from "@pages/settings/category-cluster/components/category-card.tsx";
import React, { FC, useContext, useState } from "react";
import { CamprType } from "../../../../../../api-contracts/categories";
import { PatchCategoryClusterRequest } from "../../../../../../api-contracts/category-clusters";
import { queryClient } from "../../../../query-client.ts";
import {
  useGetCategoryClusters,
  usePatchClusterById,
} from "@api/category-clusters.ts";
import { toast } from "@hooks/use-toast.ts";
import { useTranslation } from "react-i18next";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu.tsx";
import { Button } from "@primitives/button.tsx";
import { Label } from "@primitives/label.tsx";
import { Space } from "@components/space.tsx";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedClusterCategories: number;
  selectedClusters: number;
}

export const ChangeNameDialog: FC<Props> = ({
  open,
  onOpenChange,
  selectedClusterCategories,
  selectedClusters,
}) => {
  const [clusterName, setClusterName] = useState("");
  const { clusterData } = useContext(CategoryClusterContext);
  const { mutateAsync: patchCluster } = usePatchClusterById();
  const { t } = useTranslation();

  const patchClusters = async () => {
    const selectedCluster = clusterData.clusters.filter(
      (cl: Cluster) => cl.selected,
    );
    const types: CamprType[] = selectedCluster[0].categories.map(
      (cat: ClusterCategory) => {
        return cat.category;
      },
    );
    // @ts-ignore
    const cluster: PatchCategoryClusterRequest = {
      name: clusterName,
      categoryTypes: types,
    };
    try {
      await patchCluster({
        patch: cluster,
        id: selectedCluster[0].id ?? "",
      });
      await queryClient.invalidateQueries({
        queryKey: useGetCategoryClusters.getKey(),
      });
      toast({
        title: t("saved-succesfully", { name: clusterName }),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenuRoot open={open} onOpenChange={onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant={"outline"}>
            {t("change-name")}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          sideOffset={10}
          className=" min-w-[500px] rounded-[8px] p-4"
        >
          <Label className="pb-4 text-lg font-bold">{t("change-name")}</Label>
          <Space h={20} />
          <Input
            className={"font-neue text-[15px] font-normal text-secondary-text"}
            type={"text"}
            value={clusterName}
            onChange={(e) => setClusterName(e.target.value)}
            placeholder={"Name"}
          />
          <div className={"mb-4 mt-5 flex"}>
            {clusterData?.clusters
              .filter((cl: Cluster) => cl.selected)
              .map((cl: Cluster) =>
                cl.categories.map((cat) => (
                  <CategoryCard
                    key={cat.category}
                    category={cat}
                    showCheck={false}
                    selectedClusterCategories={selectedClusterCategories}
                    selectedClusters={selectedClusters}
                  />
                )),
              )}
          </div>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className={"h-[44px] rounded-xl"}
              variant={"outline"}
              onClick={() => onOpenChange(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              className={"h-[44px] rounded-xl"}
              onClick={() => {
                patchClusters().then();
                setClusterName("");
                onOpenChange(false);
              }}
            >
              {t("save")}
            </Button>
          </div>
        </DropdownMenuContent>
      </DropdownMenuRoot>
    </>
  );
};
