import { Filters } from "@pages/simple-search/search-types";
import { Address } from "./address";
import { InputWithLabel } from "./input-with-label";
import { Tags } from "./tags";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const Booker = ({
  filters,
  updateFilter,
}: {
  filters: Filters;
  updateFilter: (
    section: keyof typeof filters,
    key: string,
    value: any,
  ) => void;
}) => {
  const [tempInput, setTempInput] = useState({
    name: filters.holder.name,
    surname: filters.holder.surname,
    phone: filters.holder.phone,
    email: filters.holder.email,
    tags: filters.holder.tags,
    address: filters.holder.address,
    address2: filters.holder.address2,
    zip: filters.holder.zip,
    city: filters.holder.city,
    state: filters.holder.state,
    countryCode: filters.holder.countryCode,
  });

  const { t } = useTranslation();

  const handleInputChange = (
    key: keyof typeof tempInput,
    value: string | string[],
  ) => {
    setTempInput((prev) => ({ ...prev, [key]: value }));
    updateFilter("holder", key, value);
  };

  useEffect(() => {
    setTempInput({ ...filters.holder });
  }, [filters.holder]);

  return (
    <div className="flex flex-col pb-4">
      <InputWithLabel
        label={t("first-name")}
        value={tempInput.name}
        onChange={(e) => handleInputChange("name", e.target.value)}
      />
      <InputWithLabel
        label={t("last-name")}
        value={tempInput.surname}
        onChange={(e) => handleInputChange("surname", e.target.value)}
      />
      <InputWithLabel
        label={t("phone")}
        value={tempInput.phone}
        onChange={(e) => handleInputChange("phone", e.target.value.trim())}
      />
      <InputWithLabel
        label={t("email")}
        value={tempInput.email}
        onChange={(e) => handleInputChange("email", e.target.value.trim())}
      />
      <Tags
        disabled
        selectedTags={tempInput.tags ?? []}
        onValueChange={(newTags) => handleInputChange("tags", newTags)}
      />
      <Address
        address={{
          address: tempInput.address,
          address2: tempInput.address2,
          zip: tempInput.zip,
          city: tempInput.city,
          state: tempInput.state,
          countryCode: tempInput.countryCode,
        }}
        handleInputChange={handleInputChange}
      />
    </div>
  );
};
