import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { CamprType } from "../../../../../api-contracts/categories";

import { useGetCategoryClusters } from "@api/category-clusters.ts";

type CategoryClusterContextValue = {
  clusterData: ClusterData;
  isLoading: boolean;
  selectCategory: (category: CamprType) => void;
  selectCluster: (id: string) => void;
  selectClusterCategory: (type: CamprType, id: string) => void;
};

export interface ClusterData {
  clusters: Cluster[];
  categoryTypes: ClusterCategory[];
}

export interface Cluster {
  id: string;
  name: string;
  categories: ClusterCategory[];
  selected: boolean;
}

export interface ClusterCategory {
  category: CamprType;
  selected: boolean;
}

interface CategoryClusterContextProps {
  children: ReactNode;
}

export const CategoryClusterContext =
  createContext<CategoryClusterContextValue>({} as CategoryClusterContextValue);

export const CategoryClusterContextProvider: FC<
  CategoryClusterContextProps
> = ({ children }) => {
  const { data: categoryClusters, isLoading } = useGetCategoryClusters({
    variables: { optionalSite: null },
  });
  const [clusterData, setClusterData] = useState<ClusterData>({
    clusters: [],
    categoryTypes: [],
  });

  const selectCategory = (type: CamprType) => {
    setClusterData((prevData: ClusterData) => ({
      ...prevData,
      categoryTypes: prevData.categoryTypes.map((category: ClusterCategory) =>
        category.category === type
          ? { ...category, selected: !category.selected }
          : category,
      ),
    }));
  };

  const selectCluster = (id: string) => {
    setClusterData((prevData: ClusterData) => ({
      ...prevData,
      clusters: prevData.clusters.map((cluster: Cluster) =>
        cluster.id === id
          ? { ...cluster, selected: !cluster.selected }
          : cluster,
      ),
    }));
  };

  const selectClusterCategory = (type: CamprType, id: string) => {
    setClusterData((prevData: ClusterData) => ({
      ...prevData,
      clusters: prevData.clusters.map((cluster: Cluster) =>
        cluster.id === id
          ? {
              ...cluster,
              categories: cluster.categories.map((category) =>
                category.category === type
                  ? { ...category, selected: !category.selected }
                  : category,
              ),
            }
          : cluster,
      ),
    }));
  };

  useEffect(() => {
    if (!categoryClusters && !categoryClusters) return;
    const transformedData: ClusterData = {
      clusters: categoryClusters?.clusters.map((cluster: any) => ({
        id: cluster.id,
        name: cluster.name,
        categories: cluster.categoryTypes.map((categoryType: string) => ({
          category: categoryType,
          selected: false,
        })),
        selected: false,
      })),
      categoryTypes: categoryClusters?.types?.map((type: any) => ({
        category: type,
        selected: false,
      })),
    };
    setClusterData(transformedData);
  }, [categoryClusters]);

  return (
    <CategoryClusterContext.Provider
      value={{
        clusterData,
        isLoading,
        selectCategory,
        selectCluster,
        selectClusterCategory,
      }}
    >
      {children}
    </CategoryClusterContext.Provider>
  );
};
