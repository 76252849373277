import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@primitives/dialog";
import { Button } from "@primitives/button";
import { useGetAllocableBookables } from "@api/bookables";
import { useGetCategories } from "@api/categories";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import { eachDayOfInterval, format, isSameDay, subDays } from "date-fns";
import { GetBooking } from "../../../../../../../api-contracts/reservations/index";
import { TimeSlot } from "../../../../../../../api-contracts/reservations/timeslots";
import { useGetPricing } from "@api/pricing";
import { useGetPriceRules } from "@api/price-rules";
import { priceRuleOverrideKeyToGuestCounts } from "@shared/utils/price-rules";
import { uniqueId } from "lodash";
import {
  addHoursAndMinutesToDateISO,
  dateWithTimeString,
  dateWithTimeStringISO,
} from "@shared/utils/helpers";

export const BookableSelectDialog: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  booking: GetBooking;
  onProceed: (changedBooking: GetBooking) => void;
}> = ({ open, onOpenChange, booking, onProceed }) => {
  const { t, i18n } = useTranslation();
  const [changedBooking, setChangedBooking] = useState<GetBooking>(booking);
  const [key, setKey] = useState(+new Date());

  useEffect(() => {
    setChangedBooking(booking);
  }, [booking, open]);

  const {
    data: priceRules,
    isLoading: priceRulesIsLoading,
    isRefetching: priceRulesIsRefetching,
  } = useGetPriceRules({
    variables: {
      categoryTypes: [changedBooking.category.type],
    },
    enabled: !!changedBooking.category.type,
  });

  const {
    data: categories,
    isLoading: categoriesLoading,
    isRefetching: categoriesRefetching,
  } = useGetCategories({
    variables: { categoryTypes: ["room", "area", "bed", "dormitory"] },
  });

  const {
    data: bookables,
    isLoading: bookablesLoading,
    isRefetching: bookablesRefetching,
  } = useGetAllocableBookables({
    variables: {
      bookingId: changedBooking.id,
      categoryId: changedBooking.category.id,
      startDate: format(booking.start, "yyyy-MM-dd"),
      endDate: format(booking.end, "yyyy-MM-dd"),
    },
  });

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetPricing({
    variables: {
      id: changedBooking.category.id,
      slots: changedBooking.slots.map((s) => ({
        startDate: format(s.start, "yyyy-MM-dd"),
        startTime:
          categories?.find((c) => changedBooking.category.id === c.id)?.config!
            .startTimes[0] || "14.00",
      })),
      adults: changedBooking.guestCount.adults,
      teenagers: changedBooking.guestCount.teenagers,
      children: changedBooking.guestCount.children,
      infants: changedBooking.guestCount.infants,
    },
    enabled: !!changedBooking.category.id && categories && open,
  });

  useEffect(() => {
    updateState(
      changedBooking.category.id,
      changedBooking.bookable?.id || null,
    );
  }, [pricing, priceRules]);

  const handleProceed = () => {
    onProceed(changedBooking);
    onOpenChange(false);
  };

  const updateState = (categoryId: string, bookableId: string | null) => {
    const category = categories?.find((c) => c.id === categoryId);
    if (!category) {
      setChangedBooking((changedBooking) => ({ ...changedBooking }));
      return;
    }

    const bookable =
      bookables?.bookables.find((b) => b.id === bookableId) ||
      (categoryId === booking.category.id && bookableId === booking.bookable?.id
        ? booking.bookable
        : undefined);

    const dates = eachDayOfInterval({
      start: changedBooking.start,
      end: subDays(changedBooking.end, 1),
    });
    const newSlots: TimeSlot[] = dates.map((date) => {
      const pricer = pricing?.slots.find((s) => isSameDay(date, s.startDate));
      const slot = booking.slots.find((s) => isSameDay(s.start, date));
      let priceRuleId = slot?.priceRuleId;
      const priceOverrideAmount = slot?.priceOverrideAmount || null;
      const priceAdjustmentPercent = slot?.priceAdjustmentPercent || null;

      let pr = priceRules?.rules.find(
        (r) =>
          (priceRuleId && r.id === priceRuleId) ||
          (!priceRuleId && pricer?.ruleId && r.id === pricer.ruleId),
      );
      if (priceRuleId && !pr) {
        pr = priceRules?.rules.find(
          (r) => pricer?.ruleId && r.id === pricer.ruleId,
        );
        priceRuleId = pricer?.ruleId || null;
      }
      const priceRule = pr?.entries.find((e) => e.categoryId === categoryId);
      const priceRuleOverride = priceRule?.overrides.find((o) => {
        const guestCounts = priceRuleOverrideKeyToGuestCounts(o.overrideKey);
        return (
          guestCounts.adults === changedBooking.guestCount.adults &&
          guestCounts.children === changedBooking.guestCount.children &&
          guestCounts.teenagers === changedBooking.guestCount.teenagers &&
          guestCounts.infants === changedBooking.guestCount.infants
        );
      });

      let fullPrice = priceRuleOverride?.price || priceRule?.price || 0;

      let price = priceOverrideAmount || fullPrice;

      if (priceAdjustmentPercent) {
        price = price * (1 - 0.01 * priceAdjustmentPercent);
      }

      return {
        id: slot?.id || uniqueId("new"),
        price: price,
        start: dateWithTimeStringISO(date, category.config!.startTimes[0]),
        end: addHoursAndMinutesToDateISO(
          dateWithTimeString(date, category.config!.startTimes[0]),
          category.config!.timeslotHours,
          category.config!.timeslotMinutes,
        ),
        priceRuleId: priceRuleId || pricer?.ruleId || null,
        priceRuleName: pr?.name || "",
        fullPrice: fullPrice,
        priceOverrideAmount: priceOverrideAmount,
        priceAdjustmentPercent: priceAdjustmentPercent,
      };
    });

    setChangedBooking((changedBooking) => ({
      ...changedBooking,
      start: dateWithTimeStringISO(
        changedBooking.start,
        category.config!.startTimes[0],
      ),
      end: newSlots[newSlots.length - 1].end,
      slots: newSlots,
      category: category,
      bookable: bookable ? { ...bookable, lockedToBookable: false } : null,
    }));
  };

  const total = useMemo(() => {
    return changedBooking.slots.reduce((acc, slot) => {
      return acc + slot.price;
    }, 0);
  }, [changedBooking]);

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className=" block h-screen !max-h-full sm:!h-[310px] sm:!w-[500px] sm:!max-w-full">
          <div className=" flex h-full flex-col ">
            <DialogTitle className=" text-lg font-extrabold text-primary-text">
              {t("assign-unit")}
            </DialogTitle>

            <div className=" flex-grow">
              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("category")}
              </p>
              <Select
                disabled={changedBooking.bookable?.lockedToBookable}
                value={changedBooking.category.id}
                onValueChange={(id) => {
                  const category = categories?.find((c) => c.id === id);
                  if (category) {
                    updateState(
                      category.id,
                      changedBooking.bookable?.id || null,
                    );
                  }
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {categories?.map((c) => (
                    <SelectItem key={c.id} value={c.id} className=" text-xs">
                      {c.short}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {(changedBooking.guestCount.adults +
                changedBooking.guestCount.children +
                changedBooking.guestCount.teenagers +
                changedBooking.guestCount.infants >
                changedBooking.category.capacity.total.max ||
                changedBooking.guestCount.adults >
                  changedBooking.category.capacity.adults.max ||
                changedBooking.guestCount.adults <
                  changedBooking.category.capacity.adults.min ||
                changedBooking.guestCount.teenagers >
                  changedBooking.category.capacity.teenagers.max ||
                changedBooking.guestCount.children >
                  changedBooking.category.capacity.children.max ||
                changedBooking.guestCount.infants >
                  changedBooking.category.capacity.infants.max) && (
                <p className=" mt-2 text-xs font-normal text-status-error">
                  {t(
                    "the-{{guests}}-guests-of-the-booking-do-not-fit-in-{{category}}",
                    {
                      category: changedBooking.category.name,
                      guests: changedBooking.guests.length,
                    },
                  )}
                  .
                </p>
              )}

              <p className=" mt-4 text-sm font-medium text-primary-text">
                {t("unit")}
              </p>
              <Select
                disabled={changedBooking.bookable?.lockedToBookable}
                value={changedBooking.bookable?.id || "none-selected"}
                onValueChange={(value) => {
                  const bookable = bookables?.bookables.find(
                    (b) => b.id === value,
                  );
                  updateState(changedBooking.category.id, bookable?.id || null);
                }}
              >
                <SelectTrigger>
                  <p>{changedBooking.bookable?.name}</p>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem
                    value={"none-selected"}
                    className=" text-xs text-secondary-text"
                  >
                    {t("none-selected")}
                  </SelectItem>
                  <SelectSeparator />
                  {bookables?.bookables?.map((b) => (
                    <SelectItem key={b.id} value={b.id} className=" text-xs">
                      {b.name}
                    </SelectItem>
                  ))}
                  {bookables?.bookables.length === 0 && (
                    <p className="p-2 text-center text-sm font-normal text-secondary-text">
                      {t("no-units-available")}
                    </p>
                  )}
                </SelectContent>
              </Select>
            </div>

            <div className=" mt-6 flex items-center justify-between space-x-2">
              <p className=" text-md font-bold">
                {t("total")}:{" "}
                {new Intl.NumberFormat(
                  i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                  { maximumFractionDigits: 2 },
                ).format(total)}{" "}
                SEK
              </p>
              <div className=" flex flex-nowrap space-x-2">
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                  {t("cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    changedBooking.bookable?.lockedToBookable ||
                    changedBooking.guestCount.adults +
                      changedBooking.guestCount.children +
                      changedBooking.guestCount.teenagers +
                      changedBooking.guestCount.infants >
                      changedBooking.category.capacity.total.max ||
                    changedBooking.guestCount.adults >
                      changedBooking.category.capacity.adults.max ||
                    changedBooking.guestCount.adults <
                      changedBooking.category.capacity.adults.min ||
                    changedBooking.guestCount.teenagers >
                      changedBooking.category.capacity.teenagers.max ||
                    changedBooking.guestCount.children >
                      changedBooking.category.capacity.children.max ||
                    changedBooking.guestCount.infants >
                      changedBooking.category.capacity.infants.max
                  }
                  onClick={handleProceed}
                >
                  {t("assign")}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
