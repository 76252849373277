import React, { FC, useContext, useState } from "react";
import { Space } from "@components/space.tsx";
import { Checkbox } from "@primitives/checkbox.tsx";
import clsx from "clsx";
import {
  CategoryClusterContext,
  Cluster,
  ClusterCategory,
} from "@pages/settings/category-cluster/category-cluster-context.tsx";
import {
  Bus,
  Caravan,
  Home,
  LayoutGrid,
  Store,
  Tent,
  TentTree,
} from "lucide-react";
import { useTranslation } from "react-i18next";

interface Props {
  category: ClusterCategory;
  cluster: Cluster;
  showCheck: boolean;
  selectedCategories: number;
  selectedClusters: number;
}
export const ClusterCategoryCard: FC<Props> = ({
  category,
  showCheck,
  cluster,
  selectedCategories,
  selectedClusters,
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);

  const { selectClusterCategory } = useContext(CategoryClusterContext);
  const getCardColor = () => {
    if (category.selected || !showCheck || cluster.selected)
      return "bg-amber-500";
    return "bg-amber-500-25";
  };

  const renderIcon = () => {
    switch (category.category) {
      case "caravan":
        return (
          <Caravan
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "tent":
        return (
          <Tent
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "motorhome":
        return (
          <Bus
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "glamping":
        return (
          <TentTree
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "mobile-home":
        return (
          <Store
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "cabin":
        return (
          <Home
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      case "misc":
        return (
          <LayoutGrid
            strokeWidth={1}
            className={
              category.selected || !showCheck || cluster.selected
                ? "text-black"
                : "text-primary-icon-color"
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={clsx(
        selectedClusters < 1 && selectedCategories < 1
          ? "cursor-pointer"
          : null,
      )}
      onMouseEnter={() => {
        setHover((prevState) => !prevState);
      }}
      onMouseLeave={() => setHover((prevState) => !prevState)}
    >
      <div
        className={clsx(
          "mr-3 h-[70px] w-[120px] rounded-md p-2",
          getCardColor(),
        )}
        onClick={() => {
          if (selectedCategories < 1 && selectedClusters < 1) {
            selectClusterCategory(category.category, cluster.id);
          }
        }}
      >
        {showCheck && !cluster.selected ? (
          <div
            className={clsx(
              "flex justify-between",
              selectedClusters > 0
                ? "pointer-events-none"
                : "pointer-events-auto",
            )}
          >
            {renderIcon()}
            {hover && selectedCategories < 1 && (
              <Checkbox checked={category.selected} />
            )}
            {category.selected && !hover && (
              <Checkbox checked={category.selected} />
            )}
          </div>
        ) : (
          <div>{renderIcon()}</div>
        )}
        <Space h={12} />
        <p
          className={clsx(
            "text-[12px] font-extrabold",
            category.selected || !showCheck || cluster.selected
              ? "text-quaternary-text"
              : "text-primary-text",
          )}
        >
          {t(category.category)}
        </p>
      </div>
    </div>
  );
};
