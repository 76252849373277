import {
  useCreatePriceRule,
  useGetPriceRules,
  useUpdatePriceRule,
} from "@api/price-rules";
import { Collapsible } from "@primitives/collapsible";
import { Input } from "@primitives/input";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import {
  ArrowDown,
  ChevronDown,
  ChevronUp,
  ChevronsDownUp,
  ChevronsUpDown,
} from "lucide-react";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CreatePriceRuleRequest,
  GetPriceRule,
} from "../../../../../../api-contracts/price-rules";
import { cn } from "@shared/utils/css";
import { Button } from "@primitives/button";
import { useToast } from "@hooks/use-toast";
import { queryClient } from "query-client";
import { CategoryType } from "../../../../../../api-contracts/categories";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  tab: CategoryType[];
  ruleToEdit?: GetPriceRule;
}

const PriceRuleDrawer = ({ open, onOpenChange, tab, ruleToEdit }: Props) => {
  const [t, i18n] = useTranslation();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [highlightedCategoryIds, setHighlightedCategoryIds] = useState<
    string[]
  >([]);
  const [highlightedOverrideKeys, setHighlightedOverrideKeys] = useState<{
    categoryId: string;
    overrideKeys: string[];
  }>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [priceRule, setPriceRule] = useState<CreatePriceRuleRequest>({
    name: ruleToEdit?.name ?? "",
    entries: ruleToEdit?.entries ?? [],
    categoryTypes: tab,
  });
  const { toast } = useToast();

  useEffect(() => {
    setPriceRule({
      name: ruleToEdit?.name ?? "",
      entries: ruleToEdit?.entries ?? [],
      categoryTypes: tab,
    });
  }, [tab, open, ruleToEdit]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetPriceRules.getKey(),
    });
  }, [ruleToEdit]);

  const createPriceRule = useCreatePriceRule();
  const updatePriceRule = useUpdatePriceRule();

  const {
    data: priceRules,
    isLoading: priceRulesLoading,
    isRefetching: isRefetchingPriceRules,
  } = useGetPriceRules({
    variables: {
      categoryTypes: tab,
    },
    enabled: open,
  });

  const handleCreatePriceRule = async () => {
    setCreateLoading(true);
    try {
      if (ruleToEdit) {
        await updatePriceRule.mutateAsync({
          ruleId: ruleToEdit.id,
          entries: priceRule.entries.map((e) => ({
            ...e,
            overrides:
              e.overrides?.filter(
                (o) => e.price !== null && o.price > e.price,
              ) ?? null,
          })),
          name: priceRule.name,
        });
      } else {
        await createPriceRule.mutateAsync({
          ...priceRule,
          entries: priceRule.entries.map((e) => ({
            ...e,
            overrides:
              e.overrides?.filter(
                (o) => e.price !== null && o.price > e.price,
              ) ?? null,
          })),
        });
      }
      queryClient.invalidateQueries({
        queryKey: useGetPriceRules.getKey(),
      });
      toast({
        variant: "success",
        title: t("saved-succesfully", { name: priceRule.name }),
        className: "text-status-success",
      });
      onOpenChange(false);
    } catch (err) {
      toast({
        variant: "destructive",
        title:
          t("request-failed-with") +
          ": " +
          t(decodeURIComponent((err as any)?.message || t("no-message"))),
        className: "text-status-error",
      });
    }
    setCreateLoading(false);
  };

  const rows = useMemo(() => {
    return (
      priceRules?.categories
        .sort((c1, c2) => c1.short.localeCompare(c2.short))
        .map((category) => {
          const overrides: {
            overrideKey: string;
            guestCounts: {
              adults: number;
              teenagers: number;
              children: number;
              infants: number;
              total: number;
            };
          }[] = [];

          const entry = priceRule?.entries.find(
            (e) => e.categoryId === category.id,
          );

          for (
            let a = category.categoryCapacity.adults.min;
            a <= category.categoryCapacity.adults.max &&
            a <= category.categoryCapacity.total.max;
            a++
          ) {
            for (
              let t = 0;
              t <= category.categoryCapacity.teenagers.max &&
              a + t <= category.categoryCapacity.total.max;
              t++
            ) {
              for (
                let c = 0;
                c <= category.categoryCapacity.children.max &&
                a + t + c <= category.categoryCapacity.total.max;
                c++
              ) {
                for (
                  let i = 0;
                  i <= category.categoryCapacity.infants.max &&
                  a + t + c + i <= category.categoryCapacity.total.max;
                  i++
                ) {
                  const overrideKey = `${a}a${c}c${i}i${t}t`;
                  overrides.push({
                    overrideKey,
                    guestCounts: {
                      adults: a,
                      teenagers: t,
                      children: c,
                      infants: i,
                      total: a + t + c + i,
                    },
                  });
                  if (
                    entry?.price !== null &&
                    entry?.overrides?.length &&
                    !entry.overrides.find((o) => o.overrideKey === overrideKey)
                  ) {
                    entry.overrides.push({
                      price: entry.price,
                      overrideKey,
                    });
                  }
                }
              }
            }
          }

          overrides.sort((a, b) => a.guestCounts.total - b.guestCounts.total);

          setPriceRule({ ...priceRule });

          return { ...category, overrides };
        }) ?? []
    );
  }, [priceRules]);

  const handleOverridePriceInputChange = (
    categoryId: string,
    overrideKey: string,
    input: string,
  ) => {
    let price: number | null = Number(input);

    if (!input.trim()) {
      price = null;
    } else if (isNaN(price) || price < 0) {
      setPriceRule({ ...priceRule });
      return;
    }
    const entry = priceRule.entries.find(
      (entry) => entry.categoryId === categoryId,
    );
    if (entry) {
      const override = entry.overrides?.find(
        (o) => o.overrideKey === overrideKey,
      );
      if (override) {
        if (price !== null) {
          override.price = price;
        } else {
          entry.overrides =
            entry.overrides?.filter((o) => o.overrideKey !== overrideKey) ?? [];
        }
      } else {
        if (entry.overrides) {
          if (price !== null) {
            entry.overrides.push({ overrideKey, price });
          }
        } else {
          entry.overrides = price !== null ? [{ overrideKey, price }] : [];
        }
      }
      if (entry.overrides?.length) {
        entry.price = Math.min(...entry.overrides.map((o) => o.price));
      } else {
        entry.price = null;
      }
    } else {
      priceRule.entries = [
        ...priceRule.entries,
        {
          categoryId,
          price: price,
          overrides: price !== null ? [{ overrideKey, price }] : [],
        },
      ];
    }
    setPriceRule({
      ...priceRule,
    });
  };

  const handleCategoryPriceInputChange = (
    categoryId: string,
    input: string,
  ) => {
    let price: number | null = Number(input);

    if (!input.trim()) {
      price = null;
    } else if (isNaN(price) || price < 0) {
      setPriceRule({ ...priceRule });
      return;
    }
    const entry = priceRule.entries.find(
      (entry) => entry.categoryId === categoryId,
    );
    if (entry) {
      entry.overrides = [];
      if (price === null) {
        priceRule.entries = priceRule.entries.filter(
          (entry) => entry.categoryId !== categoryId,
        );
      } else {
        entry.price = price;
      }
    } else {
      if (price === null) {
        priceRule.entries = priceRule.entries.filter(
          (entry) => entry.categoryId !== categoryId,
        );
      } else {
        priceRule.entries = [
          ...priceRule.entries,
          { categoryId, price, overrides: [] },
        ];
      }
    }
    setPriceRule({ ...priceRule });
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="left"
        className="flex w-[600px] flex-col sm:w-[800px]"
      >
        <SheetHeader>
          <SheetTitle className=" flex items-center justify-between text-primary-text">
            {ruleToEdit ? t("edit-bar") : t("new-bar")}
          </SheetTitle>
        </SheetHeader>

        <div className=" flex flex-col bg-secondary-card-backplate">
          <div className="p-4">
            <Input
              placeholder={`${t("name")}*`}
              value={priceRule.name}
              onChange={(e) =>
                setPriceRule((priceRule) => ({
                  ...priceRule,
                  name: e.target.value,
                }))
              }
            />
            <p className=" mt-4 text-sm font-normal text-secondary-text">
              {t("price-rule-edit-description")}
            </p>
          </div>

          <div className=" flex-grow">
            <div className=" flex h-[60px] w-full flex-nowrap border-b-[0.5px] border-t-[0.5px] border-highlighted-backplate">
              <div
                className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-secondary-text"
                onClick={() => {
                  if (expandedIds.length === rows.length) {
                    setExpandedIds([]);
                  } else {
                    setExpandedIds(rows.map((r) => r.id) ?? []);
                  }
                }}
              >
                {t("category")}
                {expandedIds.length === rows.length ? (
                  <ChevronsDownUp size={16} />
                ) : (
                  <ChevronsUpDown size={16} />
                )}
              </div>
              <div className=" flex h-full min-w-[170px] max-w-[170px] items-center border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-secondary-text">
                {t("minimum-rate")}
              </div>
              <div className=" flex h-full flex-grow items-center border-highlighted-backplate px-2 text-sm font-normal text-secondary-text">
                {t("bar-price")}
              </div>
            </div>
            <div className="max-h-[calc(100vh-350px)] overflow-y-auto">
              {rows.map((row, rowIndex) => {
                const entry = priceRule.entries.find(
                  (e) => e.categoryId === row.id,
                );
                const categoryPrice = entry?.price;
                const categoryOverrides = entry?.overrides;
                return (
                  <React.Fragment key={row.id}>
                    <div className=" flex h-[60px] w-full flex-nowrap border-b-[0.5px] border-highlighted-backplate">
                      <div
                        className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text"
                        onClick={() => {
                          if (expandedIds.includes(row.id)) {
                            setExpandedIds((expandedIds) =>
                              expandedIds.filter((id) => id !== row.id),
                            );
                          } else {
                            setExpandedIds((expandedIds) => [
                              ...expandedIds,
                              row.id,
                            ]);
                          }
                        }}
                      >
                        {row.short}
                        {expandedIds.includes(row.id) ? (
                          <ChevronUp size={18} />
                        ) : (
                          <ChevronDown size={18} />
                        )}
                      </div>
                      <div className=" flex h-full min-w-[170px] max-w-[170px] items-center justify-end space-x-2 border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text">
                        <p>
                          {new Intl.NumberFormat(
                            i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                            {
                              maximumFractionDigits: 2,
                            },
                          ).format(row.minimumRate)}{" "}
                        </p>
                        <span className="text-xs text-secondary-text">SEK</span>
                      </div>
                      <div className=" flex h-full flex-grow items-center justify-end space-x-2 border-highlighted-backplate px-2 text-sm font-normal text-primary-text">
                        <Input
                          className={cn({
                            " border-2 border-status-error":
                              (categoryPrice ?? row.minimumRate) <
                              row.minimumRate,
                            " border-2 border-primary-color":
                              highlightedCategoryIds.includes(row.id),
                            "text-secondary-text": categoryOverrides?.length,
                          })}
                          placeholder={String(categoryPrice ?? "")}
                          value={String(categoryPrice ?? "")}
                          onChange={(e) =>
                            handleCategoryPriceInputChange(
                              row.id,
                              e.target.value,
                            )
                          }
                        />
                        <div className=" flex h-full w-[30px] items-center justify-center">
                          {categoryOverrides?.length ? (
                            <div className=" h-2 w-2 rounded-full bg-primary-color" />
                          ) : null}
                        </div>
                        <div className=" flex h-full w-[70px] items-center justify-center">
                          <Button
                            size="sm"
                            variant="outline"
                            disabled={!!categoryOverrides?.length}
                            onMouseEnter={() => {
                              if (
                                categoryPrice !== null &&
                                categoryPrice !== undefined
                              ) {
                                setHighlightedCategoryIds(
                                  rows
                                    .slice(rowIndex + 1)
                                    .filter(
                                      (r) => categoryPrice >= r.minimumRate,
                                    )
                                    .map((r) => r.id),
                                );
                              }
                            }}
                            onMouseLeave={() => {
                              setHighlightedCategoryIds([]);
                            }}
                            onClick={() => {
                              if (
                                categoryPrice !== null &&
                                categoryPrice !== undefined
                              ) {
                                rows
                                  .slice(rowIndex + 1)
                                  .filter((r) => categoryPrice >= r.minimumRate)
                                  .forEach((row) => {
                                    const entry = priceRule.entries.find(
                                      (e) => e.categoryId === row.id,
                                    );
                                    if (entry) {
                                      entry.price = categoryPrice;
                                      entry.overrides = [];
                                    } else {
                                      priceRule.entries.push({
                                        categoryId: row.id,
                                        price: categoryPrice,
                                        overrides: [],
                                      });
                                    }
                                  });
                                setPriceRule({ ...priceRule });
                              }
                            }}
                          >
                            <ArrowDown size={18} />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Collapsible.Root open={expandedIds.includes(row.id)}>
                      <Collapsible.Content>
                        {row.overrides.map((override, overrideIndex) => {
                          const categoryOverride = categoryOverrides?.find(
                            (o) => o.overrideKey === override.overrideKey,
                          );
                          const categoryOverridePrice = categoryOverride?.price;
                          return (
                            <div
                              key={override.overrideKey}
                              className=" flex h-[60px] w-full flex-nowrap border-b-[0.5px] border-highlighted-backplate"
                            >
                              <div className=" flex h-full min-w-[170px] max-w-[170px] items-center space-x-2 border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text">
                                {`${override.guestCounts.adults ? `${override.guestCounts.adults}(${t("adults-short")}) ` : ""}${override.guestCounts.teenagers ? `${override.guestCounts.teenagers}(${t("teenagers-short")}) ` : ""}${override.guestCounts.children ? `${override.guestCounts.children}(${t("children-short")}) ` : ""}${override.guestCounts.infants ? `${override.guestCounts.infants}(${t("infants-short")})` : ""}`}
                              </div>
                              <div className=" flex h-full min-w-[170px] max-w-[170px] items-center space-x-2 border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text" />
                              <div className=" flex h-full flex-grow items-center justify-end space-x-2 border-r-[0.5px] border-highlighted-backplate px-2 text-sm font-normal text-primary-text">
                                <Input
                                  className={cn({
                                    " border-2 border-status-error":
                                      /*(categoryOverrides?.length &&
                                        !categoryOverridePrice) ||*/
                                      (categoryOverridePrice ??
                                        row.minimumRate) < row.minimumRate,
                                    " border-2 border-primary-color":
                                      highlightedOverrideKeys?.categoryId ===
                                        row.id &&
                                      highlightedOverrideKeys?.overrideKeys.includes(
                                        override.overrideKey,
                                      ),
                                  })}
                                  placeholder={String(
                                    !categoryOverrides?.length
                                      ? (categoryPrice ?? "")
                                      : "",
                                  )}
                                  value={String(categoryOverridePrice ?? "")}
                                  onChange={(e) =>
                                    handleOverridePriceInputChange(
                                      row.id,
                                      override.overrideKey,
                                      e.target.value,
                                    )
                                  }
                                />
                                <div className=" flex h-full w-[30px] items-center justify-center" />
                                <div className=" flex h-full w-[70px] items-center justify-center">
                                  <Button
                                    size="sm"
                                    variant="outline"
                                    disabled={
                                      categoryOverridePrice === undefined
                                    }
                                    onMouseEnter={() => {
                                      setHighlightedOverrideKeys({
                                        categoryId: row.id,
                                        overrideKeys: row.overrides
                                          .slice(overrideIndex + 1)
                                          .map((o) => o.overrideKey),
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      setHighlightedOverrideKeys(undefined);
                                    }}
                                    onClick={() => {
                                      row.overrides
                                        .slice(overrideIndex + 1)
                                        .forEach((override) => {
                                          if (categoryOverridePrice) {
                                            if (categoryOverrides) {
                                              const o = categoryOverrides.find(
                                                (o) =>
                                                  o.overrideKey ===
                                                  override.overrideKey,
                                              );
                                              if (o) {
                                                o.price = categoryOverridePrice;
                                              } else {
                                                categoryOverrides.push({
                                                  overrideKey:
                                                    override.overrideKey,
                                                  price: categoryOverridePrice,
                                                });
                                              }
                                            } else {
                                              if (entry) {
                                                entry.overrides = [
                                                  {
                                                    overrideKey:
                                                      override.overrideKey,
                                                    price:
                                                      categoryOverridePrice,
                                                  },
                                                ];
                                              } else {
                                                priceRule.entries.push({
                                                  categoryId: row.id,
                                                  price: categoryOverridePrice,
                                                  overrides: [
                                                    {
                                                      overrideKey:
                                                        override.overrideKey,
                                                      price:
                                                        categoryOverridePrice,
                                                    },
                                                  ],
                                                });
                                              }
                                            }
                                          }
                                        });
                                      if (entry) {
                                        if (entry.overrides?.length) {
                                          entry.price = Math.min(
                                            ...entry.overrides.map(
                                              (o) => o.price,
                                            ),
                                          );
                                        }
                                      }
                                      setPriceRule({ ...priceRule });
                                    }}
                                  >
                                    <ArrowDown size={18} />
                                  </Button>
                                </div>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </Collapsible.Content>
                    </Collapsible.Root>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>

        <div className=" flex flex-grow items-end justify-end space-x-2 ">
          <Button
            onClick={handleCreatePriceRule}
            loading={createLoading}
            disabled={
              !(
                priceRule.entries.length === rows.length &&
                priceRule.name &&
                priceRule.entries.every((e) => {
                  const row = rows.find((row) => row.id === e.categoryId);
                  const minimumRate = row?.minimumRate ?? 0;
                  const overrideOptions = row?.overrides;
                  return (
                    e.price &&
                    e.price >= minimumRate &&
                    (!e.overrides?.length ||
                      e.overrides?.length === overrideOptions?.length) &&
                    e.overrides?.every((o) => o.price >= minimumRate)
                  );
                })
              )
            }
          >
            {ruleToEdit ? t("save-bar") : t("create-bar")}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PriceRuleDrawer;
