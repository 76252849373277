import { useGetAssetGroups } from "@api/groups";
import { TabNavbar } from "@components/tab-navbar";
import { Button } from "@primitives/button";
import { Checkbox } from "@primitives/checkbox";
import DatePicker from "@primitives/date-picker";
import { Popover } from "@primitives/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import {
  AlignVerticalSpaceAround,
  ArrowRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  Ellipsis,
  Filter,
  Info,
  Link,
  Minus,
  MoreHorizontal,
  X,
} from "lucide-react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SearchBar } from "@primitives/search-bar";
import { cn } from "@shared/utils/css";
import { Collapsible } from "@primitives/collapsible";
import { enGB, sv } from "date-fns/locale";
import {
  differenceInCalendarDays,
  format,
  isBefore,
  isSameDay,
  isWithinInterval,
} from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@primitives/simpleTooltip";
import {
  BookableStatus,
  PaymentStatus,
} from "../../../../../api-contracts/category-grid";
import { Loading } from "@primitives/loading";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@primitives/sheet";
import { TimePicker } from "@primitives/time-picker";
import { Label } from "@primitives/label";
import { Textarea } from "@primitives/textarea";
import { useBlockBookable, useGetAllocableBookables } from "@api/bookables";
import { queryClient } from "query-client";
import { useToast } from "@hooks/use-toast";
import { ROUTES } from "@shared/types/navigation";
import { useProfileContext } from "@context/profile-context";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import {
  bedOptionLabel,
  combine,
  getAgeType,
  getBedPreference,
  getBedPreferenceFromType,
  getDaysBetween,
  getPossibleBedPreferences,
} from "@shared/utils/helpers";
import { useGetCategories } from "@api/categories";
import { Switch } from "@primitives/switch";
import { useGetLocations } from "@api/locations";
import { useGetAssetGrid } from "@api/asset-grid";
import {
  AgeType,
  BedPreference,
  GetReservation,
} from "../../../../../api-contracts/reservations";
import {
  useAbortOngoingReservation,
  useCreateReservation,
  usePatchReservation,
} from "@api/reservations";
import {
  CategoryCapacity,
  CategoryType,
} from "../../../../../api-contracts/categories";
import {
  GetAssetGridAsset,
  GetAssetGridDateEntry,
} from "../../../../../api-contracts/asset-grid";
import type { Range } from "@tanstack/react-virtual";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { ConfirmDialog } from "@components/confirm-dialog";
import { useGetMultiplePricing } from "@api/pricing";
import { TimeSlot } from "../../../../../api-contracts/reservations/timeslots";
import { ClusterTabNavBar } from "@components/cluster-tab-nav-bar.tsx";
import { BlockDrawer } from "../category-grid/components/block-drawer";

type DurationOption = {
  label: string;
  size: number;
  sizeType: "day" | "month";
};

const durationOptions: DurationOption[] = [
  {
    label: "4-weeks",
    size: 28,
    sizeType: "day",
  },
  {
    label: "3-weeks",
    size: 21,
    sizeType: "day",
  },
  {
    label: "2-weeks",
    size: 14,
    sizeType: "day",
  },
  {
    label: "1-week",
    size: 7,
    sizeType: "day",
  },
  {
    label: "whole-month",
    size: 1,
    sizeType: "month",
  },
];

type AssetGroup = {
  id: string;
  name: string;
  assets: GetAssetGridAsset[];
};

export const bookableStatusToColor = (status?: BookableStatus) => {
  switch (status) {
    case "ongoing":
      return "booking-ongoing";
    case "preliminary":
      return "booking-tentative";
    case "unconfirmed":
      return "booking-unconfirmed";
    case "confirmed":
      return "booking-confirmed";
    case "guaranteed":
      return "booking-guaranteed";
    case "checked-in":
      return "booking-checked-in";
    case "checked-out":
      return "booking-checked-out";
    case "no-show":
      return "status-error";
    case "booked-as-other":
      return "booking-other-category";
    case "out-of-order":
      return "out-of-order";
    case "out-of-service":
      return "out-of-service";
    default:
      return "status-disabled";
  }
};

const paymentStatusToColor = (status?: PaymentStatus) => {
  switch (status) {
    case "partly-paid":
      return "status-warning";
    case "paid":
      return "status-success";
    case "unpaid":
      return "status-error";
    default:
      return undefined;
  }
};

interface Props {
  tab: CategoryType[];
}

const AssetGridPage = ({ tab }: Props) => {
  const [t, i18n] = useTranslation();
  const { toast } = useToast();
  const [durationIndex, setDurationIndex] = useState<string>("0");
  const duration = durationOptions[Number(durationIndex)];
  const [from, setFrom] = useState<Date>(new Date());
  const [tableSize, setTableSize] = useState<"small" | "medium" | "large">(
    (localStorage.getItem("asset-grid-spacing") as
      | "small"
      | "medium"
      | "large") || "medium",
  );
  const [newReservationOpen, setNewReservationOpen] = useState<boolean>(false);
  const [informationOpen, setInformationOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [groupsFilterOpen, setGroupsFilterOpen] = useState<boolean>(false);
  const [categoriesFilterOpen, setCategoriesFilterOpen] =
    useState<boolean>(false);
  const [locationsFilterOpen, setLocationsFilterOpen] =
    useState<boolean>(false);
  const [numGuestsDetailsOpen, setNumGuestsDetailsOpen] =
    useState<boolean>(false);
  const [groupBy, setGroupBy] = useState<
    { id: string; name: string; type: "group" }[]
  >([]);
  const [categories, setCategories] = useState<
    { id: string; name: string; type: "category" }[]
  >([]);
  const [locations, setLocations] = useState<
    { id: string; name: string; type: "location" }[]
  >([]);
  const [searchGroups, setSearchGroups] = useState<string>("");
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [searchLocations, setSearchLocations] = useState<string>("");
  const [focusedAsset, setFocusedAsset] = useState<{
    asset: GetAssetGridAsset;
    entries: GetAssetGridDateEntry[];
    startDate: Date;
    endDate: Date;
    category: {
      name: string;
      shortName: string;
      id: string;
      capacity: CategoryCapacity;
      startTimes: string[];
      bookableId: string;
    };
  }>();
  const [selectedAssets, setSelectedAssets] = useState<
    {
      asset: GetAssetGridAsset;
      slotsOverride?: TimeSlot[];
      entries: GetAssetGridDateEntry[];
      startDate: Date;
      endDate: Date;
      category: {
        name: string;
        shortName: string;
        id: string;
        capacity: CategoryCapacity;
        startTimes: string[];
        bookableId: string;
      };
      adults?: number;
      children: {
        age: number;
        bedPreference: BedPreference;
      }[];
      bookingID?: string;
    }[]
  >([]);
  const [reservation, setReservation] = useState<GetReservation>();
  const [assetToBlock, setAssetToBlock] = useState<GetAssetGridAsset>();
  const today = new Date(new Date().toDateString());
  const [showLocation, setShowLocation] = useState<boolean>(
    localStorage.getItem("asset-grid-show-location") === "true",
  );
  const [abortReservationOpen, setAbortReservationOpen] =
    useState<boolean>(false);
  const [updateReservationLoading, setUpdateReservationLoading] =
    useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { module } = useProfileContext();

  const to = useMemo(() => {
    const date = new Date(from);
    if (duration.sizeType === "day") {
      date.setDate(date.getDate() + duration.size - 1);
    } else {
      date.setMonth(date.getMonth() + duration.size);
      date.setDate(0);
    }
    return date;
  }, [from, duration]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: useGetAssetGrid.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetMultiplePricing.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetAllocableBookables.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetAssetGroups.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetCategories.getKey(),
    });
    queryClient.invalidateQueries({
      queryKey: useGetLocations.getKey(),
    });
  }, []);

  const {
    data: pricing,
    isLoading: pricingIsLoading,
    isRefetching: pricingIsRefetching,
  } = useGetMultiplePricing({
    variables: {
      checks: selectedAssets.map((a) => {
        const dates = getDaysBetween(a.startDate, a.endDate);
        dates.pop();

        return {
          slots: dates.map((d) => ({
            startDate: format(d, "yyyy-MM-dd"),
            startTime: a.category.startTimes[0],
          })),
          adults: a.adults,
          teenagers: a.children
            ? a.children.filter((c) => c.age !== undefined && c.age >= 13)
                .length || undefined
            : undefined,
          children: a.children
            ? a.children.filter(
                (c) => c.age === undefined || (c.age > 2 && c.age < 13),
              ).length || undefined
            : undefined,
          infants: a.children
            ? a.children.filter((c) => c.age !== undefined && c.age <= 2)
                .length || undefined
            : undefined,
          categoryId: a.category.id,
        };
      }),
    },
    enabled: selectedAssets.length > 0,
  });

  const {
    data: assetGrid,
    isLoading: assetGridLoading,
    isRefetching: assetGridRefetching,
  } = useGetAssetGrid({
    variables: {
      startDate: format(from, "yyyy-MM-dd"),
      endDate: format(to, "yyyy-MM-dd"),
      types: tab,
    },
  });

  const filteredAssetGrid = useMemo(() => {
    if (!assetGrid) {
      return undefined;
    }
    return {
      meta: assetGrid.meta,
      assets: assetGrid.assets.filter((a) => {
        return (
          (a.locations.find((l) => locations.find((l2) => l.id === l2.id)) ||
            (a.locations.length === 0 &&
              locations.find((l) => l.id === "missing"))) &&
          a.categories.find((c) => categories.find((c2) => c.id === c2.id))
        );
      }),
    };
  }, [assetGrid, locations, categories]);

  const groupedData: AssetGroup[] = useMemo(() => {
    let groups: AssetGroup[] = [];
    if (groupBy.length) {
      const groupMap = combine(
        [...groupBy],
        Math.max(groupBy.length - 1, 1),
      ).sort((g1, g2) => g2.length - g1.length);
      groups = groupMap.map((grouping, i) => ({
        name: grouping.map((g) => g.name).join(", "),
        id: String(i),
        assets:
          filteredAssetGrid?.assets?.filter((a) =>
            grouping.every(
              (g) =>
                g.type === "group" && !!a.groups.find((g1) => g1.id == g.id),
            ),
          ) || ([] as any),
      }));
      groups = groups.filter((g, i) => g.assets.length > 0 || i === 0);
    }
    return groups;
  }, [filteredAssetGrid, groupBy]);

  const blockBookable = useBlockBookable();
  const handleCreateReservation = useCreateReservation();
  const handleUpdateReservation = usePatchReservation();
  const handleAbortReservation = useAbortOngoingReservation();

  useEffect(() => {
    localStorage.setItem("asset-grid-spacing", tableSize);
  }, [tableSize]);

  useEffect(() => {
    localStorage.setItem("asset-grid-show-location", String(showLocation));
  }, [showLocation]);

  useEffect(() => {
    if (duration.sizeType === "month") {
      const date = new Date(from);
      date.setDate(1);
      setFrom(date);
    }
  }, [duration]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyDown);

    return () => {
      window.removeEventListener("keyup", handleKeyDown);
    };
  }, [selectedAssets]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseDown);

    return () => {
      window.removeEventListener("mouseup", handleMouseDown);
    };
  }, [selectedAssets]);

  const {
    data: assetGroups,
    error: assetGroupsError,
    isLoading: assetGroupsIsLoading,
  } = useGetAssetGroups({});

  const filteredGroups = useMemo(() => {
    if (searchGroups) {
      return assetGroups?.filter((g) =>
        g.name.toLocaleLowerCase().includes(searchGroups.toLocaleLowerCase()),
      );
    } else {
      return assetGroups;
    }
  }, [assetGroups, searchGroups]);

  const {
    data: cat,
    error: catError,
    isLoading: catIsLoading,
  } = useGetCategories({
    variables: {
      categoryTypes: tab,
    },
  });

  useEffect(() => {
    setCategories(
      cat?.map((c) => ({ id: c.id, name: c.name, type: "category" })) || [],
    );
  }, [cat]);

  const filteredCategories = useMemo(() => {
    if (searchCategories) {
      return cat?.filter((g) =>
        g.name
          .toLocaleLowerCase()
          .includes(searchCategories.toLocaleLowerCase()),
      );
    } else {
      return cat;
    }
  }, [cat, searchCategories]);

  const {
    data: loc,
    error: locError,
    isLoading: locIsLoading,
  } = useGetLocations({});

  useEffect(() => {
    setLocations([
      ...(loc?.map((c) => ({
        id: c.id,
        name: c.name,
        type: "location" as "location",
      })) ?? []),
      { id: "missing", name: t("missing"), type: "location" },
    ]);
  }, [loc]);

  const filteredLocations = useMemo(() => {
    const locationsAddMissing = [
      ...(loc ?? []),
      { id: "missing", name: t("missing"), siteName: "missing", partOf: null },
    ];
    if (searchLocations) {
      return locationsAddMissing?.filter((l) =>
        l.name
          .toLocaleLowerCase()
          .includes(searchLocations.toLocaleLowerCase()),
      );
    } else {
      return locationsAddMissing;
    }
  }, [loc, searchLocations]);

  const handleMouseDown = (e: MouseEvent) => {
    if (!(e.ctrlKey || e.metaKey)) {
      if (selectedAssets.length) {
        setNewReservationOpen(true);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      if (selectedAssets.length) {
        setNewReservationOpen(true);
      }
    }
  };

  const goToCurrent = () => {
    if (duration.sizeType === "day") {
      setFrom(new Date());
    } else {
      const date = new Date();
      date.setDate(1);
      setFrom(date);
    }
  };

  const createReservation = async () => {
    if (!reservation) {
      setUpdateReservationLoading(true);
      try {
        const response = await handleCreateReservation.mutateAsync({
          bookings: selectedAssets.map((b) => {
            const dates = getDaysBetween(b.startDate, b.endDate);
            dates.pop();
            const children = b.children
              ? b.children.filter(
                  (c) => c.age === undefined || getAgeType(c.age) === "child",
                )
              : [];
            const numChildren = children.length;
            const infants = b.children
              ? b.children.filter(
                  (c) => c.age !== undefined && getAgeType(c.age) === "infant",
                )
              : [];
            const numInfants = infants.length;
            const teenagers = b.children
              ? b.children.filter(
                  (c) =>
                    c.age !== undefined && getAgeType(c.age) === "teenager",
                )
              : [];
            const numTeenagers = teenagers.length;
            return {
              guests: [
                ...Array.from({ length: b.adults || 0 }, (_, i) => ({
                  ageType: "adult" as AgeType,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                })),
                ...children.map(({ bedPreference, age }) => ({
                  ageType: "child" as AgeType,
                  age: age,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                  bedPreference,
                })),
                ...teenagers.map(({ bedPreference, age }) => ({
                  ageType: "teenager" as AgeType,
                  age: age,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                  bedPreference,
                })),
                ...infants.map(({ bedPreference, age }) => ({
                  ageType: "infant" as AgeType,
                  age: age,
                  name: null,
                  surname: null,
                  passportNumber: null,
                  address: null,
                  address2: null,
                  city: null,
                  state: null,
                  zip: null,
                  countryCode: null,
                  phone: null,
                  phone2: null,
                  email: null,
                  bedPreference,
                })),
              ],
              slots: dates.map((d) => ({
                startDate: format(d, "yyyy-MM-dd"),
                startTime: b.category.startTimes[0],
                priceOverrideAmount: null,
                priceAdjustmentPercent: null,
              })),
              categoryId: String(b.category.id),
              bookableId: b.category.bookableId || null,
              numAdults: b.adults,
              numChildren,
              numInfants,
              numTeenagers,
            };
          }),
        });
        setReservation(response);
        response.bookings.forEach((r, i) => {
          if (selectedAssets[i]) {
            selectedAssets[i].bookingID = r.id;
            selectedAssets[i].slotsOverride = r.slots;
          }
        });
        setSelectedAssets([...selectedAssets]);
        queryClient.invalidateQueries({
          queryKey: useGetAssetGrid.getKey(),
        });
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
      setUpdateReservationLoading(false);
    }
  };

  const updateReservation = async () => {
    if (reservation) {
      setUpdateReservationLoading(true);
      try {
        const response = await handleUpdateReservation.mutateAsync({
          id: reservation.id,
          patch: {
            bookings: selectedAssets.map((b) => {
              const dates = getDaysBetween(b.startDate, b.endDate);
              dates.pop();
              const children = b.children
                ? b.children.filter(
                    (c) => c.age === undefined || getAgeType(c.age) === "child",
                  )
                : [];
              const numChildren = children.length;
              const infants = b.children
                ? b.children.filter(
                    (c) =>
                      c.age !== undefined && getAgeType(c.age) === "infant",
                  )
                : [];
              const numInfants = infants.length;
              const teenagers = b.children
                ? b.children.filter(
                    (c) =>
                      c.age !== undefined && getAgeType(c.age) === "teenager",
                  )
                : [];
              const numTeenagers = teenagers.length;
              return {
                id: b.bookingID,
                guests: [
                  ...Array.from({ length: b.adults || 0 }, (_, i) => ({
                    ageType: "adult" as AgeType,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                    bedPreference: getBedPreferenceFromType("adult"),
                  })),
                  ...children.map(({ bedPreference, age }) => ({
                    ageType: "child" as AgeType,
                    age: age,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                    bedPreference,
                  })),
                  ...teenagers.map(({ bedPreference, age }) => ({
                    ageType: "teenager" as AgeType,
                    age: age,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                    bedPreference,
                  })),
                  ...infants.map(({ bedPreference, age }) => ({
                    ageType: "infant" as AgeType,
                    age: age,
                    name: null,
                    surname: null,
                    passportNumber: null,
                    address: null,
                    address2: null,
                    city: null,
                    state: null,
                    zip: null,
                    countryCode: null,
                    phone: null,
                    phone2: null,
                    email: null,
                    bedPreference,
                  })),
                ],
                slots:
                  b.slotsOverride?.map((s) => {
                    return {
                      id: s.id,
                      startDate: format(s.start, "yyyy-MM-dd"),
                      startTime: format(s.start, "HH:mm:ss"),
                      priceOverrideAmount: s.priceOverrideAmount,
                      priceAdjustmentPercent: s.priceAdjustmentPercent,
                    };
                  }) ??
                  dates.map((d) => ({
                    startDate: format(d, "yyyy-MM-dd"),
                    startTime: b.category.startTimes[0],
                    priceOverrideAmount: null,
                    priceAdjustmentPercent: null,
                  })),
                categoryId: String(b.category.id),
                bookableId: b.category.bookableId || null,
                numAdults: b.adults,
                numChildren,
                numInfants,
                numTeenagers,
              };
            }),
          },
        });
        setReservation(response);
        queryClient.invalidateQueries({
          queryKey: useGetAssetGrid.getKey(),
        });
        navigate(`/${module}/reservations/${response.id}`);
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
      setUpdateReservationLoading(false);
    }
  };

  useEffect(() => {
    if (newReservationOpen && selectedAssets.length) {
      createReservation();
    }

    if (!newReservationOpen) {
      setReservation(undefined);
    }
  }, [newReservationOpen, selectedAssets]);

  const totalPrice = useMemo(() => {
    let price = 0;
    pricing?.prices.forEach((p) => {
      p.slots.forEach((slot) => {
        price += slot.price;
      });
    });
    return price;
  }, [pricing]);

  const abortReservation = async () => {
    if (reservation) {
      try {
        await handleAbortReservation.mutateAsync({
          id: reservation.id,
        });
        queryClient.invalidateQueries({
          queryKey: useGetAssetGrid.getKey(),
        });
        setNewReservationOpen(false);
        setSelectedAssets([]);
      } catch (err) {
        toast({
          variant: "destructive",
          title:
            t("request-failed-with") +
            ": " +
            t(decodeURIComponent((err as any)?.message || t("no-message"))),
          className: "text-status-error",
        });
      }
    }
  };

  const onContinue = async () => {
    if (reservation) {
      await updateReservation();
    }
  };

  const AssetRow = ({ a }: { a: GetAssetGridAsset }) => {
    const [hoveredBookingId, setHoveredBookingId] = useState<string>();

    return (
      <div
        key={a.id}
        className={cn("box-border flex h-[40px] w-full flex-nowrap", {
          "mt-2 border-t border-highlighted-backplate": tableSize === "large",
          "h-[25px]": tableSize === "small",
        })}
      >
        {showLocation && (
          <div
            className={cn(
              " flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-b border-r  border-highlighted-backplate bg-primary-card-backplate px-2 text-sm font-normal",
              {
                "text-xs": tableSize === "small",
              },
            )}
          >
            {a.locations.map((l) => l.name).join(", ")}
          </div>
        )}
        <div
          className={cn(
            " flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-b border-r  border-highlighted-backplate bg-primary-card-backplate px-2 text-sm font-normal",
            {
              "text-xs": tableSize === "small",
            },
          )}
        >
          <div>
            <p>
              {a.name}{" "}
              {a.categories.length
                ? `(${a.categories.map((a) => a.shortName).join(", ")})`
                : ""}
            </p>
          </div>
          <div className=" flex flex-nowrap space-x-1">
            {a.connections?.length ? (
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link size={14} />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent
                      align="start"
                      side="bottom"
                      className="min-w-[160px] p-0"
                    >
                      <div>
                        <p className=" border-b border-highlighted-backplate p-2 text-xs font-bold text-primary-text">
                          {t("connecting-rooms")}
                        </p>
                        {a.connections.map((c) => (
                          <div
                            key={c.id}
                            className=" flex flex-nowrap items-center space-x-2 p-2 text-sm font-medium text-primary-text"
                          >
                            <Link size={14} />
                            <p>{c.name}</p>
                          </div>
                        ))}
                      </div>
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </TooltipProvider>
            ) : undefined}
            <DropdownMenu.Root>
              <DropdownMenuTrigger>
                {" "}
                <MoreHorizontal size={12} />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuItem onClick={() => setAssetToBlock(a)}>
                  {t("block-room")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu.Root>
          </div>
        </div>

        {a.entries.map((entry, index) => {
          const date = new Date(entry.date);
          return (
            <div
              key={entry.date}
              className={cn(
                "relative flex min-w-[50px] flex-1 flex-col items-center justify-center border-b border-highlighted-backplate bg-primary-card-backplate",
                {
                  "bg-amber-500-50": isSameDay(today, date),
                  "border-r": index !== a.entries.length - 1,
                  "border-l":
                    selectedAssets &&
                    selectedAssets.find(
                      (b1) =>
                        b1.asset.id === a.id &&
                        !!b1.entries.find(
                          (e) =>
                            e.date === entry.date &&
                            isSameDay(b1.startDate, entry.date),
                        ),
                    ),
                  "border-t border-status-success bg-status-success-100":
                    selectedAssets &&
                    selectedAssets.find(
                      (b1) =>
                        b1.asset.id === a.id &&
                        !!b1.entries.find((e) => e.date === entry.date),
                    ),
                },
              )}
            >
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    {entry.status ? (
                      <div
                        className={cn("h-full w-full", {
                          "border-b border-t border-primary-text py-[2px]":
                            entry.booking?.id &&
                            entry.booking?.id === hoveredBookingId,
                          "rounded-r-sm border-r":
                            entry.booking?.id === entry.booking?.id &&
                            entry.booking?.id === hoveredBookingId &&
                            a.entries[index + 1]?.booking?.id !==
                              hoveredBookingId,
                          "rounded-l-sm border-l":
                            entry.booking?.id === entry.booking?.id &&
                            entry.booking?.id === hoveredBookingId &&
                            a.entries[index - 1]?.booking?.id !==
                              hoveredBookingId,
                        })}
                      >
                        <div
                          onMouseEnter={() => {
                            setHoveredBookingId(entry.booking?.id);
                          }}
                          onMouseLeave={() => {
                            setHoveredBookingId(undefined);
                          }}
                          onClick={() =>
                            entry.booking?.reservationId &&
                            navigate(
                              `/${module}/${ROUTES.RESERVATIONS}/${entry.booking.reservationId}`,
                            )
                          }
                          className={cn(
                            "absolute right-[-1px] top-[3px] z-10 mr-[1px] h-[33px] w-[calc(100%+1px)] opacity-80",
                            `bg-${bookableStatusToColor(entry.status)}`,
                            {
                              "cursor-pointer": !!entry.booking?.reservationId,
                              " ml-[2px] mr-[0px] rounded-l":
                                a.entries[index - 1]?.status !== entry.status ||
                                a.entries[index - 1]?.booking?.id !==
                                  entry.booking?.id,
                              " mr-[4px] rounded-r":
                                a.entries[index + 1]?.status !== entry.status ||
                                a.entries[index + 1]?.booking?.id !==
                                  entry.booking?.id,
                              " w-[calc(100%-2px)]":
                                a.entries[index - 1]?.status !== entry.status ||
                                a.entries[index - 1]?.booking?.id !==
                                  entry.booking?.id ||
                                a.entries[index + 1]?.status !== entry.status ||
                                a.entries[index + 1]?.booking?.id !==
                                  entry.booking?.id,
                              " mr-[4px] w-[calc(100%-6px)]":
                                (a.entries[index - 1]?.status !==
                                  entry.status ||
                                  a.entries[index - 1]?.booking?.id !==
                                    entry.booking?.id) &&
                                (a.entries[index + 1]?.status !==
                                  entry.status ||
                                  a.entries[index + 1]?.booking?.id !==
                                    entry.booking?.id),
                              "opacity-100":
                                entry.booking?.id &&
                                entry.booking?.id === hoveredBookingId,
                              "h-[18px]": tableSize === "small",
                              "z-20":
                                (entry.status === "out-of-order" ||
                                  entry.status === "out-of-service") &&
                                a.entries[index - 1]?.status !== entry.status &&
                                a.entries[index + 1]?.status === entry.status,
                            },
                          )}
                        >
                          {entry.status === "out-of-order" &&
                            a.entries[index - 1]?.status !== entry.status &&
                            a.entries[index + 1]?.status === entry.status && (
                              <p
                                className={cn(
                                  " absolute m-2 text-nowrap text-xs font-medium text-teritary-text",
                                  {
                                    "my-[2px]": tableSize === "small",
                                  },
                                )}
                              >
                                {t("out-of-order")}
                              </p>
                            )}
                          {entry.status === "out-of-service" &&
                            a.entries[index - 1]?.status !== entry.status &&
                            a.entries[index + 1]?.status === entry.status && (
                              <p
                                className={cn(
                                  " absolute m-2 text-nowrap text-xs font-medium text-teritary-text",
                                  {
                                    "my-[2px]": tableSize === "small",
                                  },
                                )}
                              >
                                {t("out-of-service")}
                              </p>
                            )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={cn(" h-full w-full", {
                          "cursor-pointer": isBefore(today, date),
                          "bg-status-success-100":
                            selectedAssets &&
                            selectedAssets.find(
                              (b) =>
                                b.asset.id === a.id &&
                                !!b.entries.find((e) => e.date === entry.date),
                            ),
                        })}
                        onMouseDown={(e) => {
                          if (isBefore(date, today)) {
                            return;
                          }
                          const startDate = new Date(entry.date);
                          const endDate = new Date(startDate);
                          endDate.setDate(endDate.getDate() + 1);
                          const asset = {
                            asset: a,
                            entries: [entry],
                            startDate,
                            endDate,
                            category: a.categories[0],
                          };
                          setFocusedAsset(asset);
                          if (e.ctrlKey || e.metaKey) {
                            setSelectedAssets([
                              ...selectedAssets,
                              {
                                ...asset,
                                adults:
                                  a.categories[0]?.capacity.adults.min || 1,
                                children: [],
                              },
                            ]);
                          } else {
                            setSelectedAssets([
                              {
                                ...asset,
                                adults:
                                  a.categories[0]?.capacity.adults.min || 1,
                                children: [],
                              },
                            ]);
                          }
                          e.preventDefault();
                        }}
                        onMouseUp={(e) => {
                          if (a.id === focusedAsset?.asset.id) {
                            const endDate = new Date(entry.date);
                            if (isBefore(endDate, focusedAsset.startDate)) {
                              return;
                            }
                            endDate.setDate(endDate.getDate() + 1);
                            const newEntries =
                              focusedAsset.asset.entries.filter((e) =>
                                isWithinInterval(new Date(e.date), {
                                  start: focusedAsset.startDate,
                                  end: new Date(entry.date),
                                }),
                              );
                            if (newEntries.find((e) => !!e.status)) {
                              return;
                            }
                            focusedAsset.endDate = endDate;
                            focusedAsset.entries = newEntries;
                            setSelectedAssets([
                              ...selectedAssets.map((b) =>
                                b.asset.id === focusedAsset.asset.id
                                  ? { ...b, ...focusedAsset }
                                  : b,
                              ),
                            ]);
                            setFocusedAsset(undefined);
                            if (!(e.ctrlKey || e.metaKey)) {
                              setNewReservationOpen(true);
                            }
                          }
                        }}
                        onMouseOver={(e) => {
                          if (a.id === focusedAsset?.asset.id) {
                            const endDate = new Date(entry.date);
                            if (isBefore(endDate, focusedAsset.startDate)) {
                              return;
                            }
                            endDate.setDate(endDate.getDate() + 1);
                            const newEntries =
                              focusedAsset.asset.entries.filter((e) =>
                                isWithinInterval(new Date(e.date), {
                                  start: focusedAsset.startDate,
                                  end: new Date(entry.date),
                                }),
                              );
                            if (newEntries.find((e) => !!e.status)) {
                              return;
                            }
                            focusedAsset.endDate = endDate;
                            focusedAsset.entries = newEntries;
                            setSelectedAssets([
                              ...selectedAssets.map((b) =>
                                b.asset.id === focusedAsset.asset.id
                                  ? { ...b, ...focusedAsset }
                                  : b,
                              ),
                            ]);
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  </TooltipTrigger>
                  {entry.booking?.startDate ? (
                    <TooltipPortal>
                      <TooltipContent
                        align="start"
                        side="bottom"
                        className="min-w-[250px] p-0"
                      >
                        <div className=" mt-3 flex items-center justify-between px-3">
                          <p className=" text-lg font-extrabold text-primary-text">
                            {a.name}
                          </p>

                          <p className=" flex items-center justify-center rounded-s bg-secondary-card-backplate p-1 text-xs font-extrabold">
                            {a.categories.map((c) => c.name).join(", ")}
                          </p>
                        </div>

                        <div className="mt-2 border-b border-highlighted-backplate px-3 pb-4">
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("name")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {entry.booking.primaryGuestLastName},{" "}
                              {entry.booking.primaryGuestFirstName}
                            </p>
                          </div>
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("date")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {format(
                                new Date(entry.booking.startDate),
                                "d MMM",
                                {
                                  locale: i18n.language === "sv-se" ? sv : enGB,
                                },
                              )}{" "}
                              -{" "}
                              {format(
                                new Date(entry.booking.endDate),
                                "d MMM",
                                {
                                  locale: i18n.language === "sv-se" ? sv : enGB,
                                },
                              )}{" "}
                              (
                              {differenceInCalendarDays(
                                new Date(entry.booking.endDate),
                                new Date(entry.booking.startDate),
                              )}{" "}
                              {t("nights").toLowerCase()})
                            </p>
                          </div>
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("number-of-guests")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {entry.booking.numAdults ? (
                                <>
                                  {entry.booking.numAdults}({t("adults-short")}){" "}
                                </>
                              ) : undefined}
                              {entry.booking.numTeenagers ? (
                                <>
                                  {entry.booking.numTeenagers}(
                                  {t("teenagers-short")}){" "}
                                </>
                              ) : undefined}
                              {entry.booking.numChildren ? (
                                <>
                                  {entry.booking.numChildren}(
                                  {t("children-short")}){" "}
                                </>
                              ) : undefined}
                              {entry.booking.numInfants ? (
                                <>
                                  {entry.booking.numInfants}(
                                  {t("infants-short")}){" "}
                                </>
                              ) : undefined}
                            </p>
                          </div>
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("price")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {new Intl.NumberFormat(
                                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                                { maximumFractionDigits: 2 },
                              ).format(entry.booking.bookablePrice)}{" "}
                              <span className=" text-[10px] font-normal">
                                SEK
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="mt-4 px-3 pb-3">
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("reservation-no")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {entry.booking.reservationId}
                            </p>
                          </div>
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("channel")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {entry.booking.channelName}
                            </p>
                          </div>
                          <div className="flex flex-nowrap py-[2px]">
                            <p className=" w-[100px] text-xs font-normal">
                              {t("total")}
                            </p>
                            <p className=" flex-1 text-xs font-extrabold">
                              {new Intl.NumberFormat(
                                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                                { maximumFractionDigits: 2 },
                              ).format(entry.booking.totalPrice)}{" "}
                              <span className=" text-[10px] font-normal">
                                SEK
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="mb-4 flex space-x-2 px-3">
                          <p
                            className={cn(
                              "rounded-s p-[6px] text-xs font-extrabold",
                              ` bg-${bookableStatusToColor(entry.status)}-100  text-${bookableStatusToColor(entry.status)}`,
                            )}
                          >
                            {t(entry.status as string)}
                          </p>
                          <p
                            className={cn(
                              "rounded-s p-[6px] text-xs font-extrabold",
                              ` bg-${paymentStatusToColor(entry.booking.paymentStatus)}-100  text-${paymentStatusToColor(entry.booking.paymentStatus)}`,
                            )}
                          >
                            {t(entry.booking.paymentStatus as string)}
                          </p>
                        </div>
                      </TooltipContent>
                    </TooltipPortal>
                  ) : undefined}
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        })}
      </div>
    );
  };

  const listItems: {
    height: number;
    element: ReactNode;
  }[] = useMemo(() => {
    const list: {
      height: number;
      element: ReactNode;
    }[] = [];

    list.push({
      height: 50,
      element: (
        <div className=" flex h-[50px] w-full flex-nowrap">
          {showLocation && (
            <div
              className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-b border-r border-t border-highlighted-backplate px-2 text-sm font-normal"
              onClick={() => {}}
            >
              {t("location")}
            </div>
          )}
          <div
            className=" flex h-full min-w-[170px] max-w-[170px] cursor-pointer items-center justify-between border-b border-r border-t border-highlighted-backplate px-2 text-sm font-normal"
            onClick={() => {}}
          >
            {t("unit")}
          </div>

          {assetGrid?.meta.map((meta, index) => {
            const date = new Date(meta.date);
            return (
              <div
                key={meta.date}
                className={cn(
                  "flex min-w-[50px] flex-1 flex-col items-center justify-center border-b border-t border-highlighted-backplate",
                  {
                    "bg-amber-500-50": isSameDay(today, date),
                    "border-r": index !== assetGrid?.meta.length - 1,
                  },
                )}
              >
                <p
                  className={cn("text-sm font-medium ", {
                    "opacity-40": date < today,
                  })}
                >
                  {format(date, "d")}
                </p>
                <p
                  className={cn("text-xs font-normal text-secondary-text ", {
                    "opacity-40": date < today,
                  })}
                >
                  {format(date, "EEE", {
                    locale: i18n.language === "sv-se" ? sv : enGB,
                  })}
                </p>
              </div>
            );
          })}
        </div>
      ),
    });

    if (groupedData?.length) {
      groupedData.forEach((g) => {
        list.push({
          height: tableSize === "large" ? 45 : tableSize === "medium" ? 36 : 24,
          element: (
            <div
              className={cn(" w-full bg-secondary-card-backplate p-2", {
                "py-1": tableSize === "small",
                "border-b border-highlighted-backplate": g.assets.length,
                "mt-2": tableSize === "large",
              })}
            >
              <p
                className={cn(" text-sm font-extrabold", {
                  "text-xs": tableSize === "small",
                })}
              >
                {t("grouping")}: {g.name}
              </p>
            </div>
          ),
        });

        if (!g.assets.length) {
          list.push({
            height:
              tableSize === "large" ? 44 : tableSize === "medium" ? 36 : 24,
            element: (
              <div
                className={cn("flex w-full items-center justify-center p-2", {
                  "py-1": tableSize === "small",
                })}
              >
                <p
                  className={cn(" text-sm font-normal text-secondary-text", {
                    "text-xs": tableSize === "small",
                    "mt-2": tableSize === "large",
                  })}
                >
                  {t("no-units-found")}
                </p>
              </div>
            ),
          });
        } else {
          g.assets.forEach((a) => {
            list.push({
              height:
                tableSize === "small" ? 25 : tableSize === "medium" ? 40 : 48,
              element: <AssetRow a={a} />,
            });
          });
        }
      });
    } else {
      filteredAssetGrid?.assets.forEach((a) => {
        list.push({
          height: tableSize === "small" ? 25 : tableSize === "medium" ? 40 : 48,
          element: <AssetRow a={a} />,
        });
      });
    }

    return list;
  }, [
    groupedData,
    assetGrid,
    filteredAssetGrid,
    tableSize,
    selectedAssets,
    showLocation,
  ]);

  const rowVirtualizer = useVirtualizer({
    count: listItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => listItems[i].height,
    overscan: 5,
    rangeExtractor: useCallback((range: Range) => {
      const next = new Set([0, ...defaultRangeExtractor(range)]);

      return [...next];
    }, []),
  });

  return (
    <>
      <div className="p-4 text-primary-text">
        <ClusterTabNavBar path={ROUTES.ASSET_GRID} />
        <div className=" mt-4 rounded-lg border border-main-border-color bg-primary-card-backplate">
          <div className="flex justify-between p-4">
            <h1 className=" text-2xl font-bold">{t("unit-grid")}</h1>
            <RouterLink
              to={`/${module}/${ROUTES.UNALLOCATED}/${format(from, "yyyy-MM-dd")}/${format(to, "yyyy-MM-dd")}`}
            >
              <Button variant="primary">
                <ArrowRight size={18} className=" mr-2" />
                {t("bookings-without-allocated-units")}
              </Button>
            </RouterLink>
          </div>

          <div className=" my-4 flex items-center overflow-x-auto px-4">
            <Popover.Root
              open={informationOpen}
              onOpenChange={setInformationOpen}
            >
              <Popover.Trigger asChild>
                <Button variant="secondary">
                  <Info size={18} />
                </Button>
              </Popover.Trigger>
              <Popover.Content
                className="w-auto min-w-[250px] p-0 shadow-xl"
                align="start"
              >
                <div className=" flex items-center justify-between p-4">
                  <h1 className=" text-base font-bold">{t("information")}</h1>
                  <X
                    size={24}
                    className=" cursor-pointer"
                    onClick={() => setInformationOpen(false)}
                  />
                </div>
                <div className=" border-t border-highlighted-backplate p-4">
                  <h3 className=" mb-1 text-xs font-extrabold text-secondary-text">
                    {t("statuses")}
                  </h3>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-ongoing opacity-80" />
                    <p className=" text-xs font-medium">{t("ongoing")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-tentative opacity-80" />
                    <p className=" text-xs font-medium">{t("preliminary")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-unconfirmed opacity-80" />
                    <p className=" text-xs font-medium">{t("unconfirmed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-confirmed opacity-80" />
                    <p className=" text-xs font-medium">{t("confirmed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-guaranteed opacity-80" />
                    <p className=" text-xs font-medium">{t("guaranteed")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-other-category opacity-80" />
                    <p className=" text-xs font-medium">
                      {t("booked-as-another-category")}
                    </p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-checked-in opacity-80" />
                    <p className=" text-xs font-medium">{t("checked-in")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-booking-checked-out opacity-80" />
                    <p className=" text-xs font-medium">{t("checked-out")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-status-error opacity-80" />
                    <p className=" text-xs font-medium">{t("no-show")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-out-of-order opacity-80" />
                    <p className=" text-xs font-medium">{t("out-of-order")}</p>
                  </div>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-out-of-service opacity-80" />
                    <p className=" text-xs font-medium">
                      {t("out-of-service")}
                    </p>
                  </div>
                  <h3 className=" my-1 text-xs font-extrabold text-secondary-text">
                    {t("other")}
                  </h3>
                  <div className=" flex items-center py-1">
                    <div className=" mr-2 min-h-[14px] min-w-[14px] bg-accent-12-500" />
                    <p className=" text-xs font-medium">{t("inactive")}</p>
                  </div>
                </div>
              </Popover.Content>
            </Popover.Root>
            <div className=" flex flex-1 items-center justify-center">
              <div className=" flex items-center space-x-2">
                <Select value={durationIndex} onValueChange={setDurationIndex}>
                  <SelectTrigger className="min-w-[170px] text-xs">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {durationOptions.map((d, i) => (
                      <SelectItem
                        key={i}
                        value={String(i)}
                        className=" text-xs"
                      >
                        {t(d.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <div className=" min-w-[200px]">
                  <DatePicker
                    value={from}
                    clearable={false}
                    onValueChange={(date) => {
                      if (date) {
                        if (duration.sizeType === "month") {
                          date.setDate(1);
                        }
                        setFrom(date);
                      }
                    }}
                    calendarProps={{
                      required: true,
                      disabled:
                        duration.sizeType === "month"
                          ? (day: Date) => day.getDate() !== 1
                          : false,
                    }}
                  />
                </div>
                <div>
                  <Minus size={18} />
                </div>
                <div className=" min-w-[200px]">
                  <DatePicker
                    value={to}
                    clearable={false}
                    buttonProps={{ disabled: true }}
                    calendarProps={{ required: true }}
                  />
                </div>
                <Button variant="secondary" onClick={goToCurrent}>
                  {duration.sizeType === "day"
                    ? t("skip-to-today")
                    : t("skip-to-current-month")}
                </Button>
                <div className=" flex flex-nowrap space-x-1">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      const date = new Date(from);
                      if (duration.sizeType === "day") {
                        date.setDate(date.getDate() - duration.size);
                      } else {
                        date.setMonth(date.getMonth() - duration.size);
                      }
                      setFrom(date);
                    }}
                  >
                    <ChevronsLeft size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={duration.sizeType === "month"}
                    onClick={() => {
                      const date = new Date(from);
                      date.setDate(date.getDate() - 1);
                      setFrom(date);
                    }}
                  >
                    <ChevronLeft size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={duration.sizeType === "month"}
                    onClick={() => {
                      const date = new Date(from);
                      date.setDate(date.getDate() + 1);
                      setFrom(date);
                    }}
                  >
                    <ChevronRight size={18} />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      const date = new Date(from);
                      if (duration.sizeType === "day") {
                        date.setDate(date.getDate() + duration.size);
                      } else {
                        date.setMonth(date.getMonth() + duration.size);
                      }
                      setFrom(date);
                    }}
                  >
                    <ChevronsRight size={18} />
                  </Button>
                </div>
              </div>
            </div>
            <div className=" flex items-center space-x-2">
              <Button
                variant="secondary"
                onClick={() => {
                  switch (tableSize) {
                    case "large":
                      setTableSize("medium");
                      break;
                    case "medium":
                      setTableSize("small");
                      break;
                    case "small":
                      setTableSize("large");
                      break;
                  }
                }}
              >
                <AlignVerticalSpaceAround size={18} />
              </Button>
              <Popover.Root open={filterOpen} onOpenChange={setFilterOpen}>
                <Popover.Trigger asChild>
                  <Button variant="secondary">
                    <Filter size={18} className="mr-2" />
                    {t("show")}
                  </Button>
                </Popover.Trigger>
                <Popover.Content
                  className="w-auto min-w-[250px] p-0 shadow-xl"
                  align="end"
                >
                  <Popover.Root
                    open={groupsFilterOpen}
                    onOpenChange={setGroupsFilterOpen}
                  >
                    <Popover.Trigger asChild>
                      <div
                        className={cn(
                          " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                          {
                            "bg-status-disabled-100": groupsFilterOpen,
                          },
                        )}
                      >
                        <p className=" text-sm font-extrabold">
                          {t("grouping")}
                        </p>
                        <ChevronRight size={16} />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content
                      className="w-auto min-w-[250px] p-0 shadow-xl"
                      align="start"
                      side="left"
                    >
                      <SearchBar
                        className=" mb-1"
                        placeholder={t("search-groups")}
                        value={searchGroups}
                        onChange={(e) => setSearchGroups(e.target.value)}
                      />
                      <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                        <>
                          {filteredGroups?.length === 0 && (
                            <div className="flex items-center justify-center p-3 text-sm text-secondary-text">
                              <p>{t("no-groups-found")}</p>
                            </div>
                          )}
                          {filteredGroups?.map((g) => (
                            <div
                              key={g.id}
                              className=" flex items-center space-x-2 px-2 py-1"
                            >
                              <Checkbox
                                checked={!!groupBy.find((g1) => g.id === g1.id)}
                                onCheckedChange={(v) => {
                                  if (v) {
                                    setGroupBy([
                                      ...groupBy,
                                      { ...g, type: "group" },
                                    ]);
                                  } else {
                                    setGroupBy(
                                      groupBy.filter((g1) => g1.id !== g.id),
                                    );
                                  }
                                }}
                                id={g.id}
                              />
                              <label
                                className=" cursor-pointer text-sm font-medium"
                                htmlFor={g.id}
                              >
                                {g.name}
                              </label>
                            </div>
                          ))}
                        </>
                      </div>
                      <div
                        className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                        onClick={() =>
                          filteredGroups &&
                          setGroupBy(
                            filteredGroups.map((g) => ({
                              ...g,
                              type: "group",
                            })),
                          )
                        }
                      >
                        <p className="text-sm font-extrabold">
                          {t("select-all")}
                        </p>
                      </div>
                    </Popover.Content>
                  </Popover.Root>
                  <Popover.Root
                    open={categoriesFilterOpen}
                    onOpenChange={setCategoriesFilterOpen}
                  >
                    <Popover.Trigger asChild>
                      <div
                        className={cn(
                          " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                          {
                            "bg-status-disabled-100": categoriesFilterOpen,
                          },
                        )}
                      >
                        <p className=" text-sm font-extrabold">
                          {t("categories")}
                        </p>
                        <ChevronRight size={16} />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content
                      className="w-auto min-w-[250px] p-0 shadow-xl"
                      align="start"
                      side="left"
                    >
                      <SearchBar
                        className=" mb-1"
                        placeholder={t("search-categories")}
                        value={searchCategories}
                        onChange={(e) => setSearchCategories(e.target.value)}
                      />
                      <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                        <>
                          {filteredCategories?.length === 0 && (
                            <div className="flex items-center justify-center p-3 text-sm text-secondary-text">
                              <p>{t("no-categories-found")}</p>
                            </div>
                          )}
                          {filteredCategories?.map((g) => (
                            <div
                              key={g.id}
                              className=" flex items-center space-x-2 px-2 py-1"
                            >
                              <Checkbox
                                checked={
                                  !!categories.find((g1) => g.id === g1.id)
                                }
                                onCheckedChange={(v) => {
                                  if (v) {
                                    setCategories([
                                      ...categories,
                                      { ...g, type: "category" },
                                    ]);
                                  } else {
                                    setCategories(
                                      categories.filter((g1) => g1.id !== g.id),
                                    );
                                  }
                                }}
                                id={g.id}
                              />
                              <label
                                className=" cursor-pointer text-sm font-medium"
                                htmlFor={g.id}
                              >
                                {g.name}
                              </label>
                            </div>
                          ))}
                        </>
                      </div>
                      <div
                        className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                        onClick={() =>
                          filteredCategories &&
                          setCategories(
                            filteredCategories.map((c) => ({
                              ...c,
                              type: "category",
                            })),
                          )
                        }
                      >
                        <p className="text-sm font-extrabold">
                          {t("select-all")}
                        </p>
                      </div>
                    </Popover.Content>
                  </Popover.Root>
                  <Popover.Root
                    open={locationsFilterOpen}
                    onOpenChange={setLocationsFilterOpen}
                  >
                    <Popover.Trigger asChild>
                      <div
                        className={cn(
                          " flex cursor-pointer items-center justify-between border-b border-highlighted-backplate p-3",
                          {
                            "bg-status-disabled-100": locationsFilterOpen,
                          },
                        )}
                      >
                        <p className=" text-sm font-extrabold">
                          {t("locations")}
                        </p>
                        <ChevronRight size={16} />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content
                      className="w-auto min-w-[250px] p-0 shadow-xl"
                      align="start"
                      side="left"
                    >
                      <SearchBar
                        className=" mb-1"
                        placeholder={t("search-locations")}
                        value={searchLocations}
                        onChange={(e) => setSearchLocations(e.target.value)}
                      />
                      <div className=" flex max-h-[200px] flex-col overflow-y-auto">
                        <>
                          {filteredLocations?.length === 0 && (
                            <div className="flex items-center justify-center p-3 text-sm text-secondary-text">
                              <p>{t("no-locations-found")}</p>
                            </div>
                          )}
                          {filteredLocations?.map((g) => (
                            <div
                              key={g.id}
                              className=" flex items-center space-x-2 px-2 py-1"
                            >
                              <Checkbox
                                checked={
                                  !!locations.find((g1) => g.id === g1.id)
                                }
                                onCheckedChange={(v) => {
                                  if (v) {
                                    setLocations([
                                      ...locations,
                                      { ...g, type: "location" },
                                    ]);
                                  } else {
                                    setLocations(
                                      locations.filter((g1) => g1.id !== g.id),
                                    );
                                  }
                                }}
                                id={g.id}
                              />
                              <label
                                className=" cursor-pointer text-sm font-medium"
                                htmlFor={g.id}
                              >
                                {g.name}
                              </label>
                            </div>
                          ))}
                        </>
                      </div>
                      <div
                        className="mt-2 flex cursor-pointer items-center justify-center space-x-2 border-t border-highlighted-backplate p-3"
                        onClick={() =>
                          filteredLocations &&
                          setLocations(
                            filteredLocations.map((c) => ({
                              ...c,
                              type: "location",
                            })),
                          )
                        }
                      >
                        <p className="text-sm font-extrabold">
                          {t("select-all")}
                        </p>
                      </div>
                    </Popover.Content>
                  </Popover.Root>
                  <div className=" flex items-center justify-between space-x-2 border-b border-highlighted-backplate p-3">
                    <label
                      className=" cursor-pointer text-sm font-medium"
                      htmlFor="show-location"
                    >
                      {t("show-location")}
                    </label>
                    <Switch
                      checked={showLocation}
                      onCheckedChange={(v) => setShowLocation(v as boolean)}
                      id="show-location"
                    />
                  </div>
                  <div
                    className="flex cursor-pointer items-center justify-center space-x-2 p-3"
                    onClick={() => {
                      setGroupBy([]);
                      setCategories([]);
                      setLocations([]);
                      setShowLocation(false);
                      setFilterOpen(false);
                    }}
                  >
                    <p className="text-sm font-extrabold">{t("reset")}</p>
                  </div>
                </Popover.Content>
              </Popover.Root>
            </div>
          </div>

          {assetGridLoading || assetGridRefetching ? (
            <div className=" relative h-[600px] w-full">
              <Loading />
            </div>
          ) : (
            <div className=" relative w-full overflow-x-auto pt-4">
              {!groupedData.length && !assetGrid?.assets.length ? (
                <div
                  className={cn(
                    "flex h-[600px] w-full items-center justify-center p-2",
                    {
                      "py-1": tableSize === "small",
                    },
                  )}
                >
                  <p
                    className={cn(" text-sm font-normal text-secondary-text", {
                      "text-xs": tableSize === "small",
                      "mt-2": tableSize === "large",
                    })}
                  >
                    {t("no-categories-found")}
                  </p>
                </div>
              ) : (
                <div className=" flex w-fit min-w-full flex-col">
                  <div
                    ref={parentRef}
                    className="List"
                    style={{
                      maxHeight: `calc(100vh - ${tableSize === "small" ? 440 : 500}px)`,
                      minHeight: `${Math.min(400, rowVirtualizer.getTotalSize())}px`,
                      width: `100%`,
                      overflowY: "auto",
                    }}
                  >
                    <div
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                        <div
                          key={virtualRow.key}
                          data-index={virtualRow.index}
                          ref={rowVirtualizer.measureElement}
                          className={cn({
                            " opacity-1 z-10 bg-solid-backplate":
                              virtualRow.index === 0,
                          })}
                          style={{
                            ...(virtualRow.index === 0
                              ? {
                                  position: "sticky",
                                }
                              : {
                                  position: "absolute",
                                  transform: `translateY(${virtualRow.start}px)`,
                                }),
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: `${listItems[virtualRow.index].height}px`,
                          }}
                        >
                          {listItems[virtualRow.index].element}
                        </div>
                      ))}
                    </div>
                  </div>

                  <Collapsible.Root
                    open={numGuestsDetailsOpen}
                    onOpenChange={setNumGuestsDetailsOpen}
                  >
                    <div
                      className={cn(" flex h-[40px] w-full flex-nowrap", {
                        "h-[25px]": tableSize === "small",
                      })}
                    >
                      <Collapsible.Trigger>
                        <div
                          className={cn(
                            " flex h-full min-w-[170px] max-w-[170px] items-center justify-between border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                            {
                              "text-xs": tableSize === "small",
                              "min-w-[340px] max-w-[340px]": showLocation,
                            },
                          )}
                        >
                          {t("number-of-guests")}
                          {numGuestsDetailsOpen ? (
                            <ChevronUp size={18} />
                          ) : (
                            <ChevronDown size={18} />
                          )}
                        </div>
                      </Collapsible.Trigger>

                      {assetGrid?.meta.map((meta, index) => {
                        const date = new Date(meta.date);
                        return (
                          <div
                            key={meta.date}
                            className={cn(
                              "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                              {
                                "bg-primary-card-backplate":
                                  date.getDay() === 0,
                                "bg-amber-500-50": isSameDay(today, date),
                                "border-r":
                                  index !== assetGrid?.meta.length - 1,
                              },
                            )}
                          >
                            <p
                              className={cn("text-sm font-medium ", {
                                "opacity-40": date < today,
                                "text-xs": tableSize === "small",
                              })}
                            >
                              {meta.numGuests}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <Collapsible.Content className=" w-full">
                      <div
                        className={cn(" flex h-[100px] w-full flex-nowrap", {
                          "h-[75px]": tableSize === "small",
                        })}
                      >
                        <div
                          className={cn(
                            " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                            {
                              "text-xs": tableSize === "small",
                              "min-w-[340px] max-w-[340px]": showLocation,
                            },
                          )}
                        >
                          {t("guest-distribution")}
                        </div>

                        {assetGrid?.meta.map((meta, index) => {
                          const date = new Date(meta.date);
                          return (
                            <div
                              key={meta.date}
                              className={cn(
                                "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                                {
                                  "bg-primary-card-backplate":
                                    date.getDay() === 0,
                                  "bg-amber-500-50": isSameDay(today, date),
                                  "border-r":
                                    index !== assetGrid?.meta.length - 1,
                                },
                              )}
                            >
                              <div
                                className={cn(
                                  "flex flex-col text-center text-sm font-medium",
                                  {
                                    "opacity-40": date < today,
                                    "text-xs": tableSize === "small",
                                  },
                                )}
                              >
                                <p>
                                  {meta.numAdults} ({t("adults-short")})
                                </p>
                                <p>
                                  {meta.numTeenagers} ({t("teenagers-short")})
                                </p>
                                <p>
                                  {meta.numChildren} ({t("children-short")})
                                </p>
                                <p>
                                  {meta.numInfants} ({t("infants-short")})
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Collapsible.Content>
                  </Collapsible.Root>

                  <div
                    className={cn(" flex h-[40px] w-full flex-nowrap", {
                      "h-[25px]": tableSize === "small",
                    })}
                  >
                    <div
                      className={cn(
                        " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                        {
                          "text-xs": tableSize === "small",
                          "min-w-[340px] max-w-[340px]": showLocation,
                        },
                      )}
                    >
                      {t("booked-rooms")}
                    </div>

                    {assetGrid?.meta.map((meta, index) => {
                      const date = new Date(meta.date);
                      return (
                        <div
                          key={meta.date}
                          className={cn(
                            "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                            {
                              "bg-primary-card-backplate": date.getDay() === 0,
                              "bg-amber-500-50": isSameDay(today, date),
                              "border-r": index !== assetGrid?.meta.length - 1,
                            },
                          )}
                        >
                          <p
                            className={cn("text-sm font-medium ", {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                            })}
                          >
                            {meta.bookedAssets}
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={cn(" flex h-[40px] w-full flex-nowrap", {
                      "h-[25px]": tableSize === "small",
                    })}
                  >
                    <div
                      className={cn(
                        " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                        {
                          "text-xs": tableSize === "small",
                          "min-w-[340px] max-w-[340px]": showLocation,
                        },
                      )}
                    >
                      {t("vacant-rooms")}
                    </div>

                    {assetGrid?.meta.map((meta, index) => {
                      const date = new Date(meta.date);
                      return (
                        <div
                          key={meta.date}
                          className={cn(
                            "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                            {
                              "bg-primary-card-backplate": date.getDay() === 0,
                              "bg-amber-500-50": isSameDay(today, date),
                              "border-r": index !== assetGrid?.meta.length - 1,
                            },
                          )}
                        >
                          <p
                            className={cn("text-sm font-medium ", {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                              "text-status-error": meta.vacantAssets < 1,
                            })}
                          >
                            {meta.vacantAssets}
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={cn(" flex h-[40px] w-full flex-nowrap", {
                      "h-[25px]": tableSize === "small",
                    })}
                  >
                    <div
                      className={cn(
                        " flex h-full min-w-[170px] max-w-[170px] items-center border-r border-t border-highlighted-backplate bg-amber-500-10 px-2 text-sm font-normal",
                        {
                          "text-xs": tableSize === "small",
                          "min-w-[340px] max-w-[340px]": showLocation,
                        },
                      )}
                    >
                      {t("occupancy")}
                    </div>

                    {assetGrid?.meta.map((meta, index) => {
                      const date = new Date(meta.date);
                      return (
                        <div
                          key={meta.date}
                          className={cn(
                            "flex min-w-[50px] flex-1 items-center justify-center border-t border-highlighted-backplate bg-amber-500-10",
                            {
                              "bg-primary-card-backplate": date.getDay() === 0,
                              "bg-amber-500-50": isSameDay(today, date),
                              "border-r": index !== assetGrid?.meta.length - 1,
                            },
                          )}
                        >
                          <p
                            className={cn("text-sm font-medium ", {
                              "opacity-40": date < today,
                              "text-xs": tableSize === "small",
                              "text-status-error": meta.occupancy > 1,
                            })}
                          >
                            {Math.round(meta.occupancy * 100)}%
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Sheet
        open={newReservationOpen}
        onOpenChange={(open) => {
          if (!open) {
            if (reservation) {
              setAbortReservationOpen(true);
            } else {
              setNewReservationOpen(open);
              setSelectedAssets([]);
            }
          } else {
            setNewReservationOpen(open);
          }
        }}
      >
        <SheetContent
          side="left"
          className="flex w-[400px] flex-col overflow-y-auto sm:w-[600px]"
        >
          <SheetHeader>
            <SheetTitle className=" flex items-center justify-between text-primary-text">
              {reservation
                ? `${t("reservation")} ${reservation.id}`
                : t("do-you-want-to-start-a-booking?")}
              {reservation && (
                <div className=" rounded bg-booking-ongoing-100 p-2 text-xs font-extrabold text-booking-ongoing">
                  {t("ongoing")}
                </div>
              )}
            </SheetTitle>
          </SheetHeader>

          <div className=" flex-1 overflow-y-auto bg-secondary-card-backplate p-4 text-primary-text">
            {selectedAssets.map((b, index) => {
              const { category, asset, startDate, endDate, adults, children } =
                b;
              return (
                <div
                  key={index}
                  className=" mt-4 bg-primary-card-backplate p-4"
                >
                  <div className=" flex justify-between">
                    <div>
                      <p className=" text-sm font-bold">
                        {t("chosen-room")}: {asset?.name}
                      </p>
                      <p className=" text-xs font-normal text-secondary-text">
                        {t("category")}: {category.name}
                      </p>
                    </div>
                    <Ellipsis size={18} className=" cursor-pointer" />
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">
                      {t("arrival-and-departure")}
                    </p>
                    <div className=" rounded-lg border border-border-color px-4 py-1 text-sm font-extrabold">
                      {format(startDate, "yyyy-MM-dd")} -{" "}
                      {format(endDate, "yyyy-MM-dd")} (
                      {getDaysBetween(startDate, endDate).length - 1}{" "}
                      {getDaysBetween(startDate, endDate).length - 1 === 1
                        ? t("night")
                        : t("nights")}
                      )
                    </div>
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">{t("category")}</p>
                    <Select
                      value={category.id}
                      onValueChange={(v) => {
                        const c = asset.categories.find((c) => c.id === v);
                        if (c) {
                          b.category = c;
                          b.adults = c.capacity.adults.min || 1;
                          b.children = [];
                        }
                        setSelectedAssets([...selectedAssets]);
                      }}
                    >
                      <SelectTrigger className="min-w-[170px] text-xs">
                        <SelectValue placeholder={t("category")} />
                      </SelectTrigger>
                      <SelectContent>
                        {asset.categories.map((c) => (
                          <SelectItem
                            key={c.id}
                            value={c.id}
                            className=" text-xs"
                          >
                            {c.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {(b.adults ?? 0) + b.children.length >
                      b.category.capacity.total.max && (
                      <p className=" mt-2 text-xs font-normal text-status-error">
                        {t("{{category}}-allows-max-{{guests}}-guests", {
                          category: b.category.name,
                          guests: b.category.capacity.total.max,
                        })}
                      </p>
                    )}
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">{t("adults")}</p>
                    <Select
                      value={adults !== undefined ? String(adults) : undefined}
                      onValueChange={(v) => {
                        b.adults = Number(v);
                        setSelectedAssets([...selectedAssets]);
                      }}
                    >
                      <SelectTrigger className="min-w-[170px] text-xs">
                        <SelectValue placeholder={t("adults")} />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from(
                          { length: category.capacity.adults.max + 1 },
                          (v, k) => k,
                        )
                          .filter((v) => v >= category.capacity.adults.min)
                          .map((v) => (
                            <SelectItem
                              key={v}
                              value={String(v)}
                              className=" text-xs"
                            >
                              {String(v)}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className=" mt-4">
                    <p className=" mb-1 text-xs font-medium">{t("children")}</p>
                    <Select
                      value={children && String(children?.length)}
                      onValueChange={(v) => {
                        b.children = Array.from(
                          { length: Number(v) },
                          (c, k) => k,
                        ).map((c) => ({
                          age: 0,
                          bedPreference: getBedPreference(0),
                        }));
                        setSelectedAssets([...selectedAssets]);
                      }}
                    >
                      <SelectTrigger className="min-w-[170px] text-xs">
                        <SelectValue placeholder={t("children")} />
                      </SelectTrigger>
                      <SelectContent>
                        {Array.from(
                          {
                            length:
                              category.capacity.children.max +
                              category.capacity.infants.max +
                              category.capacity.teenagers.max +
                              1,
                          },
                          (v, k) => k,
                        ).map((v) => (
                          <SelectItem
                            key={v}
                            value={String(v)}
                            className=" text-xs"
                          >
                            {String(v)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  {children && children?.length > 0 && (
                    <div className=" mt-2 rounded-md border border-highlighted-backplate p-2">
                      {children?.map((child, i) => (
                        <div key={i} className=" mt-2">
                          <p className=" mb-1 text-xs font-extrabold">
                            {t("child")} {i + 1}
                          </p>
                          <label className="text-xs font-medium">
                            {t("age")}
                          </label>
                          <Select
                            value={
                              child.age !== undefined
                                ? String(child?.age)
                                : undefined
                            }
                            onValueChange={(v) => {
                              const age = Number(v);
                              child.age = age;
                              const bedOptions = getPossibleBedPreferences(age);
                              if (!bedOptions.includes(child.bedPreference)) {
                                child.bedPreference = getBedPreference(age);
                              }
                              setSelectedAssets([...selectedAssets]);
                            }}
                          >
                            <SelectTrigger className="min-w-[170px] text-xs">
                              <SelectValue placeholder={t("age")} />
                            </SelectTrigger>
                            <SelectContent>
                              {Array.from({ length: 18 }, (v, k) => k).map(
                                (v) => (
                                  <SelectItem
                                    key={v}
                                    value={String(v)}
                                    className=" text-xs"
                                  >
                                    {String(v)}
                                  </SelectItem>
                                ),
                              )}
                            </SelectContent>
                          </Select>
                          <div className="mt-2">
                            <label className="text-xs font-medium">
                              {t("bed-preference")}
                            </label>
                            <Select
                              value={child.bedPreference}
                              onValueChange={(v) => {
                                child.bedPreference = v as BedPreference;
                                setSelectedAssets([...selectedAssets]);
                              }}
                            >
                              <SelectTrigger className="min-w-[170px] text-xs">
                                <SelectValue
                                  placeholder={t("bed-preference")}
                                />
                              </SelectTrigger>
                              <SelectContent>
                                {getPossibleBedPreferences(child.age).map(
                                  (p) => (
                                    <SelectItem
                                      key={p}
                                      value={p}
                                      className=" text-xs"
                                    >
                                      {bedOptionLabel(p, t)}
                                    </SelectItem>
                                  ),
                                )}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className=" mt-[-16px] flex flex-col items-end bg-secondary-card-backplate p-4">
            <p className=" text-xs font-medium">{t("total")}</p>
            <p className=" text-sm font-extrabold text-secondary-text">
              {new Intl.NumberFormat(
                i18n.language === "sv-se" ? "sv-SE" : "en-GB",
                { maximumFractionDigits: 2 },
              ).format(totalPrice)}{" "}
              SEK
            </p>
          </div>

          <div className=" flex justify-end space-x-2 ">
            <Button
              variant="outline"
              onClick={() => {
                if (reservation) {
                  setAbortReservationOpen(true);
                } else {
                  setNewReservationOpen(false);
                  setSelectedAssets([]);
                }
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={
                updateReservationLoading ||
                !selectedAssets.length ||
                !reservation ||
                selectedAssets.some(
                  (a) =>
                    (a.adults ?? 0) + a.children.length >
                    a.category.capacity.total.max,
                )
              }
              onClick={onContinue}
              loading={updateReservationLoading}
            >
              {t("continue")}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
      <BlockDrawer
        type="asset"
        open={!!assetToBlock}
        id={assetToBlock?.id}
        name={assetToBlock?.name}
        onClose={() => setAssetToBlock(undefined)}
      />
      <ConfirmDialog
        proceedBtnText={t("abort-reservation")}
        title={t("do-you-really-want-to-abort-the-reservation?")}
        description={t(
          "the-reservation-is-saved-as-aborted-and-will-no-longer-affect-the-category-or-room-availability",
        )}
        onOpenChange={setAbortReservationOpen}
        isOpen={abortReservationOpen}
        onProceed={abortReservation}
      />
    </>
  );
};

export default AssetGridPage;
