import { useGetPriceCalendar } from "@api/price-calendar";
import { queryClient } from "query-client";
import { FC, useEffect } from "react";
import { CalendarHeader } from "./components/calendar-header";
import { YearLayoutCalendar } from "./components/year-layout-calendar";
import { PriceCalendarContextProvider } from "./price-calendar-context";
import { CategoryType } from "../../../../../api-contracts/categories";

interface Props {
  tab: CategoryType[];
}
const PriceCalendarPage: FC<Props> = ({ tab }) => {
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: useGetPriceCalendar.getKey() });
  }, []);

  return (
    <PriceCalendarContextProvider>
      <div className="flex h-full w-full flex-col overflow-x-auto">
        <CalendarHeader tab={tab} />
        <YearLayoutCalendar />
      </div>
    </PriceCalendarContextProvider>
  );
};

export default PriceCalendarPage;
