import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@primitives/command";
import { Input } from "@primitives/input";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { TCountryCode, countries, getCountryData } from "countries-list";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onValueChange?: (value?: string) => void;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
}

const CountrySelect = ({
  value,
  onValueChange,
  id,
  disabled,
  placeholder,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const filteredCountries = useMemo(
    () =>
      Object.entries(countries).filter(([_, country]) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(
      getCountryData(value?.toUpperCase() as TCountryCode)?.name || "",
    );
  }, [value]);

  return (
    <PopoverRoot
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
        if (!o) {
          setSearchTerm(
            getCountryData(value?.toUpperCase() as TCountryCode)?.name || "",
          );
        }
      }}
    >
      <PopoverTrigger
        asChild
        disabled={disabled}
        className=" min-w-[150px] flex-1"
      >
        <div className="relative ">
          <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
            {value ? (
              <img
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.toUpperCase()}.svg`}
                className={"mr-2 inline h-4 rounded-sm"}
              />
            ) : undefined}
          </div>
          <Input
            autoComplete="off"
            disabled={disabled}
            id={id}
            className="ps-10"
            value={searchTerm}
            placeholder={placeholder}
            onChange={(e) => {
              setOpen(true);
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="PopoverContent p-0"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Command>
          <CommandList>
            <CommandEmpty>{t("no-country-found")}</CommandEmpty>
            <CommandGroup>
              {filteredCountries.map(([code, { name }]) => (
                <CommandItem
                  key={code}
                  value={code}
                  onSelect={() => {
                    onValueChange?.(code);
                    setOpen(false);
                  }}
                >
                  <div>
                    <span>
                      <img
                        alt={name}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
                        className={"mr-2 inline h-4 rounded-sm"}
                      />
                      {name}
                    </span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverRoot>
  );
};

export default CountrySelect;
