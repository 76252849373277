//Main-nav

import { getFullName } from "@shared/utils/text";
import {
  ArrowLeft,
  ArrowRight,
  HotelIcon,
  LogOutIcon,
  LucideIcon,
  TentIcon,
} from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { AuthUser } from "@shared/context/auth-context";
import { SiteSelector } from "@shared/components/site-selector";
import { useSites } from "@shared/hooks/use-sites";
import { useTranslation } from "react-i18next";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import { version } from "version";
import { useTheme } from "@shared/hooks/use-theme";
import PlaceholderThumbnail from "/user-thumbnail.png";
import {
  ACTIVE_MODULE_STORAGE_KEY,
  Clusters,
  useProfileContext,
} from "@context/profile-context";
import { Search } from "./search";
import { PermissionModule } from "../../../../../api-contracts/auth";

interface MainNavProps {
  navItems: NavItem[];
  children: ReactNode;
  user?: AuthUser;
}

export interface NavSubItem {
  urls: string[];
  name: string;
}

export interface NavItem {
  url?: string;
  icon: LucideIcon;
  name: string;
  subItems?: NavSubItem[];
}

const ICON_STROKE_WIDTH = 1.5;
const ICON_SIZE = 21;
const NAV_LIST_WIDTH = 70;
const NAV_SUB_LIST_WIDTH = 180;
const NAV_LIST_HEADER_WIDTH = NAV_LIST_WIDTH + NAV_SUB_LIST_WIDTH;

function MainNav({ navItems, children, user }: MainNavProps) {
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { activeSite, sites, setActiveSite } = useSites();
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollAreaRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  const findInitialActiveMenu = useCallback(
    (navItems: NavItem[]) => {
      const pathName = `${location.pathname}`;
      for (const item of navItems) {
        if (item.subItems) {
          const foundSubItem = item.subItems.find((subItem) =>
            subItem.urls.find((url) => {
              return pathName.startsWith(url);
            }),
          );
          if (foundSubItem) {
            return item;
          }
        }
      }
      return navItems[0];
    },
    [navItems, location.pathname],
  );

  const initialActiveMainMenu = findInitialActiveMenu(navItems);

  const [submenuItems, setSubmenuItems] = useState<NavSubItem[] | undefined>(
    initialActiveMainMenu?.subItems,
  );

  const [activeMainMenu, setActiveMainMenu] = useState<NavItem>(
    initialActiveMainMenu,
  );
  const [isExpanded, setIsExpanded1] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isExpanded");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("isExpanded", JSON.stringify(isExpanded));
  }, [isExpanded]);

  const setIsExpanded = (value: boolean) => {
    setShouldAnimate(true);
    setIsExpanded1(value);
  };
  useEffect(() => {
    const initialActiveMainMenu = findInitialActiveMenu(navItems);
    setActiveMainMenu(initialActiveMainMenu);
    setSubmenuItems(initialActiveMainMenu?.subItems);
  }, [navItems, location.pathname, findInitialActiveMenu]);

  const onNavItemClicked = (navItem: NavItem) => {
    const hasSubItems = navItem.subItems && navItem.subItems.length > 0;
    const isPreviousActiveMenu = navItem.name === activeMainMenu.name;

    if (hasSubItems) {
      setSubmenuItems(navItem.subItems);
      setActiveMainMenu(navItem);
      if (isPreviousActiveMenu) {
        setIsExpanded(false);
      }
      if (!isExpanded) {
        setIsExpanded(true);
      }
    } else return;
  };

  const NavMenuList = ({ navItems }: { navItems: NavItem[] }) => {
    const commonItemNames = ["customers", "general-settings"];

    const commonList = navItems.filter((item) =>
      commonItemNames.includes(item.name),
    );
    const nonCommonList = navItems.filter(
      (item) => !commonItemNames.includes(item.name),
    );

    return (
      <div
        style={{
          minWidth: `${NAV_LIST_WIDTH}px`,
        }}
        className="flex h-full flex-col items-center justify-between "
      >
        <div className="flex flex-col">
          {nonCommonList.map((item) => (
            <div
              key={item.name}
              className="flex cursor-pointer items-center py-1"
              onClick={() => {
                onNavItemClicked(item);
              }}
            >
              <NavItemIcon
                item={item}
                isActive={item.name === activeMainMenu?.name}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col p-3">
          {commonList.map((item) => (
            <div
              key={item.name}
              className="flex cursor-pointer items-center py-1"
              onClick={() => {
                onNavItemClicked(item);
              }}
            >
              <NavItemIcon
                item={item}
                isActive={item.name === activeMainMenu?.name}
              />
            </div>
          ))}
          <p className="py-1 text-center text-xs font-normal text-secondary-text">
            {version}
          </p>
        </div>
      </div>
    );
  };

  const NavItemIcon = ({
    item,
    isActive,
  }: {
    item: NavItem;
    isActive: boolean;
  }) => {
    return (
      <div
        className={clsx(
          "flex h-[44px] w-[44px] items-center justify-center rounded-xl",
          isActive ? "bg-primary-color" : "bg-primary-button-backplate",
        )}
      >
        <item.icon
          strokeWidth={ICON_STROKE_WIDTH}
          size={ICON_SIZE}
          className={clsx(
            "text-primary-icon-color",
            isActive && "text-secondary-icon-color",
          )}
        />
      </div>
    );
  };

  const RoundedIconBtn = ({
    onClick,
    icon,
    className = "",
  }: {
    onClick: () => void;
    icon: ReactNode;
    className?: string;
  }) => {
    return (
      <div
        className={`flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-lg bg-primary-button-backplate ${className}`}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const NavSubMenuList = () => {
    return (
      <div className={!isExpanded ? "opacity-0" : "opacity-1"}>
        <div>
          <h4 className=" font-itc text-lg font-bold text-primary-text">
            {t(activeMainMenu?.name)}
          </h4>
          {submenuItems?.map((sItem) => {
            const isActive = sItem.urls.some((url) =>
              location.pathname.startsWith(url),
            );
            return (
              <div key={sItem.urls[0]}>
                {sItem.urls[0].includes("campr/bookables") ? (
                  <Link to={"/campr/bookables/plots"}>
                    <p
                      className={clsx(
                        "line-clamp-1 rounded pt-2 text-left font-neue text-sm",
                        isActive
                          ? "font-extrabold text-primary-text"
                          : "text-secondary-text",
                      )}
                    >
                      {t(sItem.name)}
                    </p>
                  </Link>
                ) : (
                  <Link to={sItem.urls[0]}>
                    <p
                      className={clsx(
                        "line-clamp-1 rounded pt-2 text-left font-neue text-sm",
                        isActive
                          ? "font-extrabold text-primary-text"
                          : "text-secondary-text",
                      )}
                    >
                      {t(sItem.name)}
                    </p>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const NavListHeader = () => {
    return (
      <div
        className={`flex border-b-2 border-screen-background py-3 text-primary-icon-color`}
      >
        {!isExpanded ? (
          <div className="px-3">
            <RoundedIconBtn
              className="rounded-xl"
              icon={<ArrowRight size={ICON_SIZE} />}
              onClick={() => setIsExpanded(true)}
            />
          </div>
        ) : (
          <div className={`flex flex-1 items-center justify-between px-3`}>
            <div className="flex flex-col">
              <h2 className="line-clamp-1 font-itc font-bold text-primary-text">
                {activeSite?.name}
              </h2>
            </div>
            <div className="flex">
              <RoundedIconBtn
                className="rounded-xl"
                icon={<ArrowLeft size={ICON_SIZE} />}
                onClick={() => setIsExpanded(false)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  /** Missing thumbnail & role in User object */
  const UserView = () => {
    return (
      <HoverCard openDelay={0}>
        <HoverCardTrigger>
          <div className="flex cursor-pointer rounded-lg bg-primary-button-backplate">
            <div
              onDoubleClick={() =>
                setTheme(theme === "light" ? "dark" : "light")
              }
              className="rounded-lg"
            >
              <img
                src={PlaceholderThumbnail}
                alt="User Thumbnail Image"
                className="h-full w-12 rounded"
              />
            </div>

            <div
              onDoubleClick={() =>
                i18n.changeLanguage(i18n.languages[0] === "en" ? "sv-se" : "en")
              }
              className="flex flex-col justify-center px-3 font-itc text-sm "
            >
              <p className="font-bold text-primary-text">{getFullName(user)}</p>
              <p className="font-normal text-secondary-text">
                {user?.type === "admin" ? "Super Admin" : "Role"}
              </p>
            </div>
          </div>
        </HoverCardTrigger>

        <HoverCardContent
          sideOffset={5}
          alignOffset={-10}
          align="start"
          className="z-50 flex flex-col rounded-lg bg-solid-backplate p-4 px-8 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <button onClick={() => navigate("/logout")}>
            <div className="flex flex-row items-center text-primary-text">
              <LogOutIcon />
              <p className=" ml-5 font-itc text-sm font-bold">{t("logout")}</p>
            </div>
          </button>
        </HoverCardContent>
      </HoverCard>
    );
  };

  const ModuleSelector = () => {
    const { module, setModule, getModuleCluster, clusters, modulePermissions } =
      useProfileContext();
    const moduleIcons = (module: PermissionModule) => {
      switch (module) {
        case "campr":
          return <TentIcon strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />;
        case "roomr":
        default:
          return <HotelIcon strokeWidth={ICON_STROKE_WIDTH} size={ICON_SIZE} />;
      }
    };

    const onModuleChange = (mod: PermissionModule) => {
      if (mod === module) {
        return;
      }
      setModule(mod);
      const newCluster = getModuleCluster(mod);
      const path = location.pathname.split(`/${module}`)[1];
      if (location.pathname.includes("roomr/bookables")) {
        navigate(`/${mod}/bookables/plots`, { replace: true });
      } else if (location.pathname.includes("/campr/bookables")) {
        navigate(`/${mod}/bookables/rooms`, { replace: true });
      } else if (path.includes("category-grid")) {
        navigate(`/${mod}/category-grid/${newCluster[0].name}`, {
          replace: true,
        });
      } else if (path.includes("asset-grid")) {
        navigate(`/${mod}/asset-grid/${newCluster[0].name}`, {
          replace: true,
        });
      } else if (path.includes("pricing")) {
        navigate(
          module === "roomr"
            ? `/campr/pricing/${newCluster[0].name}`
            : `/roomr/pricing/hotel`,
          {
            replace: true,
          },
        );
      } else if (path.includes("price-calendar")) {
        navigate(
          module === "roomr"
            ? `/campr/price-calendar/${newCluster[0].name}`
            : `/roomr/price-calendar/hotel`,
          {
            replace: true,
          },
        );
      } else {
        navigate(`/${mod}${path}`, { replace: true });
      }
      localStorage.setItem(ACTIVE_MODULE_STORAGE_KEY, mod);
    };

    return (
      <HoverCard openDelay={0}>
        <HoverCardTrigger>
          <div className="flex cursor-pointer flex-row justify-center rounded-lg bg-primary-button-backplate ">
            <RoundedIconBtn
              onClick={() => null}
              className="bg-primary-color text-secondary-icon-color"
              icon={moduleIcons(module)}
            />
            <div className="flex flex-col justify-center px-2 align-middle">
              <p className="align-middle font-itc text-base font-bold text-primary-text">
                {module.toLocaleUpperCase()}
              </p>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent
          alignOffset={-25}
          sideOffset={8}
          align="start"
          className="z-50 flex flex-col rounded-lg bg-solid-backplate p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          {modulePermissions.map((mod) => (
            <div
              onClick={() => onModuleChange(mod as PermissionModule)}
              key={mod}
              className="mt-2 flex cursor-pointer justify-start rounded-lg bg-primary-button-backplate"
            >
              <RoundedIconBtn
                onClick={() => null}
                className={`bg-primary-color text-secondary-icon-color`}
                icon={moduleIcons(mod as PermissionModule)}
              />
              <p className="mx-2 flex items-center justify-center align-middle font-itc text-base font-bold text-primary-text">
                {mod.toLocaleUpperCase()}
              </p>
            </div>
          ))}
        </HoverCardContent>
      </HoverCard>
    );
  };

  const TopHeaderBar = () => {
    return (
      <div className=" flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <UserView />
          <div className="pl-1" />
          <SiteSelector
            activeSite={activeSite}
            availableSites={sites}
            onSiteSelected={(site) => {
              setActiveSite(site);
              navigate("/");
              window.location.reload();
            }}
          />
        </div>
        <div className="min-w-[400px]">
          <Search pages={navItems} />
        </div>
        <div className={"flex"}>
          <ModuleSelector />
        </div>
      </div>
    );
  };

  const animationClass = shouldAnimate
    ? isExpanded
      ? "animate-width-expand"
      : "animate-width-collapse"
    : "";

  const navHeaderStyle = shouldAnimate
    ? {
        "--target-width": `${NAV_LIST_HEADER_WIDTH}px`,
      }
    : {
        width: isExpanded ? NAV_LIST_HEADER_WIDTH : 0,
      };
  const navHeaderClassName = shouldAnimate ? animationClass : "";
  const navSubMenuClassName = shouldAnimate
    ? animationClass
    : isExpanded
      ? `w-[${NAV_SUB_LIST_WIDTH}px]`
      : "w-0";
  return (
    <>
      <div className="flex h-screen flex-col overflow-hidden font-neue">
        <div className="m-1 rounded-lg border border-main-border-color bg-solid-background-backplate p-3">
          <TopHeaderBar />
        </div>

        <div className="flex flex-grow">
          {navItems && (
            <nav className="m-1 flex flex-col rounded-lg border border-main-border-color bg-primary-card-backplate p-1">
              <div style={navHeaderStyle} className={navHeaderClassName}>
                <NavListHeader />
              </div>
              <div className="flex h-full flex-grow flex-col justify-between">
                <div className="flex flex-grow pt-3">
                  <NavMenuList navItems={navItems} />
                  <div className={navSubMenuClassName}>
                    <NavSubMenuList />
                  </div>
                </div>
              </div>
            </nav>
          )}
          <div
            ref={scrollAreaRef}
            className=" m-1 h-[calc(100vh-80px)] w-full overflow-y-auto rounded-lg border border-main-border-color bg-primary-card-backplate p-1"
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainNav;
