import { CardTitle } from "@primitives/card";
import { useTranslation } from "react-i18next";
import { CardHeaderTabs } from "./card-header-tabs";
import { Button } from "@primitives/button";
import { FullSearchResult } from "../../../../../api-contracts/search";
import { Column, VisibilityState } from "@tanstack/react-table";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { CheckboxWithLabel } from "@primitives/checkbox-with-label";
import { Filter, Printer } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { CsvButton } from "./csv-button";
import { printTable } from "@pages/reception/reception-utils";

export const TableHeader = ({
  searchTerm,
  results,
  columns,
  setColumnVisibility,
  openFiltersSheet,
  areFiltersApplied,
}: {
  searchTerm: string;
  results: FullSearchResult[];
  columns: Column<FullSearchResult, unknown>[];
  setColumnVisibility: Dispatch<SetStateAction<VisibilityState>>;
  openFiltersSheet: () => void;
  areFiltersApplied: boolean;
}) => {
  const { t } = useTranslation();

  const resetColumnVisibility = () => {
    setColumnVisibility({});
  };
  return (
    <div className="flex flex-col pb-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="flex items-center pb-2">
            <CardTitle className="mr-4">{t("search-results")}</CardTitle>
            <CardHeaderTabs numberOfResults={results?.length || 0} />
          </div>
          <span className="text-sm font-medium text-secondary-text">
            {t("showing-search-results-for")}
            <span className="ml-1 font-semibold text-primary-text">
              {searchTerm}
            </span>
          </span>
        </div>
        <div className="flex">
          <div className="mx-4 flex">
            <Button
              disabled={results.length === 0}
              variant={"secondary"}
              className="mx-2"
              size="icon"
              onClick={printTable}
            >
              <Printer size={"15px"} />
            </Button>
            <CsvButton
              searchColumnDefinition={columns}
              searchResults={results}
            />
          </div>
          <div className="flex">
            <ColumnVisibilityToggle
              columns={columns}
              resetColumnVisibility={resetColumnVisibility}
            />
            <Button
              className={areFiltersApplied ? "border-amber-500-50" : ""}
              variant={"outline"}
              onClick={openFiltersSheet}
            >
              {t("filter")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnVisibilityToggle = ({
  columns,
  resetColumnVisibility,
}: {
  columns: Column<FullSearchResult, unknown>[];
  resetColumnVisibility: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button className="mx-2" variant={"secondary"}>
          <Filter size={"15px"} className="mr-1 text-primary-icon-color" />
          {t("show")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{t("columns")}</DropdownMenuLabel>
        {columns.map((col) => {
          if (col.columnDef.enableHiding === false) return null;
          const label = col?.columnDef?.header?.toString();

          return (
            <div key={col.id} className="mx-2 flex h-[32px] items-center">
              <CheckboxWithLabel
                key={col.id}
                label={label}
                checked={col.getIsVisible()}
                onCheckedChange={() => col.toggleVisibility()}
              />
            </div>
          );
        })}
        <div className="my-1 flex w-full items-center justify-center">
          <Button
            size={"sm"}
            variant={"outline"}
            className="flex items-center justify-center"
            onClick={resetColumnVisibility}
          >
            {t("reset")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};
