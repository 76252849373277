import { Button } from "@primitives/button";
import { InputWithLabel } from "./input-with-label";
import { t } from "i18next";
import { useState } from "react";

export const Address = ({
  address,
  handleInputChange,
}: {
  address: {
    address: string;
    address2: string;
    zip: string;
    city: string;
    state: string;
    countryCode: string;
  };
  handleInputChange: (key: keyof typeof address, value: string) => void;
}) => {
  const [showSecondAddressLine, setShowSecondAddressLine] = useState<boolean>(
    address?.address2?.length > 0,
  );

  const onToggleAddressLine = () => {
    setShowSecondAddressLine((prev) => !prev);
    if (showSecondAddressLine) {
      handleInputChange("address2", "");
    }
  };

  return (
    <div className="rounded-lg bg-secondary-card-backplate p-4">
      <p className="text-base font-medium">{t("address")}</p>
      <InputWithLabel
        label={t("address-line")}
        value={address.address}
        onChange={(e) => handleInputChange("address", e.target.value)}
      />
      {showSecondAddressLine && (
        <InputWithLabel
          label={t("address-line-2")}
          value={address.address2}
          onChange={(e) => handleInputChange("address2", e.target.value)}
        />
      )}
      <Button size="sm" variant="secondary" onClick={onToggleAddressLine}>
        {showSecondAddressLine
          ? t("remove-address-line")
          : t("add-address-line")}
      </Button>
      <InputWithLabel
        label={t("zip-code")}
        value={address.zip}
        onChange={(e) => handleInputChange("zip", e.target.value.trim())}
      />
      <InputWithLabel
        label={t("postal-address")}
        value={address.city}
        onChange={(e) => handleInputChange("city", e.target.value)}
      />
      <InputWithLabel
        label={t("state-province-region")}
        value={address.state}
        onChange={(e) => handleInputChange("state", e.target.value)}
      />
      <InputWithLabel
        label={t("country")}
        value={address.countryCode}
        onChange={(e) => handleInputChange("countryCode", e.target.value)}
      />
    </div>
  );
};
