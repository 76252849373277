import { useGetTags } from "@api/tags";
import { getTextColor } from "@pages/settings/tags/tags-utils";
import { Button } from "@primitives/button";
import { SearchBar } from "@primitives/search-bar";
import { Tooltip } from "@radix-ui/react-tooltip";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../../../../api-contracts/tags";
import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "@primitives/popover";
import { useProfileContext } from "@context/profile-context";
import { Loading } from "@primitives/loading";
import { X } from "lucide-react";

interface TagBadgeProps {
  tag: Tag;
  onClick?: () => void;
  showDelete?: boolean;
  onDelete?: () => void;
}

export const Tags = ({
  selectedTags,
  onValueChange,
  disabled,
}: {
  selectedTags: string[];
  onValueChange: (value: string[]) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { data: tagsData, isLoading: tagsLoading } = useGetTags();

  const selectedTagObjects = useMemo(() => {
    if (!tagsData) return [];
    return tagsData.filter((tag) => selectedTags.includes(tag.name));
  }, [selectedTags, tagsData]);

  return (
    <div className="my-3 rounded-lg bg-secondary-card-backplate p-4">
      <div
        className={`flex justify-between ${disabled && "text-status-disabled"}`}
      >
        <p className="text-base font-medium">{t("tags")}</p>
        <PopoverRoot>
          <PopoverTrigger asChild className="h-8">
            <Button disabled={disabled} variant="outline">
              {t("add-tag")}
            </Button>
          </PopoverTrigger>
          <TagSelector
            value={selectedTags}
            onValueChange={(tags) => onValueChange(tags)}
            tagsData={tagsData}
            tagsIsLoading={tagsLoading}
          />
        </PopoverRoot>
      </div>
      <div className="flex flex-wrap gap-2">
        {selectedTagObjects.map((tag) => (
          <TagBadge
            key={tag.id}
            tag={tag}
            showDelete
            onDelete={() =>
              onValueChange?.(selectedTags.filter((name) => name !== tag.name))
            }
          />
        ))}
      </div>
    </div>
  );
};

const TagSelector = ({
  value,
  onValueChange,
  tagsData,
  tagsIsLoading,
}: {
  value: string[];
  onValueChange?: (value: string[]) => void;
  tagsData?: Tag[];
  tagsIsLoading: boolean;
}) => {
  const { module } = useProfileContext();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  const filteredTags = useMemo(() => {
    if (!tagsData) return [];
    const searchLower = searchTerm.toLowerCase();
    return tagsData.filter(
      (tag) =>
        tag.active &&
        (tag.name.toLowerCase().includes(searchLower) ||
          tag.short.toLowerCase().includes(searchLower)),
    );
  }, [tagsData, searchTerm]);

  return (
    <PopoverContent className="z-[201] min-w-[400px]" align="end">
      <div>
        <h4 className="text-sm font-extrabold text-primary-text">
          {t("add-tag")}
        </h4>
        <p className="mb-2 text-sm font-normal text-secondary-text">
          {t("click-a-tag-to-add-it")}
        </p>

        <SearchBar
          placeholder={t("search")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="max-h-[400px] overflow-y-auto pt-4">
          <div>
            <p className="mb-2 text-xs font-normal text-secondary-text">
              {module.toUpperCase()}
            </p>
            <div className="relative flex flex-wrap gap-2">
              {tagsIsLoading && <Loading />}
              {filteredTags?.map((tag) => (
                <TagBadge
                  key={tag.id}
                  tag={tag}
                  onClick={() => {
                    if (!value.includes(tag.name)) {
                      onValueChange?.([...value, tag.name]);
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </PopoverContent>
  );
};

export const TagBadge = ({
  tag,
  onClick,
  showDelete = false,
  onDelete,
}: TagBadgeProps) => {
  return (
    <Tooltip delayDuration={0}>
      <div
        className="flex cursor-pointer items-center space-x-1 rounded-lg p-2 text-xs font-extrabold"
        onClick={showDelete ? onDelete : onClick}
        style={{
          backgroundColor: tag.color,
          color: getTextColor(tag.color),
        }}
      >
        {showDelete && (
          <X
            size={13}
            style={{
              color: getTextColor(tag.color),
            }}
          />
        )}
        <p>{tag.short}</p>
      </div>
    </Tooltip>
  );
};
