import { Input } from "@primitives/input";
import { Label } from "@primitives/label";
import { Calendar } from "lucide-react";

export const InputWithLabel = ({
  label,
  type = "text",
  className = "my-2",
  placeholder,
  showCalendarIcon,
  disabled = false,
  onChange,
  value,
}: {
  label?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  showCalendarIcon?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}) => {
  return (
    <div className={className}>
      <Label className={`${disabled ? "text-status-disabled" : ""}`}>
        {label}
      </Label>
      <div className="relative mt-1">
        <Input
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          className={`rounded-lg ${showCalendarIcon ? "pe-2 ps-7" : ""}`}
        />
        {showCalendarIcon && (
          <div className="absolute inset-y-0 start-0 flex items-center ps-2">
            <Calendar strokeWidth={0.5} size={16} />
          </div>
        )}
      </div>
    </div>
  );
};
