import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs.tsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import { isArray } from "lodash";
import { capitalizeFirstLetter } from "@utils/helpers.ts";

export interface TabItem {
  value: string | string[];
  label: string;
  disabled?: boolean;
  to?: string;
}

interface Props {
  value: string;
  onValueChange?: (value: string) => void;
  items: TabItem[];
  all?: TabItem;
}
export const TabNavbar: FC<Props> = ({ items, value, onValueChange, all }) => {
  return (
    <Tabs value={value} onValueChange={onValueChange}>
      <TabsList>
        {all && all.to && (
          <Link to={all?.to} key={`${all}`}>
            <TabsTrigger
              key={all.to}
              value={all.value[0] as string}
              disabled={false}
            >
              {all.label}
            </TabsTrigger>
          </Link>
        )}
        {items.map((item, index) =>
          item.to ? (
            <Link to={item.to} key={`${item}-${index}`}>
              <TabsTrigger
                key={`${item}-${index}`}
                value={isArray(item.value) ? item.value[0] : item.value}
                disabled={item.disabled}
              >
                {capitalizeFirstLetter(item.label)}
              </TabsTrigger>
            </Link>
          ) : (
            <TabsTrigger
              key={`${item}-${index}`}
              value={isArray(item.value) ? item.value.join("&") : item.value}
              disabled={item.disabled}
            >
              {capitalizeFirstLetter(item.label)}
            </TabsTrigger>
          ),
        )}
      </TabsList>
    </Tabs>
  );
};
