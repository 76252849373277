import { CardTitle } from "@primitives/card";
import { Tabs, TabsList, TabsTrigger } from "@primitives/tabs";
import { useTranslation } from "react-i18next";
import { Button } from "@primitives/button";
import {
  AlignStartHorizontal,
  AlignStartVertical,
  Check,
  ChevronLeft,
  ChevronRight,
  FilterIcon,
  LayoutGrid,
  X,
} from "lucide-react";
import { Layouts, usePriceCalendarContext } from "../price-calendar-context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@primitives/select";
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "@primitives/dropdown-menu";
import { Loading } from "@primitives/loading";
import { Label } from "@primitives/label";
import { Input } from "@primitives/input";
import { FC, useEffect, useMemo, useState } from "react";
import { NoteItem } from "./note-alert-dialog";
import {
  useCreatePriceCalendarNote,
  useDeletePriceCalendarNote,
  useGetPriceCalendar,
  useSetPriceCalendar,
  useUpdatePriceCalendarNote,
} from "@api/price-calendar";
import { toast } from "@hooks/use-toast";
import { queryClient } from "query-client";
import {
  CalendarDay,
  GetPriceRulesPriceRule,
  Note,
  SetPriceCalendarRequest,
} from "../../../../../../api-contracts/price-calendar";
import { format } from "date-fns";
import { SearchBar } from "@primitives/search-bar";
import { CategoryType } from "../../../../../../api-contracts/categories";
import { Switch } from "@primitives/switch";
import { ToggleGroup, ToggleGroupItem } from "@primitives/toggle-group";
import { ClusterTabNavBar } from "@components/cluster-tab-nav-bar.tsx";
import { ROUTES } from "@shared/types/navigation.ts";

interface Props {
  tab: CategoryType[];
}
export const CalendarHeader: FC<Props> = ({ tab }) => {
  const { t } = useTranslation();
  const { selectedDays, layout } = usePriceCalendarContext();
  const isSelectedDays = selectedDays.length > 0;
  const isMonthLayout = layout === Layouts.Month;
  const [routeTab, setRouteTab] = useState(tab);

  useEffect(() => {
    setRouteTab(tab);
  }, [tab]);

  return (
    <div className="flex flex-col p-4 pb-0">
      <CategorySelector tab={tab} />
      <div className="flex items-center justify-between py-4">
        <div className="mr-auto w-1/3">
          <CardTitle>{t("price-calendar")}</CardTitle>
        </div>
        <div className="mx-auto flex h-16 items-center justify-center gap-x-4">
          {isSelectedDays ? (
            <CalendarActionBar />
          ) : isMonthLayout ? (
            <CalendarMonthSelectorBar />
          ) : (
            <CalendarYearSelectorBar />
          )}
        </div>
        <div className="ml-auto flex w-1/3 items-center justify-end gap-4">
          <LayoutSelector />
          <FilterDropdown />
        </div>
      </div>
    </div>
  );
};

const CategorySelector: FC<Props> = ({ tab }) => {
  const { t } = useTranslation();
  const {
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    availableCategories,
  } = usePriceCalendarContext();

  const handleSubCategoryChange = (value: string) => {
    setSelectedSubCategory(value);
  };

  useEffect(() => {
    setSelectedCategory(tab);
    setSelectedSubCategory("all");
  }, [tab]);

  const subCategories = [
    { id: "all", name: t("all-categories") },
    ...availableCategories,
  ];

  return (
    <div className="flex gap-4">
      <ClusterTabNavBar path={ROUTES.PRICE_CALENDAR} tabsAsLinks={true} />
      <Select
        onValueChange={handleSubCategoryChange}
        value={selectedSubCategory}
      >
        <SelectTrigger className=" min-w-[200px] max-w-[240px]">
          <SelectValue placeholder={t("sub-categories")} />
        </SelectTrigger>
        <SelectContent>
          {subCategories.map((category) => (
            <SelectItem key={category.id} value={category.id}>
              {category.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
const CalendarActionBar = () => {
  const { t } = useTranslation();
  const {
    selectedDays,
    setSelectedDays,
    currentCalendar,
    nextCalendar,
    previousCalendar,
  } = usePriceCalendarContext();
  const [tempNotes, setTempNotes] = useState<Note[]>([]);
  const [tempSelectedDays, setTempSelectedDays] = useState<CalendarDay[]>([]);

  if (!currentCalendar) {
    return;
  }
  const daysSelected = selectedDays.length;
  useEffect(() => {
    const updatedSelectedDays = selectedDays.map((selectedDay) => {
      let updatedDay = null;

      if (currentCalendar) {
        for (const month of currentCalendar.months) {
          for (const week of month.weeks) {
            const day = week.find(
              (day) => day && day.date === selectedDay.date,
            );
            if (day) {
              updatedDay = day;
              break;
            }
          }
          if (updatedDay) break;
        }
      }

      if (!updatedDay && previousCalendar) {
        for (const month of previousCalendar.months) {
          for (const week of month.weeks) {
            const day = week.find(
              (day) => day && day.date === selectedDay.date,
            );
            if (day) {
              updatedDay = day;
              break;
            }
          }
          if (updatedDay) break;
        }
      }

      if (!updatedDay && nextCalendar) {
        for (const month of nextCalendar.months) {
          for (const week of month.weeks) {
            const day = week.find(
              (day) => day && day.date === selectedDay.date,
            );
            if (day) {
              updatedDay = day;
              break;
            }
          }
          if (updatedDay) break;
        }
      }

      return updatedDay || selectedDay;
    });

    const selectedNotes = updatedSelectedDays.flatMap((day) => day.notes);

    setTempNotes(selectedNotes);
    setTempSelectedDays(updatedSelectedDays);
  }, [currentCalendar, selectedDays, nextCalendar, previousCalendar]);

  return (
    <div className=" top-0 flex justify-center rounded-xl bg-solid-backplate p-2 shadow-lg">
      <ChangeBarDropdown />
      <ReplaceBarDropdown />
      <EditNotesDropdown selectedDays={tempSelectedDays} notes={tempNotes} />
      <Button
        onClick={() => setSelectedDays([])}
        size="sm"
        className="ml-2"
        variant={"primary"}
      >
        <X size={12} className="mr-1" />
        {`${daysSelected} ${daysSelected > 1 ? t("marked_plural") : t("marked")}`}
      </Button>
    </div>
  );
};

const CalendarYearSelectorBar = () => {
  const { year, setYear } = usePriceCalendarContext();
  const { t } = useTranslation();

  const increaseYear = () => {
    setYear((prev) => (prev += 1));
  };
  const decreaseYear = () => {
    setYear((prev) => (prev -= 1));
  };
  const jumpToTodaysYear = () => {
    setYear(new Date().getFullYear());
  };

  // Start with year that the user bought the system
  const availableYears = [
    { id: "1", value: "2023" },
    { id: "2", value: "2024" },
    { id: "3", value: "2025" },
    { id: "4", value: "2026" },
    { id: "5", value: "2027" },
    { id: "6", value: "2028" },
    { id: "7", value: "2029" },
    { id: "8", value: "2030" },
  ];

  return (
    <div className="flex items-center gap-4">
      <Button onClick={decreaseYear} size="sm" variant="secondary">
        <ChevronLeft size={17} />
      </Button>
      <Button size="sm" variant="secondary" onClick={jumpToTodaysYear}>
        {t("today")}
      </Button>
      <>
        <Select
          value={year.toString()}
          onValueChange={(value) => setYear(parseInt(value))}
        >
          <SelectTrigger className="w-[200px]">{year}</SelectTrigger>
          <SelectContent>
            {availableYears.map((year) => (
              <SelectItem key={year.id} value={year.value}>
                {year.value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </>
      <Button size="sm" variant="secondary" onClick={increaseYear}>
        <ChevronRight size={17} />
      </Button>
    </div>
  );
};

const CalendarMonthSelectorBar = () => {
  const { month, year, setMonth, setYear } = usePriceCalendarContext();
  const { t } = useTranslation();

  const [nextMonth, setNextMonth] = useState<number>(
    month === 11 ? 0 : month + 1,
  );
  const [nextYear, setNextYear] = useState<number>(
    month === 11 ? year + 1 : year,
  );

  const increaseMonth = () => {
    let newMonth = month + 1;
    let newYear = year;

    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }

    setMonth(newMonth);
    setYear(newYear);
    setNextMonth(newMonth === 11 ? 0 : newMonth + 1);
    setNextYear(newMonth === 11 ? newYear + 1 : newYear);
  };

  const decreaseMonth = () => {
    let newMonth = month - 1;
    let newYear = year;

    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }

    setMonth(newMonth);
    setYear(newYear);
    setNextMonth(newMonth === 11 ? 0 : newMonth + 1);
    setNextYear(newMonth === 11 ? newYear + 1 : newYear);
  };

  const jumpToTodaysMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    setMonth(currentMonth);
    setYear(currentYear);

    setNextMonth(currentMonth === 11 ? 0 : currentMonth + 1);
    setNextYear(currentMonth === 11 ? currentYear + 1 : currentYear);
  };

  const handleMonthChange = (selectedMonth: number) => {
    setMonth(selectedMonth);
    setNextMonth(selectedMonth === 11 ? 0 : selectedMonth + 1);
    setNextYear(selectedMonth === 11 ? year + 1 : year);
  };

  const handleNextMonthChange = (selectedMonth: number) => {
    setNextMonth(selectedMonth);

    if (selectedMonth === 0) {
      setMonth(11);
      setYear(nextYear - 1);
    } else {
      setMonth(selectedMonth - 1);
      setYear(nextYear);
    }
  };

  const monthNames = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  return (
    <div className="flex items-center gap-4">
      <Button onClick={decreaseMonth} size="sm" variant="secondary">
        <ChevronLeft size={17} />
      </Button>

      <>
        <Select
          value={month.toString()}
          onValueChange={(value) => handleMonthChange(parseInt(value))}
        >
          <SelectTrigger className="w-[200px]">
            {monthNames[month]} {year}
          </SelectTrigger>
          <SelectContent>
            {monthNames.map((name, index) => (
              <SelectItem key={`${name}-${index}`} value={index.toString()}>
                {name} {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Button size="sm" variant="secondary" onClick={jumpToTodaysMonth}>
          {t("today")}
        </Button>

        <Select
          value={nextMonth.toString()}
          onValueChange={(value) => handleNextMonthChange(parseInt(value))}
        >
          <SelectTrigger className="w-[200px]">
            {monthNames[nextMonth]} {nextYear}
          </SelectTrigger>
          <SelectContent>
            {monthNames.map((name, index) => (
              <SelectItem key={`${name}-${index}`} value={index.toString()}>
                {name} {nextYear}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </>
      <Button size="sm" variant="secondary" onClick={increaseMonth}>
        <ChevronRight size={17} />
      </Button>
    </div>
  );
};

const ChangeBarDropdown = () => {
  const {
    availablePriceRules,
    refetchCalendarYearData,
    selectedDays,
    selectedSubCategory,
    selectedCategory,
  } = usePriceCalendarContext();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedPriceRule, setSelectedPriceRule] =
    useState<GetPriceRulesPriceRule>();

  const { mutateAsync: setPriceRule } = useSetPriceCalendar();

  const filteredOptions = useMemo(
    () =>
      availablePriceRules.filter((priceRule) =>
        priceRule?.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [searchTerm, availablePriceRules],
  );

  const handleOnSearch = (e: any) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setSelectedPriceRule(undefined);
  };

  const updatePriceRuleForSelectedDays = async () => {
    try {
      const updatePromises = selectedDays.map(async (day) => {
        const payload: SetPriceCalendarRequest = {
          months: [
            {
              month: new Date(day.date).getMonth() + 1,
              year: new Date(day.date).getFullYear(),
              days: [
                {
                  day: new Date(day.date).getDate(),
                  priceRuleId: selectedPriceRule?.id || day.priceRule.id,
                },
              ],
            },
          ],
          categoryId:
            selectedSubCategory === "all" ? null : selectedSubCategory,
          categoryTypes: selectedCategory,
        };

        return setPriceRule(payload);
      });

      await Promise.all(updatePromises);

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });
      closeDropdown();
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const deletePriceRuleForSelectedDays = async () => {
    try {
      const updatePromises = selectedDays.map(async (day) => {
        const payload: SetPriceCalendarRequest = {
          months: [
            {
              month: new Date(day.date).getMonth() + 1,
              year: new Date(day.date).getFullYear(),
              days: [
                {
                  day: new Date(day.date).getDate(),
                  priceRuleId: null,
                },
              ],
            },
          ],
          categoryId:
            selectedSubCategory === "all" ? null : selectedSubCategory,
          categoryTypes: selectedCategory,
        };

        return setPriceRule(payload);
      });

      await Promise.all(updatePromises);

      await refetchCalendarYearData();
    } catch (error) {}
  };

  return (
    <DropdownMenuRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant={isDropdownOpen ? "primary" : "outline"}>
          {t("change-bar")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className=" flex max-h-[500px] min-w-[500px] flex-col overflow-y-auto p-0">
        <div onClick={(e) => e.stopPropagation()}>
          <SearchBar
            value={searchTerm}
            onChange={handleOnSearch}
            placeholder={`${t("search")}...`}
            className="rounded-none border-l-0 border-r-0 border-t-0 bg-transparent text-sm font-normal  focus-visible:ring-0"
          />
          {filteredOptions.map((option) => {
            const isChecked: boolean = selectedPriceRule?.id === option.id;

            return (
              <div
                key={option.id}
                className="flex cursor-pointer items-center py-1 hover:bg-gray-100"
                onClick={() => setSelectedPriceRule(option)}
              >
                {isChecked && <Check className="mx-1 h-4 w-4" />}
                <p className={`text-sm ${!isChecked && "ml-6"}`}>
                  {option.name}
                </p>
              </div>
            );
          })}
          <button
            onClick={deletePriceRuleForSelectedDays}
            style={{ backgroundColor: "rgba(220, 38, 38, 0.1)" }}
            className="flex w-full justify-center border-y  border-highlighted-backplate p-1 text-sm font-extrabold text-status-error hover:bg-hover "
          >
            {t("delete")}
          </button>
          <div className="flex overflow-hidden">
            <Button
              onClick={() => setIsDropdownOpen(false)}
              className="h-8 w-full rounded-r-none rounded-se-none rounded-ss-none"
              variant={"ghost"}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={updatePriceRuleForSelectedDays}
              className="h-8 w-full rounded-l-none rounded-es-none rounded-se-none"
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const ReplaceBarDropdown = () => {
  const { t } = useTranslation();
  const {
    selectedDays,
    availablePriceRules,
    selectedSubCategory,
    selectedCategory,
  } = usePriceCalendarContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { mutateAsync: setPriceRule } = useSetPriceCalendar();

  const uniquePriceRules = useMemo(() => {
    const priceRules = selectedDays
      .map((day) => day.priceRule)
      .filter((rule) => rule !== null);

    const uniquePriceRules = new Map(priceRules.map((rule) => [rule.id, rule]));

    return Array.from(uniquePriceRules.values());
  }, [selectedDays]);

  const [selectedFromPriceRule, setSelectedFromPriceRule] = useState<string>(
    uniquePriceRules[0].id,
  );
  const [selectedToPriceRule, setSelectedToPriceRule] = useState<string>("");

  const filteredAvailablePriceRules = useMemo(() => {
    return availablePriceRules.filter(
      (priceRule) => priceRule.id !== selectedFromPriceRule,
    );
  }, [availablePriceRules, selectedFromPriceRule]);

  const isSaveDisabled: boolean =
    selectedFromPriceRule?.length > 0 && selectedToPriceRule?.length > 0;

  const updatePriceRuleForSelectedDays = async () => {
    try {
      const updatePromises = selectedDays.map(async (day) => {
        if (day.priceRule.id !== selectedFromPriceRule) {
          return;
        }
        const payload: SetPriceCalendarRequest = {
          months: [
            {
              month: new Date(day.date).getMonth() + 1,
              year: new Date(day.date).getFullYear(),
              days: [
                {
                  day: new Date(day.date).getDate(),
                  priceRuleId: selectedToPriceRule || day.priceRule.id,
                },
              ],
            },
          ],
          categoryId:
            selectedSubCategory === "all" ? null : selectedSubCategory,
          categoryTypes: selectedCategory,
        };

        return setPriceRule(payload);
      });

      await Promise.all(updatePromises);

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });
      closeDropdown();
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    }
  };

  const closeDropdown = () => {
    setSelectedToPriceRule("");
    setSelectedFromPriceRule("");
    setIsDropdownOpen(false);
  };

  return (
    <DropdownMenuRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          size="sm"
          className="mx-2"
          variant={isDropdownOpen ? "primary" : "outline"}
        >
          {t("replace-bar")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex max-h-[500px]  flex-col gap-2 overflow-y-auto p-4">
        <Label className="text-lg font-bold">{t("replace-bar")}</Label>
        <div>
          <Select
            value={selectedFromPriceRule}
            onValueChange={(value) => setSelectedFromPriceRule(value)}
          >
            <Label className="text-xs">{t("from")}</Label>
            <SelectTrigger className="min-w-[500px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {uniquePriceRules.map((priceRule, index) => (
                <SelectItem
                  key={`${priceRule.id}-${index}`}
                  value={priceRule.id}
                >
                  {priceRule.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <Select
            value={selectedToPriceRule}
            onValueChange={(value) => setSelectedToPriceRule(value)}
          >
            <Label className="text-xs">{t("to")}</Label>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {filteredAvailablePriceRules.map((priceRule, index) => (
                <SelectItem
                  key={`${priceRule.id}-${index}`}
                  value={priceRule.id}
                >
                  {priceRule.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex justify-end gap-3 pt-4">
          <Button size={"sm"} variant={"outline"} onClick={closeDropdown}>
            {t("cancel")}
          </Button>
          <Button
            disabled={!isSaveDisabled}
            size={"sm"}
            onClick={updatePriceRuleForSelectedDays}
          >
            {t("replace")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const EditNotesDropdown = ({
  selectedDays,
  notes,
}: {
  selectedDays: CalendarDay[];
  notes: Note[];
}) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const { mutateAsync: patchNote } = useUpdatePriceCalendarNote();
  const { mutateAsync: delNote } = useDeletePriceCalendarNote();
  const { mutateAsync: postNote } = useCreatePriceCalendarNote();

  const uniqueMessages = new Set<string>();
  const filteredNotes = notes.filter((note) => {
    if (uniqueMessages.has(note.message)) {
      return false;
    } else {
      uniqueMessages.add(note.message);
      return true;
    }
  });

  const updateNote = async (note: Note, message: string) => {
    setIsLoading(true);
    try {
      const ids = notes
        .filter((n) => n.message === note.message)
        .map((n) => n.id);

      await patchNote({
        priceCalendarNoteIds: ids,
        message,
      });

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });

      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const deleteNote = async (note: Note) => {
    setIsLoading(true);
    try {
      await delNote({
        priceCalendarNoteId: note.id,
      });

      const ids = notes
        .filter((n) => n.message === note.message && n.id !== note.id)
        .map((n) => n.id);

      if (ids.length > 0) {
        await Promise.all(
          ids.map((id) =>
            delNote({
              priceCalendarNoteId: id,
            }),
          ),
        );
      }

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });
      toast({
        title: t("changes-saved"),
        variant: "success",
      });
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const createNote = async () => {
    setIsLoading(true);
    try {
      for (const day of selectedDays) {
        const newNote = {
          startDate: format(day.date, "yyyy-MM-dd"),
          endDate: format(day.date, "yyyy-MM-dd"),
          message,
        };

        await postNote(newNote);
      }

      queryClient.invalidateQueries({
        queryKey: useGetPriceCalendar.getKey(),
      });

      toast({
        title: t("saved-succesfully", { name: message }),
        variant: "success",
      });
      setMessage("");
    } catch (error) {
      toast({
        title: t("request-failed-with"),
        description: t(
          decodeURIComponent(
            (error instanceof Error && error.message) || t("no-message"),
          ),
        ),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <DropdownMenuRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant={isDropdownOpen ? "primary" : "outline"}>
          {t("edit-notes")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" className=" min-w-[500px] p-4">
        <Label className="pb-4 text-lg font-bold">{t("edit-notes")}</Label>

        {selectedDays.length > 0 && (
          <div className="flex max-h-[500px] flex-col overflow-y-auto">
            {filteredNotes.map((note, noteIndex) => {
              const correspondingDay = selectedDays.find((day) =>
                day.notes.some((n) => n.id === note.id),
              );
              const date = correspondingDay ? correspondingDay.date : "";

              return (
                <div key={`${noteIndex}-${note.id}-${noteIndex}`}>
                  <NoteItem
                    note={note}
                    updateNote={updateNote}
                    deleteNote={() => deleteNote(note)}
                    date={date}
                  />
                </div>
              );
            })}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <Loading />
              </div>
            )}
          </div>
        )}
        <div>
          <Label className="text-xs">{t("new-note")}</Label>
          <Input value={message} onChange={(e) => setMessage(e.target.value)} />
          <Button
            loading={isLoading}
            className="my-4"
            disabled={message.length === 0}
            size={"sm"}
            onClick={() => createNote()}
          >
            {t("add")}
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const FilterDropdown = () => {
  const { t } = useTranslation();
  const {
    selectedSubCategory,
    showOccupancy,
    setShowOccupancy,
    showPrices,
    setShowPrices,
    showWeather,
    setShowWeather,
    layout,
  } = usePriceCalendarContext();

  const isMonthLayout = layout === Layouts.Month;
  const isCategorySpecific = selectedSubCategory !== "all";
  const isOccupancyDisabled: boolean = !isMonthLayout && showPrices;
  const isPricesDisabled: boolean =
    !isCategorySpecific || (!isMonthLayout && showOccupancy);

  const handleOccupancyChange = () => {
    setShowOccupancy((prev) => !prev);
  };

  const handlePricesChange = () => {
    setShowPrices((prev) => !prev);
  };

  const handleWeatherChange = () => {
    setShowWeather((prev) => !prev);
  };

  const resetFilters = () => {
    setShowOccupancy(false);
    setShowPrices(false);
    setShowWeather(false);
  };

  useEffect(() => {
    if (!isCategorySpecific) {
      setShowPrices(false);
    }
  }, [isCategorySpecific, layout]);

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Button size={"sm"} variant="secondary">
          <FilterIcon size="15px" className="mr-1" />
          <p>{t("show")}</p>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="flex min-w-32 flex-col text-sm font-medium"
        align="end"
      >
        <div className="p-2">
          <div className="mb-2 flex justify-between">
            <p className="mr-4">{t("show-occupancy")}</p>
            <Switch
              checked={showOccupancy}
              onCheckedChange={handleOccupancyChange}
              disabled={isOccupancyDisabled}
            />
          </div>

          <div className="mb-2 flex justify-between">
            <p
              className={`mr-4 ${!isCategorySpecific && layout !== Layouts.Month && "text-secondary-text"}`}
            >
              {t("show-prices")}
            </p>
            <Switch
              checked={showPrices}
              onCheckedChange={handlePricesChange}
              disabled={isPricesDisabled}
            />
          </div>

          <div className="flex justify-between">
            <p className="mr-4">{t("show-weather")}</p>
            <Switch
              checked={showWeather}
              onCheckedChange={handleWeatherChange}
            />
          </div>
        </div>
        <div className="flex justify-center border-t py-1 text-sm font-bold">
          <button onClick={resetFilters}>{t("reset")}</button>
        </div>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
};

const LayoutSelector = () => {
  const { layout, setLayout, setMonth, setYear } = usePriceCalendarContext();

  const resetToCurrentMonthAndYear = () => {
    const today = new Date();
    setMonth(today.getMonth());
    setYear(today.getFullYear());
  };

  const handleLayoutChange = (value: Layouts) => {
    if (value === Layouts.Month) {
      resetToCurrentMonthAndYear();
    }
    setLayout(value);
  };

  return (
    <div className="rounded-lg">
      <ToggleGroup
        type="single"
        value={layout}
        size={"sm"}
        onValueChange={(value) => value && handleLayoutChange(value as Layouts)}
        className="bg-primary-card-backplate"
      >
        <ToggleGroupItem
          size={"sm"}
          value={Layouts.Vertical}
          className="bg-primary-button-backplate"
        >
          <AlignStartHorizontal size={17} />
        </ToggleGroupItem>
        <ToggleGroupItem
          size={"sm"}
          value={Layouts.Horizontal}
          className="mx-2 bg-primary-button-backplate"
        >
          <AlignStartVertical size={17} />
        </ToggleGroupItem>
        <ToggleGroupItem
          className="bg-primary-button-backplate "
          size={"sm"}
          value={Layouts.Month}
        >
          <LayoutGrid size={17} />
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};
